import { Link } from "react-router-dom";
import moment from "moment";
import { Card, Tag, Space, Typography, Divider, Avatar } from "antd";
import { VendorInfo } from "../atoms/VendorInfo";
export const OrderInfoCard = ({ customer, order }) => {
  const { name, email, phone, id, invoiceTemplate } = customer;

  const {
    fulfillmentType,
    deliveryAddress,
    notes,
    packerNotes,
    updatedAt,
    createdAt,
    orderDate,
    confirmedAt,
  } = order;

  return (
    <>
      <Card title="Customer">
        <Space direction="vertical">
          <Space>
            <Avatar>{name.charAt(0).toUpperCase()}</Avatar>
            <Link to={`/customers/${id}`}>{name}</Link>
            {customer.franchise && <Tag>Franchise: {customer.franchise?.name}</Tag>}
          </Space>

          <Typography.Text strong>Contact information</Typography.Text>
          <Typography.Text>Email: {email}</Typography.Text>
          <Typography.Text>Phone: {phone}</Typography.Text>
          <VendorInfo vendor={invoiceTemplate} />
        </Space>
      </Card>
      <Divider />
      <Card title="Addtional Info">
        <Space>
          <Space direction="vertical">
            <Typography.Text strong> Date</Typography.Text>
            <Typography.Text>{moment(orderDate).format("DD/MM/YYYY")}</Typography.Text>
          </Space>
          <Divider type="vertical" />
          <Space direction="vertical">
            <Typography.Text strong>Fulfillment Type</Typography.Text>
            <Typography.Text>{fulfillmentType}</Typography.Text>
          </Space>
        </Space>
        <Divider />

        <Space direction="vertical">
          <Typography.Text strong>Shipping address</Typography.Text>
          <Typography.Text>{deliveryAddress}</Typography.Text>
        </Space>

        <Divider />

        <Space direction="vertical">
          <Typography.Text strong>Notes</Typography.Text>
          <Typography.Text>{notes}</Typography.Text>
        </Space>
        <Divider />
        <Space direction="vertical">
          <Typography.Text strong>Packer notes</Typography.Text>
          <Typography.Text>{packerNotes}</Typography.Text>
        </Space>
        {/* <Divider />
        <Space direction="vertical">
          <Typography.Text strong>Packer</Typography.Text>
          <Typography.Text strong>{packer?.name}</Typography.Text>
          <Typography.Text>{packerNotes}</Typography.Text>
        </Space> */}
      </Card>
      <Card title="Timestamps">
        <Space direction="vertical">
          <Space>
            <Typography.Text strong>Created at: </Typography.Text>
            <Typography.Text>{moment(createdAt).format("YYYY-MM-DD, HH:mm:ss")}</Typography.Text>
          </Space>
          <Space>
            <Typography.Text strong>Confirmed at: </Typography.Text>
            <Typography.Text>
              {confirmedAt ? moment(confirmedAt).format("YYYY-MM-DD, HH:mm:ss") : "not confirmed"}
            </Typography.Text>
          </Space>
          <Space>
            <Typography.Text strong>Updated at: </Typography.Text>
            <Typography.Text>{moment(updatedAt).format("YYYY-MM-DD, HH:mm:ss")}</Typography.Text>
          </Space>
        </Space>
      </Card>
    </>
  );
};
