export const getSearchParams = (name, gte, lte, currentPage = 1) => {
  if (!gte && !lte) {
    return {
      name: name,
      currentPage: currentPage,
    };
  }

  if (!gte) {
    return {
      name: name,
      currentPage: currentPage,
      lte: lte,
    };
  }

  if (!lte) {
    return {
      name: name,
      currentPage: currentPage,
      gte: gte,
    };
  }

  return {
    name: name,
    currentPage: currentPage,
    gte: gte,
    lte: lte,
  };
};
