import { useQuery } from "@apollo/client";
import { useEffect, useState } from "react";
import { CustomersSelectedQuery } from "../graphql/customers.graphql";
import { EditOutlined } from "@ant-design/icons";

export const useAllCustomers = () => {
  const { data, loading, error } = useQuery(CustomersSelectedQuery);
  const [customers, setCustomers] = useState([]);

  useEffect(() => {
    if (data?.customers) {
      const options = data.customers.map((customer) => {
        return {
          label: (
            <div style={{ display: "flex", justifyContent: "space-between" }}>
              {customer.name}
              <p>
                Edit this customer <EditOutlined />
              </p>
            </div>
          ),
          value: customer.name,
          id: customer.id,
        };
      });

      setCustomers([{ label: "Existing Customers", options }]);
    }
  }, [data]);

  return { customers, error, loading };
};
