import { useMutation } from "@apollo/client";
import moment from "moment";
import {
  UpdateRunsheetMutation,
  CreateRunsheetMutation,
  DeliveryRunQuery,
} from "../graphql/deliveryRun.graphql";

export const useSetDeliveryRun = (emptyDeliveryRun) => {
  const [createRunsheet, { loading: createLoading }] = useMutation(CreateRunsheetMutation, {
    // refetchQueries: [{ query: DeliveryRunQuery, fetchPolicy: "network-only" }],
  });
  const [updateRunsheet, { loading: updateLoading }] = useMutation(UpdateRunsheetMutation, {
    refetchQueries: [
      {
        query: DeliveryRunQuery,
        variables: String(moment().format("YYYY-MM-DD")),
        fetchPolicy: "network-only",
      },
    ],
  });

  const date = String(moment().format("YYYY-MM-DD"));
  let doSetDeliveryRun;
  if (!emptyDeliveryRun) {
    doSetDeliveryRun = ({ runsheet }) => {
      return updateRunsheet({
        variables: { name: date, runsheet: runsheet },
      });
    };
  } else {
    doSetDeliveryRun = ({ runsheet }) => {
      return createRunsheet({
        variables: { name: date, runsheet: runsheet },
      });
    };
  }
  return { doSetDeliveryRun, createLoading, updateLoading };
};
