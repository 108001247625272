import { Space } from "antd";
import { LeftOutlined, RightOutlined } from "@ant-design/icons";

export const SiderTrigger = ({ collapsed }) => {
  return (
    <Space
      style={{
        borderRight: "0.33px solid lightgrey",
        width: "inherit",
        display: "flex",
        justifyContent: "space-around",
      }}
    >
      {collapsed ? <RightOutlined /> : <LeftOutlined />}
    </Space>
  );
};
