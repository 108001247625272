import { useState, useEffect } from "react";
import { Table, PageHeader, Space, Input, Button, Typography } from "antd";
import { useLazyQuery } from "@apollo/client";
import moment from "moment";
import { SyncOutlined } from "@ant-design/icons";
import styled from "styled-components";

import { CustomersQuery } from "../graphql/customers.graphql";

const ToolbarWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  padding: 16px 12px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.06);
  margin-bottom: 8px;
`;

const columns = [
  {
    title: "Customer",
    dataIndex: "name",
    key: "name",
    render: (customerName, record) => {
      return (
        <Typography.Title level={5} style={{ marginBottom: 0 }}>
          {customerName}
        </Typography.Title>
      );
    },
  },
  {
    title: "Address",
    dataIndex: "deliveryAddress",
    key: "deliveryAddress",
  },
  {
    title: "No. of orders",
    dataIndex: "ordersCount",
    key: "ordersCount",
    render: (count, record) => `${count} orders`,
    responsive: ["md"],
  },
  {
    title: "Last order",
    dataIndex: "lastOrder",
    key: "lastOrder",
    render: (_, record) => {
      if (record.orders.length > 0) {
        return moment(record.orders[0].orderDate).fromNow();
      }
    },
  },
  {
    title: "Salesperson",
    dataIndex: ["salesperson", "name"],
    key: "salesperson.name",
  },
];

export const SalesPortal = () => {
  const [searchCustomer, { data, loading, refetch }] = useLazyQuery(CustomersQuery, {
    fetchPolicy: "network-only",
  });
  const [customers, setCustomers] = useState([]);

  useEffect(() => {
    if (data?.customers) {
      setCustomers(data.customers);
    }
  }, [data]);

  const handleRefresh = () => {
    console.log("Refreshing...");
    refetch();
  };

  const handleSearch = (value) => {
    if (value.length < 2) return;
    if ("THAI".includes(value.toUpperCase())) return;
    const customerfilter = {
      OR: [
        { name: { contains: value, mode: "insensitive" } },
        { deliveryAddress: { contains: value, mode: "insensitive" } },
      ],
      isFranchise: { equals: false },
    };
    searchCustomer({ variables: { filters: customerfilter } });
  };

  return (
    <>
      <PageHeader
        ghost={true}
        title="Customers"
        subTitle={`Showing ${customers.length} customers`}
      />
      <ToolbarWrapper>
        <Space>
          <Input.Search
            placeholder="Search name"
            enterButton
            allowClear
            onSearch={handleSearch}
            style={{ width: 320 }}
          />
        </Space>
        <Space>
          <Space size={0}>
            <Button icon={<SyncOutlined />} onClick={handleRefresh}>
              Refresh
            </Button>
          </Space>
        </Space>
      </ToolbarWrapper>

      <Table
        dataSource={customers}
        columns={columns}
        rowKey="id"
        size="small"
        bordered={true}
        loading={loading}
        pagination={false}
      />
    </>
  );
};
