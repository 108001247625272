import { gql } from "@apollo/client";

export const PaymentsQuery = gql`
  query PaymentsQuery($filters: PaymentWhereInput!) {
    payments(orderBy: { createdAt: desc }, where: $filters) {
      id
      name
      createdAt
      updatedAt
      amount
      batchName
      paymentMethod
      notes
      customer {
        id
        name
      }
      order {
        id
        name
      }
    }
  }
`;

export const UnpaidOrdersByCustomerIdQuery = gql`
  query UnpaidOrdersByCustomerIdQuery($customerId: ID!) {
    orders(
      where: {
        customer: { id: { equals: $customerId } }
        paymentStatus: { not: { equals: "paid" } }
        status: { equals: "confirmed" }
      }
      orderBy: { orderDate: asc }
    ) {
      id
      orderDate
      updatedAt
      createdAt
      balancePaid
      balanceDue
      total
      name
      paymentStatus
      customer {
        id
        name
      }
    }
  }
`;

export const CreatePaymentsMutation = gql`
  mutation CreatePaymentsMutation($data: [PaymentCreateInput!]!) {
    createPayments(data: $data) {
      id
    }
  }
`;

export const UpdatePaymentMutation = gql`
  mutation UpdatePaymentMutation($id: ID!, $data: PaymentUpdateInput!) {
    updatePayment(where: { id: $id }, data: $data) {
      id
    }
  }
`;

export const SinglePaymentQuery = gql`
  query SinglePaymentQuery($id: ID!) {
    payment(where: { id: $id }) {
      id
      name
      createdAt
      updatedAt
      amount
      batchName
      paymentMethod
      notes
      customer {
        id
        name
      }
      order {
        id
        name
      }
    }
  }
`;

// payments delete
export const DeletePaymentsMutation = gql`
  mutation DeletePaymentsMutation($where: [PaymentWhereUniqueInput!]!) {
    deletePayments(where: $where) {
      id
    }
  }
`;
