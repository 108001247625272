import React from "react";
import { AutoComplete, Input } from "antd";

export const ResourceSearchComplete = ({
  options,
  placeholder = "Searc here...",
  inputWidth = 350,
  hasEnterButton = true,
  onSelect,
  width,
}) => {
  return (
    <AutoComplete
      style={{ width: width }}
      dropdownMatchSelectWidth={350}
      options={options}
      onSelect={onSelect}
      filterOption={(inputValue, option) =>
        option.value.toUpperCase().indexOf(inputValue.toUpperCase()) !== -1
      }
    >
      <Input.Search
        style={{ width: inputWidth || "350px" }}
        placeholder={placeholder}
        enterButton={hasEnterButton}
      />
    </AutoComplete>
  );
};
