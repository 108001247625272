export const getOrderFulfillmentStatus = (items) => {
  if (items.length === 0) return "packed";
  const grouped = items.reduce(
    (prev, current) => {
      const currentStatus = prev[current.pickingStatus];
      prev[current.pickingStatus] = currentStatus + 1;
      return prev;
    },
    { pack: 0, missing: 0, replace: 0, done: 0, na: 0 }
  );

  // #1. untouched, all items are pack
  if (grouped.pack === items.length) return "unpacked";

  //   #2. none of them is pack or missing
  if (grouped.pack === 0 && grouped.missing === 0) return "packed";

  return "missing";
};
