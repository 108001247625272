import { Text } from "@react-pdf/renderer";
export const PageNumber = ({ sx }) => (
  <Text
    style={{
      position: "absolute",
      fontSize: 10,
      bottom: 24,
      left: 0,
      right: 20,
      textAlign: "right",
      color: "grey",
      ...sx,
    }}
    render={({ pageNumber, totalPages }) => `Page ${pageNumber} / ${totalPages}`}
    fixed
  />
);
