import styled from "styled-components";
import pkg from "../../../../package.json";

const LogoWithTextWrapper = styled.div`
  font-size: 16px;
  font-weight: 400;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 0;
  width: 100%;
  max-width: 160px;
  min-height: 3.5rem;
  font-family: "Montserrat", sans-serif;
  img {
    margin-right: 8px;
  }
  span {
    font-size: 8px;
    margin-left: 4px;
  }
`;

export const LogoWithText = () => {
  return (
    <LogoWithTextWrapper>
      <img
        width="32"
        height="32"
        src={`${process.env.PUBLIC_URL}/images/app-logo.png`}
        alt="Fresh Cloud Logo"
      />
      FreshCloud <span>{pkg.version}</span>
    </LogoWithTextWrapper>
  );
};
