import { Space, Table, Tooltip, Typography } from "antd";
import { MoneyCell } from "../tablecells";
import { formatMoney } from "../../utils";
import { StatusTag } from "../tags/StatusTag";

export const LineItemsTable = ({ items, loading, status }) => {
  const LineItemsColumns = [
    {
      title: "#",
      dataIndex: "sortingIndex",
      key: "sortingIndex",
      render: (text) => Number(text) + 1,
    },
    {
      title: "Description",
      dataIndex: "name",
      key: "name",
    },
    { title: "Comments", dataIndex: "notes", key: "notes" },
    {
      title: "Unit",
      dataIndex: "unit",
      key: "unit",
      render: (_, record) => {
        if (record.isOpenItem) return record.packagingUnit ?? "";
        return record.productVariant && record.productVariant.name;
      },
    },

    {
      title: "QTY",
      dataIndex: "quantity",
      key: "qty",
      align: "right",
    },
    {
      title: (
        <Tooltip title={status === "confirmed" ? "Confirmed" : "Pending"}>
          <Typography.Text type={status === "confirmed" ? "success" : "secondary"}>
            Price
          </Typography.Text>
        </Tooltip>
      ),
      dataIndex: "unitPrice",
      key: "price",
      ...MoneyCell,
      render: (_, record) => {
        return <Typography.Text>{formatMoney(record.unitPrice)}</Typography.Text>;
      },
    },
    {
      title: (
        <Tooltip title={status === "confirmed" ? "Confirmed" : "Pending"}>
          <Typography.Text type={status === "confirmed" ? "success" : "secondary"}>
            Line total
          </Typography.Text>
        </Tooltip>
      ),
      dataIndex: "amount",
      key: "amount",
      align: "right",
      render: (_, record) =>
        status === "confirmed" ? formatMoney(+record.quantity * +record.unitPrice) : "-",
    },
    {
      title: "Picking",
      dataIndex: "pickingStatus",
      key: "pickingStatus",
      align: "right",
      render: (text) => <StatusTag status={text} />,
    },
  ];
  return (
    <Table
      size="small"
      pagination={false}
      columns={LineItemsColumns}
      dataSource={items}
      loading={loading}
      rowKey="id"
    />
  );
};
