import { useState, useEffect, useContext } from "react";
import { useLazyQuery, useMutation } from "@apollo/client";
import { useAuth } from "../../hooks/useAuth";
import { useLocation } from "react-router-dom";

import {
  Form,
  Space,
  Input,
  Select,
  InputNumber,
  Row,
  Col,
  Button,
  Card,
  Divider,
  Typography,
  DatePicker,
  Modal,
  notification,
  Drawer,
  Tag,
  Radio,
} from "antd";
import { MinusCircleOutlined, CaretDownOutlined, ExportOutlined } from "@ant-design/icons";

import { CustomerDetailCard } from "../CustomerDetailCard";
import momentTZ from "moment-timezone";
import {
  calcTotalPrice,
  calculateUnitPrice,
  calTolBasePrice,
  // getTodayTomorrowTag,
} from "../../utils";

import { SingleCustomerForOrderQuery } from "../../graphql/customers.graphql";

import { ReadonlyNumberField, ReadonlyPercentField } from "../formFields/ReadonlyNumberField";
import { ReadonlyField } from "../formFields/ReadonlyField";
import { ProductSearchSelect } from "../formFields/ProductSearchSelect";
import { HistoryPriceField } from "../formFields/HistoryPriceField";

import { CustomerSelection } from "../CustomerSelection";

import { PageContainer } from "../atoms/PageContainer";
import { ProductForm } from "../ProductForm";
import { CreateProductMutation } from "../../graphql/products.graphql";
import { ProductsContext } from "../../contexts/ProductsContext";

const { Option } = Select;

const pickingStatusOptions = [
  {
    label: <Tag color="red">N/A</Tag>,
    value: "na",
  },
  {
    label: <Tag color="warning">Missing</Tag>,
    value: "missing",
  },
  {
    label: <Tag color="success">Done</Tag>,
    value: "done",
  },
  {
    label: <Tag color="blue">Replace</Tag>,
    value: "replace",
  },
  {
    label: <Tag color="">Pack</Tag>,
    value: "pack",
  },
];

// const SydUtcOffset = momentTZ().tz("Australia/Sydney").utcOffset();
// const currentUtcOffset = moment().utcOffset();
let currentHour = momentTZ().tz("Australia/Sydney").hour();
// User is not located in Sydney && current local hour is less than 15pm
// if (SydUtcOffset !== currentUtcOffset && currentHour < 15) {
//   currentHour += (SydUtcOffset - currentUtcOffset) / 60;
// }

const initialValues = {
  orderDate:
    currentHour >= 15
      ? momentTZ().tz("Australia/Sydney").hour(9).minute(0).second(0).add({ days: 1 })
      : momentTZ().tz("Australia/Sydney").hour(9).minute(0).second(0),
  totalPrice: {
    totalWithoutGst: 0,
    gstAmount: 0,
    gstPercentage: 0,
    total: 0,
    grossProfit: 0,
  },
  fulfillmentType: "delivery",
  settings: {
    isFixedMargin: false,
  },
  lineItems: [undefined],

  //TODO: Property "invisibleItem" is just used for force-rerendering the form. It does not show up on this page.
  invisibleItem: "",
};

export const OrderForm = ({ order = {}, customer = {}, setCustomer, onSave, doFetch }) => {
  const location = useLocation();
  const [productForm] = Form.useForm();
  const { variants, refetch } = useContext(ProductsContext);
  const { user } = useAuth();
  const [selectedCustomer, setSelectedCustomer] = useState(customer);
  const [selectedIndex, setSelectedIndex] = useState(-1);
  const [selectedVariant, setSelectedVariant] = useState("");
  const [productModalOpen, setProductModalOpen] = useState(false);
  const [customerDrawerOpen, setCustomerDrawerOpen] = useState(false);
  const [marginType, setMarginType] = useState("default");
  // const [dateTag, setDateTag] = useState(getTodayTomorrowTag(initialValues.orderDate));
  const [form] = Form.useForm();

  const [fetchCustomer, { data: newCustomer }] = useLazyQuery(SingleCustomerForOrderQuery, {
    fetchPolicy: "network-only",
  });

  const [createProduct] = useMutation(CreateProductMutation);

  useEffect(() => {
    if (location.pathname !== "/orders/new") {
      const tempData = { ...order };
      tempData.invisibleItem = "";
      form.setFieldsValue(tempData);
      // setDateTag(getTodayTomorrowTag(order.orderDate));
    }
  }, [form, order, location]);

  useEffect(() => {
    if (customer) {
      setSelectedCustomer(customer);
      form.setFields([
        { name: "deliveryAddress", value: customer.deliveryAddress },
        { name: "notes", value: customer.notes },
      ]);
    }
    if (newCustomer?.customer && setCustomer) {
      setSelectedCustomer(newCustomer?.customer);
      setCustomerDrawerOpen(false);
      setCustomer(newCustomer?.customer);
      form.setFields([
        {
          name: "deliveryAddress",
          value: newCustomer?.customer.deliveryAddress,
        },
        { name: "notes", value: newCustomer?.customer.notes },
      ]);
    }
  }, [customer, setCustomer, newCustomer, form]);

  const handleProductSelect = (option, index) => {
    if (!option.variant) {
      const lineItem = {
        name: "",
        quantity: "1",
        unitPrice: "10.0",
        pickingStatus: "pack",
        isOpenItem: true,
      };
      form.setFields([{ name: ["lineItems", index], value: lineItem }]);
      return;
    }

    const currentVariant = option.variant;
    const unitPrice = calculateUnitPrice(marginType, customer.fixedMargin, currentVariant);
    const lineItem = {
      name: currentVariant.product.name,
      productVariant: {
        name: currentVariant.name,
        id: currentVariant.id,
        basePrice: currentVariant.basePrice,
        defaultMargin: currentVariant.defaultMargin,
      },
      product: currentVariant.product,
      // packagingUnit: currentVariant.name,
      unitPrice: unitPrice,
      quantity: "1",
      pickingStatus: "pack",
      cost: currentVariant.basePrice,
      isOpenItem: false,
    };
    form.setFields([{ name: ["lineItems", index], value: lineItem }]);

    setSelectedIndex(index);
    setSelectedVariant(currentVariant.id);
  };

  const handleFinish = async (values, isApproving = false) => {
    delete values.marginType;
    delete values.invisibleItem;
    if (isApproving) values.status = "confirmed";
    let newLineItems = values.lineItems.filter((item) => item.name !== undefined);
    let totalBasePrice = calTolBasePrice(newLineItems);
    values.totalPrice.totalBasePrice = totalBasePrice;
    onSave(values);
  };

  const handleMarginTypeChange = ({ target: { value } }) => {
    setMarginType(value);
    let lineItems = form.getFieldValue().lineItems;
    if (lineItems[0]) {
      lineItems = lineItems.map((item) => {
        if (!item || item?.isOpenItem) return item;
        const unitPrice = calculateUnitPrice(value, customer.fixedMargin, item.productVariant);
        return {
          ...item,
          unitPrice: unitPrice,
        };
      });
      form.setFields([{ name: "lineItems", value: lineItems }]);
      const totalPrice = calcTotalPrice(lineItems, 0);
      form.setFields([{ name: "totalPrice", value: totalPrice }]);
    }
  };

  const handleSave = () => {
    const values = form.getFieldsValue();
    handleFinish(values);
  };

  const handleSaveAndApprove = () => {
    const values = form.getFieldsValue();
    handleFinish(values, true);
  };
  const handleFocus = (e) => {
    e.target.select();
  };

  const handleFieldsChange = (e) => {
    let lineItems = form.getFieldValue().lineItems;

    if (lineItems) {
      const totalPrice = calcTotalPrice(lineItems, 0);
      form.setFields([{ name: "totalPrice", value: totalPrice }]);
    }
    if (e[0]?.name[0] === "orderDate") {
      // setDateTag(getTodayTomorrowTag(e[0].value));
    }
    if (e[0]?.name[2] === "pickingStatus") {
      const itemIndex = e[0]?.name[1];
      if (e[0].value === "na" || e[0].value === "replace") {
        form.setFields([{ name: ["lineItems", itemIndex, "unitPrice"], value: "0.00" }]);
      }

      form.setFields([
        { name: "totalPrice", value: calcTotalPrice(form.getFieldValue().lineItems, 0) },
      ]);
    }
  };

  const openProductModal = () => {
    setProductModalOpen(true);
  };

  const handleProductSave = async () => {
    try {
      await productForm.validateFields();
      const values = productForm.getFieldsValue();
      const variants = values.productVariants.map((v, index) => {
        return {
          name: v.name,
          name_EN: v.name_EN,
          name_ZH: v.name_ZH,
          isDefaultUnit: index === 0,
        };
      });
      const payload = {
        ...values,
        category: { connect: { id: values.category } },
        productVariants: { create: variants },
      };

      const res = await createProduct({ variables: { data: payload } });
      if (res.data.createProduct.id) {
        notification.success({
          message: `${res.data.createProduct.name} successfully created.`,
        });
        productForm.resetFields();
        refetch();
        setProductModalOpen(false);
      }
    } catch (error) {
      console.log("error: ", error);
    }
  };

  const handleProductCancel = () => {
    productForm.resetFields();
    setProductModalOpen(false);
  };

  const handleCustomerDrawerClose = () => {
    setCustomerDrawerOpen(false);
  };

  const handleCustomerChange = (_, { id }) => {
    fetchCustomer({ variables: { id: id } });
  };

  const isDuplicate = (index) => {
    if (!selectedVariant) return false;
    return (
      index !== selectedIndex &&
      !form.getFieldValue().lineItems[index]?.isOpenItem &&
      form.getFieldValue().lineItems[index]?.productVariant?.id === selectedVariant
    );
  };

  const handleMarkAllItemsDone = () => {
    const lineItemsList = form.getFieldValue().lineItems;
    const newLineItems = Array.from(lineItemsList).map((item) =>
      item?.pickingStatus !== "replace" && item?.pickingStatus !== "na"
        ? {
            ...item,
            pickingStatus: "done",
          }
        : item
    );
    form.setFields([{ name: "lineItems", value: newLineItems }]);
  };

  const handleKeyDown = (e) => {
    if (e.code === "Enter") e.preventDefault();
  };

  return (
    <>
      <Modal
        title="New product"
        onOk={handleProductSave}
        onCancel={handleProductCancel}
        width="80%"
        visible={productModalOpen}
      >
        <ProductForm form={productForm} />
      </Modal>
      <Drawer
        title="Basic Drawer"
        placement="right"
        width="50%"
        onClose={handleCustomerDrawerClose}
        visible={customerDrawerOpen}
      >
        <CustomerSelection onCustomerSelect={handleCustomerChange} />
      </Drawer>
      <Form
        layout="vertical"
        form={form}
        onFieldsChange={handleFieldsChange}
        initialValues={initialValues}
      >
        <Row gutter={16}>
          <Col span={18} style={{ marginTop: "-15px" }}>
            <PageContainer>
              <Card
                title="Items"
                extra={[
                  <Button
                    size="samll"
                    type="dashed"
                    onClick={handleMarkAllItemsDone}
                    key="all-done"
                  >
                    Mark all items done
                  </Button>,
                  <Radio.Group
                    key="marginType"
                    options={[
                      { label: "Fixed Margin", value: "fixed" },
                      { label: "Default Margin", value: "default" },
                    ]}
                    value={marginType}
                    onChange={handleMarginTypeChange}
                    optionType="button"
                    buttonStyle="solid"
                  />,
                  <Button
                    key="new-product"
                    style={{ marginLeft: "5px" }}
                    onClick={openProductModal}
                  >
                    New product
                  </Button>,
                ]}
              >
                <Row align="center" gutter={0}>
                  <Col span={1}>
                    <Typography.Text strong></Typography.Text>
                  </Col>
                  <Col span={1}>
                    <Typography.Text strong>#</Typography.Text>
                  </Col>
                  <Col span={5}>
                    <Typography.Text strong>Description</Typography.Text>
                  </Col>
                  <Col span={4}>
                    <Typography.Text strong style={{ paddingLeft: "11px" }}>
                      Unit
                    </Typography.Text>
                  </Col>
                  <Col span={3}>
                    <Typography.Text strong>QTY</Typography.Text>
                  </Col>
                  <Col span={5}>
                    <Typography.Text strong>History Price</Typography.Text>
                  </Col>
                  <Col span={3}>
                    <Typography.Text strong>Price</Typography.Text>
                  </Col>
                  <Col span={2}>
                    <Typography.Text strong>Status</Typography.Text>
                  </Col>
                  <Col span={1}>
                    <Typography.Text strong></Typography.Text>
                  </Col>
                </Row>
                <Divider style={{ margin: "0" }} />
                <Form.List name="lineItems" shouldUpdate={true}>
                  {(fields, { add, remove, move }) => (
                    <PageContainer>
                      {fields.map(({ key }, index) => {
                        return (
                          <div key={key}>
                            <Row
                              align="center"
                              key={key}
                              gutter={0}
                              style={{
                                width: "100%",
                                backgroundColor: isDuplicate(index) ? "orange" : "initial",
                                padding: "5px 3px 0px 0px",
                              }}
                            >
                              <Col span={1}>
                                <Space key={key}>
                                  <Button
                                    type="link"
                                    icon={<CaretDownOutlined />}
                                    onClick={() => {
                                      move(index, index + 1);
                                    }}
                                  />
                                </Space>
                              </Col>
                              <Col span={1}>
                                <Space style={{ height: "100%", paddingBottom: "4px" }}>
                                  {index + 1}
                                </Space>
                              </Col>
                              <Col span={5}>
                                <ProductSearchSelect
                                  name={[index, "name"]}
                                  options={variants}
                                  onSelect={(option) => {
                                    handleProductSelect(option, index);
                                    add();
                                  }}
                                />
                              </Col>
                              <Col span={4}>
                                <ReadonlyField key={key} name={[index, "productVariant", "name"]} />
                              </Col>
                              <Col span={3}>
                                <Form.Item
                                  key={key}
                                  name={[index, "quantity"]}
                                  style={{ marginBottom: 5 }}
                                >
                                  <InputNumber
                                    id="test"
                                    precision={2}
                                    stringMode={true}
                                    onFocus={handleFocus}
                                  />
                                </Form.Item>
                              </Col>

                              <Col span={4}>
                                {(user?.role === "manager" || user?.role === "admin") && (
                                  <HistoryPriceField
                                    key={key}
                                    form={form}
                                    index={index}
                                    customer={customer}
                                    productId={form.getFieldValue([
                                      "lineItems",
                                      index,
                                      "product",
                                      "id",
                                    ])}
                                    doFetch={doFetch}
                                  />
                                )}
                              </Col>
                              <Col span={3}>
                                {(user?.role === "manager" || user?.role === "admin") && (
                                  <Form.Item
                                    key={key}
                                    name={[index, "unitPrice"]}
                                    style={{ marginBottom: 5 }}
                                  >
                                    <InputNumber
                                      precision={2}
                                      addonBefore="$"
                                      stringMode={true}
                                      onFocus={handleFocus}
                                    />
                                  </Form.Item>
                                )}
                              </Col>
                              <Col span={2}>
                                <Form.Item
                                  key={index}
                                  name={[index, "pickingStatus"]}
                                  style={{ marginBottom: 5 }}
                                >
                                  <Select
                                    bordered={false}
                                    showArrow={false}
                                    style={{ width: "100%" }}
                                    options={pickingStatusOptions}
                                  />
                                </Form.Item>
                              </Col>
                              <Col span={1}>
                                <Button key={key} onClick={() => remove(index)}>
                                  <MinusCircleOutlined />
                                </Button>
                              </Col>
                            </Row>
                            <Divider style={{ margin: 0 }} />
                          </div>
                        );
                      })}
                      <Form.Item style={{ marginTop: 15 }}>
                        <Button type="dashed" block onClick={() => add()}>
                          + Add Line
                        </Button>
                      </Form.Item>
                    </PageContainer>
                  )}
                </Form.List>
              </Card>
              <Space>
                <Typography.Text>Subtotal</Typography.Text>
                <ReadonlyNumberField
                  precision={2}
                  shouldUpdate
                  name={["totalPrice", "totalWithoutGst"]}
                />
                <Divider type="vertical" />
                <Typography.Text>GST</Typography.Text>
                <ReadonlyNumberField
                  precision={2}
                  shouldUpdate
                  name={["totalPrice", "gstAmount"]}
                />
                <Divider type="vertical" />
                <Typography.Text>Discount</Typography.Text>
                <ReadonlyNumberField
                  precision={2}
                  shouldUpdate
                  name={["totalPrice", "discountAmount"]}
                />
                <Divider type="vertical" />
                <Typography.Text>Total</Typography.Text>
                <ReadonlyNumberField precision={2} shouldUpdate name={["totalPrice", "total"]} />
                <Divider type="vertical" />
                <Typography.Text>Gross Profit</Typography.Text>
                <ReadonlyPercentField shouldUpdate name={["totalPrice", "grossProfit"]} />
                <Divider type="vertical" />
              </Space>
              <Space>
                {order.status !== "draft" ? (
                  <Button onClick={handleSave} type="primary" onKeyDown={handleKeyDown}>
                    Save
                  </Button>
                ) : (
                  <>
                    <Button onKeyDown={handleKeyDown} onClick={handleSave}>
                      Save as draft
                    </Button>
                    <Button onClick={handleSaveAndApprove} type="primary" onKeyDown={handleKeyDown}>
                      Save and Approve
                    </Button>
                  </>
                )}
              </Space>
            </PageContainer>
          </Col>
          {/* cutomer section */}
          <Col span={6}>
            <CustomerDetailCard
              customer={selectedCustomer}
              extra={<Button onClick={() => setCustomerDrawerOpen(true)}>Change</Button>}
              footer={
                <Button type="link">
                  <Space>
                    <a target="_blank" rel="noreferrer" href={`/customers/${customer.id}/history`}>
                      View orders history
                    </a>
                    <ExportOutlined />
                  </Space>
                </Button>
              }
            />
            <Card bodyStyle={{ paddingTop: "5px" }}>
              <Divider orientation="left">Delivery</Divider>
              <Space direction="horizontal">
                <Form.Item
                  label={
                    <Space>
                      Date
                      {/* {dateTag.tag && <Tag color={dateTag.tag.color}>{dateTag.tag.text}</Tag>} */}
                    </Space>
                  }
                  name="orderDate"
                >
                  <DatePicker style={{ width: 150 }} />
                </Form.Item>
              </Space>
              <Form.Item label="Fulfillment Type" name="fulfillmentType">
                <Select>
                  <Option value="delivery">Delivery</Option>
                  <Option value="pickup">Pickup</Option>
                </Select>
              </Form.Item>
              <Form.Item label="Delivery address" name="deliveryAddress">
                <Input />
              </Form.Item>
              <Form.Item label="Notes" name="notes">
                <Input.TextArea />
              </Form.Item>
              <Divider orientation="left">Packer</Divider>
              <Form.Item label="Packer Notes" name="packerNotes">
                <Input.TextArea />
              </Form.Item>
            </Card>
          </Col>
        </Row>
      </Form>
    </>
  );
};
