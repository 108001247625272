import { useState, useEffect } from "react";
import { useQuery } from "@apollo/client";
import { PageHeader, Button, Typography, Table, Tag } from "antd";
import { Link } from "react-router-dom";

import { PageContainer } from "../components/atoms/PageContainer";
import { ROUTES } from "../constants";
import { ProductsQuery } from "../graphql/products.graphql";
import { PageContentContainer } from "../components/atoms/PageContentContainer";
import { ResourceToolbar } from "../components/ResourceToolbar";
import { useAuth } from "@/hooks/useAuth";

const columns = [
  {
    title: "Name",
    dataIndex: "name",
    key: "name",
    render: (name, record) => {
      return record.__typename === "Product" ? (
        <Typography.Title level={5} style={{ marginBottom: 0 }}>
          <Link to={`/products/${record.id}/edit`}>{name} </Link>
        </Typography.Title>
      ) : (
        <Typography.Text style={{ marginBottom: 0 }}>
          {" "}
          - {record.fullName} {record.isDefaultUnit && <Tag color="success">Default</Tag>}
        </Typography.Text>
      );
    },
  },
  {
    title: "Category",
    dataIndex: ["category", "name"],
  },
];

export const Products = () => {
  const [filters, setFilters] = useState({ version: { equals: 2 } });
  const [products, setProducts] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const { user } = useAuth();

  const { data, loading, refetch, networkStatus } = useQuery(ProductsQuery, {
    variables: { filters: filters, take: 30, skip: (currentPage - 1) * 30 },
    // fetchPolicy: "cache-and-network",
    notifyOnNetworkStatusChange: true,
  });

  useEffect(() => {
    if (data?.products) {
      const productsAndVaiants = [];
      data.products.forEach((product) => {
        productsAndVaiants.push(product);
        product.productVariants.forEach((variant) => {
          productsAndVaiants.push(variant);
        });
      });
      setProducts(productsAndVaiants);
    }
  }, [data, loading]);

  const handleRefresh = () => {
    console.log("Refreshing...");
    refetch();
  };

  const handleSearch = (value) => {
    setFilters((f) => ({
      ...f,
      name: { contains: value, mode: "insensitive" },
    }));
  };
  return (
    <PageContainer>
      <PageHeader
        ghost={true}
        title="Products V2"
        subTitle={`Showing ${data?.products.length} of ${data?.productsCount} products`}
        extra={[
          <Button key="stock-take">
            <Link to={"/products/v2/stock-take"}>Stock take</Link>
          </Button>,
          <Button key="bulk-edit">
            <Link to={"/products/v2/bulk-edit"}>Bulk edit V2</Link>
          </Button>,
          user && user.role === "admin" && (
            <Button key="bulk-edit">
              <Link to={"/products/v2/change-categories"}>Manage categories</Link>
            </Button>
          ),
          <Button key="new" type="primary">
            <Link to={ROUTES.productsNew}>New product</Link>
          </Button>,
        ]}
      />
      <PageContentContainer>
        <ResourceToolbar search={{ onSearch: handleSearch }} onRefresh={handleRefresh} />
        <Table
          dataSource={products}
          columns={columns}
          rowKey="id"
          size="small"
          loading={loading || networkStatus}
          pagination={{
            total: data?.productsCount,
            pageSize: 30,
            showSizeChanger: false,
            onChange: (currentPage) => {
              setCurrentPage(currentPage);
            },
          }}
        />
      </PageContentContainer>
    </PageContainer>
  );
};
