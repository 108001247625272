export const getProductVariantsSoldBreakdown = (lineItems) => {
  const groupedLineItems = lineItems.reduce((result, lineItem) => {
    if (!lineItem.productVariant?.id) {
      console.log("lineItem: ", lineItem);
    }
    const productVariantId = lineItem.productVariant?.id || "no_variant_id";
    const notes = lineItem.notes || "no_comments";

    if (!result[productVariantId]) {
      result[productVariantId] = {};
    }

    if (!result[productVariantId][notes]) {
      result[productVariantId][notes] = {
        items: [],
        totalQuantity: 0,
      };
    }

    result[productVariantId][notes].items.push(lineItem);
    result[productVariantId][notes].totalQuantity += Number(lineItem.quantity);

    return result;
  }, {});

  return groupedLineItems;
};
