import { useQuery } from "@apollo/client";
import { useEffect, useState } from "react";
import { CategoriesQuery } from "../graphql/categories.graphql";

export const useCategories = () => {
  const { data, error, loading } = useQuery(CategoriesQuery);

  const [options, setOptions] = useState([]);

  useEffect(() => {
    if (data?.categories) {
      setOptions(
        data.categories.map(cat => ({ label: cat.name, value: cat.id }))
      );
    }
  }, [data, loading]);

  return { options, loading, error };
};
