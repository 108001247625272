import { Form, InputNumber } from "antd";

export const ReadonlyNumberField = ({
  label = "",
  name,
  precision = 2,
  shouldUpdate = true,
}) => (
  <Form.Item
    label={label}
    name={name}
    style={{ marginBottom: 5 }}
    shouldUpdate={shouldUpdate}
  >
    <InputNumber
      precision={precision}
      disabled
      stringMode={true}
      style={{
        outline: "none",
        border: "none",
        color: "#000",
        background: "none",
        textAlign: "right",
      }}
    />
  </Form.Item>
);

export const ReadonlyPercentField = ({
  label = "",
  name,
  shouldUpdate = true,
}) => (
  <Form.Item
    label={label}
    name={name}
    style={{ marginBottom: 5 }}
    shouldUpdate={shouldUpdate}
  >
    <InputNumber
      disabled
      formatter={(value) => `${(+value * 100).toFixed(2)}%`}
      stringMode={true}
      style={{
        outline: "none",
        border: "none",
        color: "#000",
        background: "none",
        textAlign: "right",
      }}
    />
  </Form.Item>
);
