//import components
import { CreateExpensesMutation } from "../graphql/expenses.graphql";
import { createExpensesObject } from "../utils";

//library
import moment from "moment";
import {
  Input,
  Space,
  DatePicker,
  Row,
  Col,
  Button,
  Card,
  Divider,
  Form,
  Typography,
  InputNumber,
  PageHeader,
} from "antd";
import { useMutation } from "@apollo/client";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useEffect, useState } from "react";

export const ExpenseCreateForm = () => {
  const [form] = Form.useForm();
  const [searchParams] = useSearchParams();
  const [type, setType] = useState("");
  console.log("searchParams: ", searchParams);
  const [createExpense] = useMutation(CreateExpensesMutation);
  const navigate = useNavigate();

  useEffect(() => {
    const t = searchParams.get("type");
    setType(t.toUpperCase());
  }, [searchParams]);

  const handleSave = async values => {
    const expensesData = createExpensesObject(values, searchParams.type);
    const res = await createExpense({
      variables: { expenseCreated: expensesData },
    });
    if (res) {
      navigate("/expenses");
    }
  };

  const onFinishFailed = errorInfo => {
    console.log("Failed:", errorInfo);
  };

  return (
    <>
      <PageHeader title={type} />
      <Card style={{ marginTop: "30px" }}>
        <Form
          name="basic"
          form={form}
          onFinish={handleSave}
          onFinishFailed={onFinishFailed}
          autoComplete="off"
        >
          <Space
            style={{
              width: "100%",
              display: "flex",
              justifyContent: "space-between",
            }}
          >
            <Form.Item label="Expense date" name="spendAt">
              <DatePicker showToday="true" defaultValue={moment()} />
            </Form.Item>
            <Form.Item hidden name="type">
              <Input defaultValue={type} />
            </Form.Item>
            <Form.Item>
              <Button type="primary" htmlType="submit">
                Save
              </Button>
            </Form.Item>
          </Space>

          <Row gutter={5} style={{ width: "70%" }}>
            <Col span={6}>
              <Typography>Expense Name</Typography>
            </Col>
            <Col span={6}>
              <Typography>Expense Amount</Typography>
            </Col>
            <Col span={6}>
              <Typography>Notes</Typography>
            </Col>
          </Row>

          <Form.List name="expenses">
            {(fields, { add, remove }) => (
              <div style={{ marginTop: "20px" }}>
                {fields.map(({ field }, index) => (
                  <Row key={index} style={{ width: "60%" }} gutter={5}>
                    <Col span={6}>
                      <Form.Item {...field} name={[index, "name"]}>
                        <Input placeholder="Expense name" />
                      </Form.Item>
                    </Col>
                    <Col span={6}>
                      <Form.Item {...field} name={[index, "amount"]}>
                        <InputNumber
                          placeholder="Expense amount"
                          stringMode={true}
                        />
                      </Form.Item>
                    </Col>
                    <Col span={6}>
                      <Form.Item {...field} name={[index, "notes"]}>
                        <Input placeholder="additional notes" />
                      </Form.Item>
                    </Col>
                    <Form.Item>
                      <Button onClick={() => remove(index)}>Remove</Button>
                    </Form.Item>
                  </Row>
                ))}
                <Divider />
                <Button onClick={() => add()}>Add</Button>
              </div>
            )}
          </Form.List>
        </Form>
      </Card>
    </>
  );
};
