import { Form, Input } from "antd";

export const ReadonlyField = ({ label = "", name }) => (
  <Form.Item label={label} name={name} style={{ marginBottom: 0 }}>
    <Input
      disabled
      style={{
        outline: "none",
        border: "none",
        color: "#000",
        background: "none",
      }}
    />
  </Form.Item>
);
