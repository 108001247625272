import { View } from "@react-pdf/renderer";
import { Row, Stack, Typography } from "./utilities";

export const BankDetailsGlory = ({ purpose = "invoice" }) => {
  return (
    <Stack sx={{ width: "50%" }}>
      <Typography type="header" sx={{ fontSize: 14 }}>
        {purpose === "invoice" ? "How to pay" : "Payment info"}
      </Typography>
      <Row>
        <Typography sx={{ width: "60%" }}>Account Name: </Typography>
        <Typography sx={{ width: "40%" }}>glory fresh</Typography>
      </Row>
      <Row>
        <Typography sx={{ width: "60%" }}>BSB Number: </Typography>
        <Typography sx={{ width: "40%" }}>062-133</Typography>
      </Row>
      <Row>
        <Typography sx={{ width: "60%" }}>Account Number: </Typography>
        <Typography sx={{ width: "40%" }}>11721488</Typography>
      </Row>

      {purpose === "invoice" ? (
        <Typography sx={{ marginTop: 2 }}>Please use your invoice number as reference</Typography>
      ) : (
        <View>
          <Typography sx={{ marginTop: 2 }}>Please send the remittance advice to</Typography>
          <Typography>gloryfresh.info1@gmail.com</Typography>
        </View>
      )}
    </Stack>
  );
};

export const BankDetailsEverfresh = ({ purpose = "invoice" }) => {
  return (
    <Stack sx={{ width: "50%" }}>
      <Typography type="header" sx={{ fontSize: 14 }}>
        {purpose === "invoice" ? "How to pay" : "Payment info"}
      </Typography>
      <Row>
        <Typography sx={{ width: "60%" }}>Account Name: </Typography>
        <Typography sx={{ width: "40%" }}>EVER FRESH GROUP</Typography>
      </Row>
      <Row>
        <Typography sx={{ width: "60%" }}>BSB Number: </Typography>
        <Typography sx={{ width: "40%" }}>062-133</Typography>
      </Row>
      <Row>
        <Typography sx={{ width: "60%" }}>Account Number: </Typography>
        <Typography sx={{ width: "40%" }}>1178 4737</Typography>
      </Row>

      {purpose === "invoice" ? (
        <Typography sx={{ marginTop: 2 }}>Please use your invoice number as reference</Typography>
      ) : (
        <View>
          <Typography sx={{ marginTop: 2 }}>Please send the remittance advice to</Typography>
          <Typography>admin@everfreshgroup.com.au</Typography>
        </View>
      )}
    </Stack>
  );
};

export const Bankdetails = ({ total, balanceDue, balance }) => (
  <View style={{ padding: "8px 0" }}>
    <Typography sx={{ marginTop: 4 }}>Terms: 0 days</Typography>
    <Typography sx={{ marginTop: 4 }}>Payment details:</Typography>
    <Typography sx={{ marginTop: 4 }}>Account: ${balanceDue}</Typography>
    {/* <Typography sx={{ marginTop: 4 }}>Total account balance: ${balance}</Typography> */}
    <Typography type="header" sx={{ fontSize: 14, marginTop: 24 }}>
      EFT payment{" "}
    </Typography>
    <Typography sx={{ marginTop: 4 }}>Account name: Newnorth Group Pty Ltd</Typography>
    <Typography sx={{ marginTop: 4 }}>BSB: 062-140</Typography>
    <Typography sx={{ marginTop: 4 }}>A/C: 1134 8356 </Typography>
    <Typography sx={{ marginTop: 4 }}>Please send the remittance advice to</Typography>
    <Typography sx={{ marginTop: 4 }}>accounts@newnorthgroup.net</Typography>
  </View>
);

export const BankdetailsOnStatement = ({ total, balance }) => (
  <View style={{ padding: "8px 0" }}>
    <Typography sx={{ marginTop: 4 }}>Terms: 0 days</Typography>
    <Typography sx={{ marginTop: 4 }}>Account owning: {balance}</Typography>
    <Typography type="header" sx={{ fontSize: 14, marginTop: 24 }}>
      EFT payment{" "}
    </Typography>
    <Typography sx={{ marginTop: 4 }}>Account name: Newnorth Group Pty Ltd</Typography>
    <Typography sx={{ marginTop: 4 }}>BSB: 062-140</Typography>
    <Typography sx={{ marginTop: 4 }}>A/C: 1134 8356 </Typography>
    <Typography sx={{ marginTop: 4 }}>Please send the remittance advice to</Typography>
    <Typography sx={{ marginTop: 4 }}>accounts@newnorthgroup.net</Typography>
  </View>
);
