import { useState, useEffect } from "react";
import { useMutation, useQuery } from "@apollo/client";
import {
  PageHeader,
  Button,
  Tabs,
  Row,
  Col,
  Form,
  Typography,
  InputNumber,
  notification,
} from "antd";
import { PageContainer } from "../components/atoms/PageContainer";
import { CategoriesWithProductsForBulkEdit } from "../graphql/categories.graphql";
import { UpdateProductVariantsMutation } from "../graphql/products.graphql";
import { useNavigate } from "react-router-dom";

const { TabPane } = Tabs;

export const ProductsBulkEdit = () => {
  const [cats, setCats] = useState([]);
  const { data } = useQuery(CategoriesWithProductsForBulkEdit, {
    fetchPolicy: "cache-and-network",
  });
  const [updateVariants, { loading }] = useMutation(UpdateProductVariantsMutation);
  const [form] = Form.useForm();
  const navigate = useNavigate();
  const [changedIds, setChangedIds] = useState(new Set());

  useEffect(() => {
    if (data?.categories) {
      setCats(data.categories);
      const temp = {};
      data.categories.forEach((cat) => {
        cat.products.forEach((product) => {
          product.productVariants.forEach((variant) => {
            temp[variant.id] = {
              ...variant,
              defaultMargin: (Number(variant.defaultMargin) * 100).toFixed(2),
            };
          });
        });
      });

      form.setFieldsValue(temp);
    }
  }, [data, form]);

  const handleFieldsChange = (changedFields) => {
    const first = changedFields[0];
    const actualField = first.name[1];
    const id = first.name[0];
    let base = 0;
    let margin = 0;

    if (actualField === "sellingPrice") {
      base = form.getFieldValue([id, "basePrice"]);
      return;
    }
    if (actualField === "basePrice") {
      base = first.value;
    } else {
      base = form.getFieldValue([id, "basePrice"]) || 0;
    }

    if (actualField === "defaultMargin") {
      margin = first.value;
    } else {
      margin = form.getFieldValue([id, "defaultMargin"]) || 0;
    }
    const selling = (parseFloat(base) * (1 + parseFloat(margin) / 100)).toFixed(2);
    form.setFields([{ name: [id, "sellingPrice"], value: selling }]);
    // changedIds is new Set () here. Set is used here to store unique values of any type
    setChangedIds(changedIds.add(id));
  };

  const handleSave = async () => {
    const values = form.getFieldsValue();

    const payload = Array.from(changedIds).map((changedId) => {
      const margin = (Number(values[changedId].defaultMargin) / 100).toFixed(4);
      return {
        where: { id: changedId },
        data: { ...values[changedId], defaultMargin: margin },
      };
    });

    const res = await updateVariants({
      variables: { data: payload },
      refetchQueries: [{ query: CategoriesWithProductsForBulkEdit }],
    });
    if (res.data) {
      notification.success({ message: `${payload.length} units updated.` });
      navigate(-1);
    }
  };

  const handleInputNumberFocus = (e) => {
    e.target.select();
  };

  return (
    <PageContainer>
      <PageHeader
        style={{ position: "sticky", top: "120px" }}
        title="Bulk edit"
        extra={[
          <Button key="1" type="primary" onClick={handleSave} loading={loading}>
            Save
          </Button>,
        ]}
      />

      <Form form={form} onFieldsChange={handleFieldsChange}>
        <Tabs defaultActiveKey={0}>
          {cats.map((cat, index) => {
            return (
              <TabPane tab={cat.name} key={index}>
                <Row
                  gutter={8}
                  style={{
                    padding: "4px 8px",
                    borderBottom: "0.5px solid lightgrey",
                  }}
                >
                  <Col span={7}>
                    <Typography.Text strong>Product</Typography.Text>
                  </Col>
                  <Col span={3}>
                    <Typography.Text strong>Base Price</Typography.Text>
                  </Col>
                  <Col span={3}>
                    <Typography.Text strong>Default Margin</Typography.Text>
                  </Col>
                  <Col span={3}>
                    <Typography.Text strong>Selling Price</Typography.Text>
                  </Col>
                </Row>
                {cat.products.map((product) => {
                  return (
                    <>
                      {product.productVariants.map((variant) => {
                        return (
                          <Row
                            key={variant.id}
                            gutter={8}
                            style={{
                              padding: "4px 8px",
                              borderBottom: "0.5px solid lightgrey",
                            }}
                          >
                            <Col span={7}>
                              <Typography.Text strong>{variant.fullName}</Typography.Text>
                            </Col>
                            <Col span={3}>
                              <Form.Item
                                name={[variant.id, "basePrice"]}
                                style={{ marginBottom: 0 }}
                              >
                                <InputNumber
                                  onFocus={handleInputNumberFocus}
                                  stringMode={true}
                                  precision={2}
                                  style={{ width: "100%" }}
                                />
                              </Form.Item>
                            </Col>
                            <Col span={3}>
                              <Form.Item
                                name={[variant.id, "defaultMargin"]}
                                style={{ marginBottom: 0 }}
                              >
                                <InputNumber
                                  onFocus={handleInputNumberFocus}
                                  stringMode={true}
                                  precision={2}
                                  formatter={(value) => `${value}%`}
                                  parser={(value) => value.replace("%", "")}
                                  style={{ width: "100%" }}
                                />
                              </Form.Item>
                            </Col>
                            <Col span={3}>
                              <Form.Item
                                name={[variant.id, "sellingPrice"]}
                                style={{ marginBottom: 0 }}
                              >
                                <InputNumber
                                  onFocus={handleInputNumberFocus}
                                  stringMode={true}
                                  precision={2}
                                  style={{ width: "100%" }}
                                />
                              </Form.Item>
                            </Col>
                          </Row>
                        );
                      })}
                    </>
                  );
                })}
              </TabPane>
            );
          })}
        </Tabs>
      </Form>
    </PageContainer>
  );
};
