import { useAuth } from "../hooks/useAuth";
import { Form, Input, Button, Card, notification } from "antd";
import { LogoWithText } from "../layouts/dashboard/header/LogoWithText";
import { useLocation, useNavigate } from "react-router-dom";
import { useEffect } from "react";

import { FullScreenWrapper } from "../components/atoms/FullScreenWrapper";

export const Login = () => {
  const { login, authenticated } = useAuth();
  const from = useLocation().state?.from || "/";
  const navigate = useNavigate();

  useEffect(() => {
    if (authenticated) {
      navigate(from, { replace: true });
    }
  }, [authenticated, navigate, from]);

  const handleLoginSubmit = async (values) => {
    const { username, password } = values;

    const res = await login(username, password);
    const result = res.data.authenticateUserWithPassword;
    if (result["__typename"] === "UserAuthenticationWithPasswordFailure") {
      notification.error({
        message: "Sorry, username/password is not corret.",
      });
    } else {
      notification.success({ message: "Successfully logged in." });
      navigate(from, { replace: true });
    }
  };
  return (
    <FullScreenWrapper>
      <LogoWithText />
      <Card style={{ width: "50%", maxWidth: "400px" }}>
        <Form
          name="basic"
          onFinish={handleLoginSubmit}
          autoComplete="off"
          layout="vertical"
          size="large"
        >
          <Form.Item
            label="Username"
            name="username"
            rules={[{ required: true, message: "Please input your username!" }]}
          >
            <Input />
          </Form.Item>

          <Form.Item
            label="Password"
            name="password"
            rules={[{ required: true, message: "Please input your password!" }]}
          >
            <Input.Password />
          </Form.Item>

          <Form.Item>
            <Button type="primary" htmlType="submit" block>
              Login
            </Button>
          </Form.Item>
        </Form>
      </Card>
    </FullScreenWrapper>
  );
};
