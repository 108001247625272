export const formatMoney = (amount = 0) => {
  let amt = +amount;
  const options = {
    style: "currency",
    currency: "AUD",
    minimumFractionDigits: 2,
  };

  const formatter = Intl.NumberFormat("en-AU", options);

  return formatter.format(amt);
};
