export const convertToFixedString = (number, precision = 2) => {
  return (+number).toFixed(precision);
};

export const calculateUnitPrice = (
  marginType,
  fixedMargin,
  productVariant,
  unitPrice = "0.00",
  pickingStatus = "pack"
) => {
  if (!productVariant) return unitPrice;
  if (pickingStatus === "na" || pickingStatus === "replace") return "0.00";

  const currentMargin = marginType === "fixed" ? fixedMargin : productVariant.defaultMargin;
  return (
    productVariant.sellingPrice ||
    convertToFixedString(+productVariant.basePrice * (1 + (+currentMargin || 0))) ||
    "0.00"
  );
};
