import { Link } from "react-router-dom";
import moment from "moment";
import { Card, Tag, Space, Typography, Divider, Avatar } from "antd";

export const CreditNoteInfoCard = ({ customer, order }) => {
  const { name, email, phone, id } = customer;

  const { updatedAt, createdAt, orderDate, confirmedAt } = order;

  return (
    <>
      <Card title="Customer">
        <Space direction="vertical">
          <Space>
            <Avatar>{name.charAt(0).toUpperCase()}</Avatar>
            <Link to={`/customers/${id}`}>{name}</Link>
            {customer.franchise && <Tag>Franchise: {customer.franchise?.name}</Tag>}
          </Space>

          <Typography.Text strong>Contact information</Typography.Text>
          <Typography.Text>Email: {email}</Typography.Text>
          <Typography.Text>Phone: {phone}</Typography.Text>
        </Space>
      </Card>
      <Divider />
      <Card title="Addtional Info">
        <Space>
          <Space direction="vertical">
            <Typography.Text strong> Date</Typography.Text>
            <Typography.Text>{moment(orderDate).format("DD/MM/YYYY")}</Typography.Text>
          </Space>
        </Space>
      </Card>
      <Card title="Timestamps">
        <Space direction="vertical">
          <Space>
            <Typography.Text strong>Created at: </Typography.Text>
            <Typography.Text>{moment(createdAt).format("YYYY-MM-DD, HH:mm:ss")}</Typography.Text>
          </Space>
          <Space>
            <Typography.Text strong>Confirmed at: </Typography.Text>
            <Typography.Text>
              {confirmedAt ? moment(confirmedAt).format("YYYY-MM-DD, HH:mm:ss") : "not confirmed"}
            </Typography.Text>
          </Space>
          <Space>
            <Typography.Text strong>Updated at: </Typography.Text>
            <Typography.Text>{moment(updatedAt).format("YYYY-MM-DD, HH:mm:ss")}</Typography.Text>
          </Space>
        </Space>
      </Card>
    </>
  );
};
