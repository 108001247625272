import {
  MoneyFieldV2,
  ProductNameSearchV2,
  RemoveButton,
  PickingStatusSelectV2,
  CommentsFieldV2,
  // VariantsSelectV2,
  UnitPriceFieldV2,
  VariantsReadonlyFieldV2,
} from "../fields";
import { HistoryPriceV3 } from "../fields/HistoryPriceV3";

export const fullColumns = [
  {
    title: "#",
    key: "#",
    render: (value, record, index) => `${index + 1}`,
  },
  {
    title: "Description",
    key: "description",
    render: (value, record, index) => <ProductNameSearchV2 index={index} />,
  },
  {
    title: "Comments",
    key: "option",
    render: (value, record, index) => <CommentsFieldV2 index={index} />,
    width: 240,
  },
  {
    title: "Unit",
    key: "unit",
    render: (value, record, index) => <VariantsReadonlyFieldV2 index={index} />,
    width: 160,
  },

  {
    title: "Qty",
    key: "qty",
    render: (value, record, index) => <MoneyFieldV2 precision={2} name={[index, "quantity"]} />,
    width: 128,
  },

  {
    title: "History",
    key: "history",
    render: (value, record, index) => <HistoryPriceV3 index={index} />,
    width: 240,
  },
  {
    title: "Price",
    key: "price",
    render: (value, record, index) => <UnitPriceFieldV2 precision={2} index={index} />,
    width: 240,
  },
  {
    title: "Status",
    key: "status",
    render: (value, record, index) => <PickingStatusSelectV2 index={index} />,
    width: 160,
  },
  {
    title: "X",
    key: "remove",
    render: (value, record, index) => <RemoveButton index={index} />,
  },
];

export const draftColumns = [
  {
    title: "#",
    key: "#",
    render: (value, record, index) => `${index + 1}`,
  },
  {
    title: "Description",
    key: "description",
    render: (value, record, index) => <ProductNameSearchV2 index={index} />,
    width: 320,
  },
  {
    title: "Unit",
    key: "unit",
    render: (value, record, index) => <VariantsReadonlyFieldV2 index={index} />,
    width: 180,
  },
  {
    title: "Qty",
    key: "qty",
    render: (value, record, index) => (
      <MoneyFieldV2 precision={2} name={[index, "quantity"]} index={index} addonBefore={null} />
    ),
    width: 120,
  },
  {
    title: "Comments",
    key: "option",
    render: (value, record, index) => <CommentsFieldV2 index={index} />,
    width: 240,
  },
  {
    title: "Status",
    key: "status",
    render: (value, record, index) => <PickingStatusSelectV2 index={index} />,
    width: 128,
  },
  {
    title: "X",
    key: "remove",
    render: (value, record, index) => <RemoveButton index={index} />,
  },
];
