import { Menu, Dropdown, Button } from "antd";
import { ExportOutlined } from "@ant-design/icons";
import _ from "lodash";

export const MoveOrderToTheOtherRound = ({ trucks, truckName, order, setTrucks, setTempData }) => {
  // const result = Object.keys(trucks).reduce(
  //   (pre, cur) => {
  //     cur.split("-")[0] === "R1"
  //       ? pre["R1"].push({ key: cur, label: `Move to ${cur}` })
  //       : pre["R2"].push({ key: cur, label: `Move to ${cur}` });
  //     return pre;
  //   },
  //   { R1: [], R2: [] }
  // );

  const result = Object.keys(trucks).map((cur) => {
    return { key: cur, label: `Move to ${cur}` };
  });

  const handleOrderMove = ({ key }) => {
    const sourceIds = trucks[truckName].orderIds;
    const currentIndex = sourceIds.indexOf(order.id);
    sourceIds.splice(currentIndex, 1);

    const destOrderIds = trucks[key].orderIds;
    destOrderIds.push(order.id);

    const updatedTrucks = _.cloneDeep(trucks);
    setTrucks(updatedTrucks);
    setTempData(updatedTrucks);
  };

  const menu = <Menu onClick={handleOrderMove} items={result} />;

  // const checkRound = truckName.split("-")[0] === "R1";

  return (
    <Dropdown overlay={menu} trigger={["click"]}>
      <Button type="text" icon={<ExportOutlined />} />
    </Dropdown>
  );
};
