import { useContext } from "react";

import { Form, Input, Row, Col, Button, Card } from "antd";
import { ExportOutlined } from "@ant-design/icons";

import { CustomerDetailCard } from "../CustomerDetailCard";

import { CustomerContext } from "../../contexts/CustomerContext";
import { LineItemsEditingTable } from "./LineItemsEditingTable";
import { FormListProvider } from "./FormListContext";
import { OrderFormContext } from "../../contexts/OrderFormContext";
import { OrderTotal } from "./OrderTotal";
import { getOrderTotal } from "../../utils";
import { BasicInfo } from "./OrderBaiscInfo";

export const OrderFormV2 = () => {
  const { customer } = useContext(CustomerContext);
  const { form, isEditing } = useContext(OrderFormContext);

  const handleFormValuesChange = (changedValues, allValues) => {
    if (changedValues.lineItems) {
      const total = getOrderTotal(allValues.lineItems);
      form.setFields([{ name: "total", value: total }]);
    }
  };

  const handleFormFieldsChange = (changed) => {
    if (!changed[0]) return;
    const { name, value } = changed[0];
    const [, index, fieldName] = name;
    if (fieldName === "pickingStatus" && (value === "na" || value === "replace")) {
      // lineItem pickingStatus changed to na / replace
      form.setFields([{ name: ["lineItems", index, "unitPrice"], value: "0.00" }]);
    }
  };

  return (
    <Form
      form={form}
      onValuesChange={handleFormValuesChange}
      onFieldsChange={handleFormFieldsChange}
      layout="vertical"
    >
      <Row gutter={8}>
        <Col xs={24} xl={19}>
          <Card bordered={false} bodyStyle={{ padding: "0 0 8px" }}>
            <FormListProvider name="lineItems">
              <LineItemsEditingTable isEditing={isEditing} />
            </FormListProvider>
            <OrderTotal />
            <Form.Item hidden name="formUpdatedAt">
              <Input disabled />
            </Form.Item>
          </Card>
        </Col>
        <Col xs={24} xl={5}>
          <CustomerDetailCard
            customer={customer}
            footer={[
              <Button type="link" icon={<ExportOutlined />}>
                <a target="_blank" rel="noreferrer" href={`/customers/${customer.id}/history`}>
                  Order history
                </a>
              </Button>,
            ]}
          />
          <BasicInfo />
        </Col>
      </Row>
    </Form>
  );
};
