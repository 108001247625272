import { useState, useEffect } from "react";
import { Table, Space, PageHeader, Button, Typography, Select, Input } from "antd";
import { EditOutlined } from "@ant-design/icons";
import { ROUTES } from "../constants";
import { useQuery } from "@apollo/client";
import { Link, useSearchParams } from "react-router-dom";
import moment from "moment";
import { VendorsMap } from "../constants";
import { ToolbarWrapper } from "../components/atoms/ToolbarWrapper";
import { SortedCustomersQuery } from "../graphql/customers.graphql";
import { PageContentContainer } from "../components/atoms/PageContentContainer";
import { PageContainer } from "../components/atoms/PageContainer";
import { CUSTOMER_SORT_OPTIONS } from "../constants";
import { SyncOutlined } from "@ant-design/icons";
import { SalespeopleQuery } from "../graphql/users.graphql";
import axios from "axios";
import { debounce, set } from "lodash";
import { formatMoney } from "@/utils";

const columns = [
  {
    title: "Customer",
    dataIndex: "name",
    key: "name",
    render: (customerName, record) => {
      return (
        <>
          <Typography.Title level={5} style={{ marginBottom: 0 }}>
            <Link to={`/customers/${record.id}`} preventScrollReset={true}>
              {customerName}
            </Link>
          </Typography.Title>
          <span>{record.organisation?.name}</span>
        </>
      );
    },
  },
  {
    title: "Address",
    dataIndex: "deliveryAddress",
    key: "deliveryAddress",
  },
  {
    title: "No. of orders",
    dataIndex: "ordersCount",
    key: "ordersCount",
    render: (_, record) => `${record._count.orders} orders`,
  },
  {
    title: "Last order",
    dataIndex: "lastOrder",
    key: "lastOrder",
    render: (_, record) => {
      if (record.orders.length > 0) {
        return moment(record.orders[0].orderDate).fromNow();
      }
    },
  },
  {
    title: "Account balance",
    dataIndex: "accountBalance",
    key: "accountBalance",
    align: "right",
    render: formatMoney,
  },
  {
    title: "Vendor",
    dataIndex: "invoiceTemplate",
    key: "vendor",
    render: (value) => VendorsMap[value].label,
  },
  {
    title: "Salesperson",
    dataIndex: ["salesperson", "name"],
    key: "salesperson.name",
  },
  {
    title: "Actions",
    dataIndex: "actions",
    key: "actions",
    align: "right",
    render: (_, record) => {
      return (
        <Space>
          <Button>
            <Link to={`/customers/${record.id}/edit`}>
              Edit <EditOutlined />
            </Link>
          </Button>
        </Space>
      );
    },
  },
];

const pageSize = 50;

const fetchCustomers = async ({ query, isFranchise = false, invoiceTemplate, take, skip }) => {
  const response = await axios(`${process.env.REACT_APP_REST_API_ENDPOINT}/customers`, {
    params: {
      query,
      isFranchise,
      take,
      skip,
      invoiceTemplate,
    },
  });

  if (response.status >= 200) {
    return response.data;
  }
};

const fetchAllCustomers = async () => {
  const response = await axios(`${process.env.REACT_APP_REST_API_ENDPOINT}/customers/all`);
  if (response.status >= 200) {
    return response.data;
  }
};

const filterCustomers = (customers, query, vendor) => {
  if (!query.length && !vendor.length) {
    return customers;
  }

  return customers.filter((customer) => {
    if (query.length) {
      const name = customer.name.toLowerCase();
      const code = customer.customerCode.toLowerCase();
      const queryLower = query.toLowerCase();
      if (name.includes(queryLower) || code.includes(queryLower)) {
        return true;
      }
    }

    if (vendor.length) {
      if (customer.invoiceTemplate === vendor) {
        return true;
      }
    }

    return false;
  });
};

const sortCutomers = (customers, sort) => {
  if (!sort) {
    return customers;
  }
  const [field, order] = sort.split("=");

  customers = customers.sort((a, b) => {
    if (field === "ordersCount") {
      if (order === "1") {
        return a._count.orders > b._count.orders ? 1 : -1;
      } else {
        return a._count.orders < b._count.orders ? 1 : -1;
      }
    }
    if (field === "lastOrder") {
      if (order === "-1") {
        if (a.orders.length === 0) return 1;
        if (b.orders.length === 0) return -1;
        return a.orders[0].orderDate < b.orders[0].orderDate ? 1 : -1;
      } else {
        if (a.orders.length === 0) return 1;
        if (b.orders.length === 0) return -1;
        return a.orders[0].orderDate > b.orders[0].orderDate ? 1 : -1;
      }
    }
    if (order === "1") {
      return a[field] > b[field] ? 1 : -1;
    } else {
      return a[field] < b[field] ? 1 : -1;
    }
  });
  return customers;
};

const paginateCustomers = (customers, page, pageSize) => {
  const start = (page - 1) * pageSize;
  const end = start + pageSize;
  return customers.slice(start, end);
};

const getDisplayedCustomers = ({ customers, query, vendor, sort, page = 1, pageSize = 20 }) => {
  customers = filterCustomers(customers, query, vendor);
  customers = sortCutomers(customers, sort);
  customers = paginateCustomers(customers, page, pageSize);
  return customers;
};

export const Customers = () => {
  const [customers, setCustomers] = useState([]);
  const [totalCount, setTotalCount] = useState(0);
  const [displayedCustomers, setDisplayedCustomers] = useState([]);
  const [searchParams, setSearchParams] = useSearchParams();
  const [currentPage, setCurrentPage] = useState(Number(searchParams.get("page") || "1"));
  const [query, setQuery] = useState(searchParams.get("query") || "");
  const [vendor, setVendor] = useState(searchParams.get("vendor") || "");
  const [loading, setLoaidng] = useState(false);

  // useEffect(() => {
  //   const fetchAllCustomersData = async () => {
  //     setLoaidng(true);
  //     const res = await fetchAllCustomers();
  //     console.log("res: ", res);
  //     if (res) {
  //       setCustomers(res);
  //     }
  //     setLoaidng(false);
  //   };

  //   fetchAllCustomersData();
  // }, []);

  useEffect(() => {
    // if (!customers.length) return;
    const vendor = searchParams.get("vendor") || "";
    const query = searchParams.get("query") || "";
    const currentPage = Number(searchParams.get("page") || "1");
    const sort = searchParams.get("sort") || "name=1";

    setCurrentPage(currentPage);
    setVendor(vendor);
    setQuery(query);
    // setSort

    // const results = getDisplayedCustomers({
    //   customers,
    //   query,
    //   vendor,
    //   sort,
    //   page: currentPage,
    //   pageSize,
    // });
    // setDisplayedCustomers(results);
    // setLoaidng(true);
    // fetchCustomers({ query, vendor, take: pageSize, skip: (currentPage - 1) * pageSize }).then(
    //   (res) => {
    //     console.log("res: ", res);
    //     setCustomers(res);
    //     setLoaidng(false);
    //   }
    // );

    const fetchCustomersDatat = async () => {
      setLoaidng(true);
      const res = await fetchCustomers({
        query,
        vendor,
        take: pageSize,
        skip: (currentPage - 1) * pageSize,
      });
      if (res) {
        setDisplayedCustomers(res.customers);
        setTotalCount(res.total);
      }
      setLoaidng(false);
    };

    fetchCustomersDatat();
  }, [searchParams]);

  const handleSearch = (value) => {
    setQuery(value);
    setSearchParams({ query: value, page: currentPage, vendor }, { replace: true });
  };

  const handleVendorFilterChange = (value) => {
    setVendor(value);
    setSearchParams({ query, page: currentPage, vendor: value }, { replace: true });
  };

  const handlePageChange = (page) => {
    setCurrentPage(page);
    setSearchParams({ query, page, vendor }, { replace: true });
  };

  const handleSortChange = (value) => {
    console.log("value: ", value);
    setSearchParams({ query, page: currentPage, vendor, sort: value }, { replace: true });
  };
  return (
    <PageContainer>
      <PageHeader
        ghost={true}
        title="Customers"
        subTitle={`Showing ${Math.min(
          displayedCustomers?.length,
          pageSize
        )} customers of ${totalCount}`}
        extra={[
          <Link key="new-customer" to={ROUTES.customersNew}>
            <Button type="primary">New customer</Button>
          </Link>,
        ]}
      />

      <PageContentContainer>
        <ToolbarWrapper>
          <Space>
            <Input.Search
              placeholder="Search name or code"
              enterButton
              allowClear
              defaultValue={query}
              onSearch={handleSearch}
              style={{ width: 320 }}
            />
            <Select
              defaultValue=""
              style={{ width: 145 }}
              onChange={handleVendorFilterChange}
              value={vendor}
              options={[
                {
                  value: "",
                  label: "All vendors",
                },
                {
                  value: "NEWNORTH",
                  label: "Newnorth",
                },
                {
                  value: "GLORY_FRESH",
                  label: "Glory Fresh",
                },
                {
                  value: "EVER_FRESH",
                  label: "Everfresh Group",
                },
              ]}
            />
          </Space>
          <Space>
            <Select
              disabled
              defaultValue="createdAt=-1"
              style={{ width: 180 }}
              options={[
                {
                  value: "name=1",
                  label: "Name A-Z",
                },
                {
                  value: "name=-1",
                  label: "Name Z-A",
                },
                {
                  value: "ordersCount=1",
                  label: "Orders count↑",
                },
                {
                  value: "ordersCount=-1",
                  label: "Orders count↓",
                },
                {
                  value: "lastOrder=1",
                  label: "Last order ↑",
                },
                {
                  value: "lastOrder=-1",
                  label: "Last order ↓",
                },
                {
                  value: "accountBalance=1",
                  label: "Account balance ↑",
                },
                {
                  value: "accountBalance=-1",
                  label: "Account balance ↓",
                },
                {
                  value: "createdAt=1",
                  label: "Old - new",
                },
                {
                  value: "createdAt=-1",
                  label: "New - old",
                },
              ]}
              onChange={handleSortChange}
            />
            <Button icon={<SyncOutlined />}>Refresh</Button>
          </Space>
        </ToolbarWrapper>
        <Table
          dataSource={displayedCustomers}
          columns={columns}
          rowKey="id"
          size="medium"
          loading={loading}
          pagination={{
            current: currentPage,
            // total: displayedCustomers?.length || 0,
            total: totalCount,
            pageSize: pageSize,
            showSizeChanger: false,
            onChange: handlePageChange,
          }}
        />
      </PageContentContainer>
    </PageContainer>
  );
};
