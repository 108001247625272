import { View } from "@react-pdf/renderer";

export const Stack = ({ children, sx = {} }) => {
  return (
    <View
      style={{
        display: "flex",
        flexDirection: "column",
        ...sx,
      }}
    >
      {children}
    </View>
  );
};
