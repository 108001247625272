import { Text } from "@react-pdf/renderer";

export const Typography = ({ children, type, sx = {} }) => {
  let style = { fontSize: 10, marginBottom: 2 };
  switch (type) {
    case "header":
      style = { fontSize: 18, marginBottom: 3 };
      break;
    case "subtext":
      style = { fontSize: 8, marginBottom: 2, color: "grey" };
      break;
    default:
      style = { fontSize: 10, marginBottom: 2 };
      break;
  }
  return <Text style={{ ...style, ...sx }}>{children}</Text>;
};
