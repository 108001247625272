import { gql } from "@apollo/client";

export const DeliveryRunQuery = gql`
  query DeliveryRunQuery($name: String) {
    deliveryRun(where: { name: $name }) {
      name
      runsheet
    }
  }
`;

export const UpdateRunsheetMutation = gql`
  mutation UpdateRunsheetMutation($name: String, $runsheet: JSON) {
    updateDeliveryRun(where: { name: $name }, data: { runsheet: $runsheet }) {
      name
      runsheet
      updatedAt
    }
  }
`;

export const CreateRunsheetMutation = gql`
  mutation CreateRunsheetMutation($name: String, $runsheet: JSON) {
    createDeliveryRun(data: { name: $name, runsheet: $runsheet }) {
      name
      runsheet
      updatedAt
    }
  }
`;
