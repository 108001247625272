import { useQuery } from "@apollo/client";
import { useEffect, useState } from "react";
import { CustomersForOrderQuery } from "../graphql/customers.graphql";

export const useAllCustomersOptions = () => {
  const { data, error, loading } = useQuery(CustomersForOrderQuery);
  const [customersOptions, setCustomersOptions] = useState([]);
  useEffect(() => {
    if (data?.customers) {
      const options = data.customers.map((customer) => {
        const name = customer.franchise
          ? `${customer.name} - ${customer.franchise.name}`
          : customer.name;
        return {
          label: name,
          value: name,
          id: customer.id,
          address: customer.deliveryAddress,
          isFrequent: customer.isFrequentCustomer,
          franchise: customer.franchise?.name,
          salesperson: customer.salesperson?.name,
          accountBalance: customer.accountBalance,
        };
      });
      setCustomersOptions(options);
    }
  }, [data]);

  return { options: customersOptions, error, loading };
};
