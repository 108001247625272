import { useEffect, useState } from "react";

import { UnpaidOrdersByCustomerIdQuery } from "../graphql/payments.graphql";
import { useLazyQuery } from "@apollo/client";
import { PageContainer } from "../components/atoms/PageContainer";
import { PageHeader } from "antd";
import { PageContentContainer } from "../components/atoms/PageContentContainer";
import { CustomerSeletionTable } from "../components/CustomerSelectionTable";
import { PaymentAllocation } from "../components/PaymentAllocation";

export const NewPayment = () => {
  const [orders, setOrders] = useState([]);
  const [activeCustomer, setActiveCustomer] = useState({});

  const [getUnpaidOrdersByCustomerId, { data }] = useLazyQuery(UnpaidOrdersByCustomerIdQuery, {
    fetchPolicy: "no-cache",
  });

  // the datasource selected in paymentAllocation page and would be passed to the modal
  const [selectedOrder, setSelectedOrder] = useState([]);

  useEffect(() => {
    if (data?.orders) {
      const mapped = data.orders.map((order, index) => ({
        ...order,
        discount: "",
        amountApplied: 0,
        amountAppliedMax: order.balanceDue,
        index: index,
      }));
      setOrders(mapped);
    }
  }, [data]);

  const rowSelection = {
    onSelect: (record, selected, selectedRows) => {
      setSelectedOrder(selectedRows);
      if (selected) {
        const selectedIndex = orders.findIndex((o) => record.id === o.id);
        const newRecord = { ...record, amountApplied: record.amountAppliedMax };
        const temp = [...orders];
        temp.splice(selectedIndex, 1, newRecord);
        setOrders(temp);
      } else {
        const selectedIndex = orders.findIndex((o) => record.id === o.id);
        const newRecord = { ...record, amountApplied: 0 };
        const temp = [...orders];
        temp.splice(selectedIndex, 1, newRecord);
        setOrders(temp);
      }
    },
    onSelectAll: (selected, selectedRows) => {
      setSelectedOrder(selectedRows);
      if (selected) {
        const temp = [...orders];
        setOrders(temp.map((order) => ({ ...order, amountApplied: order.amountAppliedMax })));
      } else {
        const temp = [...orders];
        setOrders(temp.map((order) => ({ ...order, amountApplied: 0 })));
      }
    },
  };

  const handleCustomerSelect = (record) => {
    getUnpaidOrdersByCustomerId({ variables: { customerId: record.id } });
    setActiveCustomer(record);
  };

  return (
    <PageContainer>
      <PageHeader
        title="Allocate new payment"
        subTitle="Showing first 50 customers, you can search customer by name or customer code"
      />
      <PageContentContainer>
        {activeCustomer.id ? (
          <PaymentAllocation
            items={orders}
            setItems={setOrders}
            rowSelection={{ ...rowSelection }}
            selectedOrder={selectedOrder}
            activeCustomer={activeCustomer}
          />
        ) : (
          <CustomerSeletionTable onCustomerSelect={handleCustomerSelect} />
        )}
      </PageContentContainer>
    </PageContainer>
  );
};
