import { useContext, useEffect, useState } from "react";
import { Button, Typography, Tooltip, Space, Popconfirm } from "antd";
import moment from "moment";
import { OrderFormContext } from "../../../contexts/OrderFormContext";
import { QuestionCircleOutlined } from "@ant-design/icons";
import { getOrderTotal } from "../../../utils";

export const HistoryPriceV3 = ({ index }) => {
  const { form, historyData, historyDataLoading } = useContext(OrderFormContext);
  console.log("historyDataLoading: ", historyDataLoading);
  const [historyPrice, setHistoryPrice] = useState([]);

  // const [productVariantId, setProductVariantId] = useState(null);
  const variantId = form.getFieldValue(["lineItems", index, "productVariantId"]);
  const productId = form.getFieldValue(["lineItems", index, "product", "id"]);

  useEffect(() => {
    const productForCurrentItem = historyData.find((prod) => prod.id === productId);

    if (productForCurrentItem) {
      const history = productForCurrentItem.productVariants.reduce((prev, current) => {
        const historyLienItem = current.lineItems[0];
        if (historyLienItem) {
          return [
            ...prev,
            {
              name: current.name,
              productVariantId: current.id,
              customerName: historyLienItem?.order?.customer?.name || "N/A",
              orderName: historyLienItem?.order?.name || "N/A",
              price: historyLienItem?.unitPrice || "N/A",
              updatedAt: historyLienItem?.updatedAt
                ? moment(historyLienItem?.updatedAt).format("DD/MM")
                : "N/A",
            },
          ];
        } else {
          return prev;
        }
      }, []);

      setHistoryPrice(history);
    }
  }, [historyData]);

  const setPrice = (price) => {
    if (!price) return;
    form.setFields([
      {
        name: ["lineItems", index, "unitPrice"],
        value: price,
      },
    ]);
    const total = getOrderTotal(form.getFieldValue().lineItems);
    form.setFields([{ name: "total", value: total }]);
  };

  const handleConfirm = (price) => {
    setPrice(price);
  };

  if (historyDataLoading) return <Typography.Text type="secondary">Loading...</Typography.Text>;

  return (
    <div key={`history-${index}`}>
      {historyPrice.length ? (
        historyPrice.map((data, index) => {
          return (
            <Space style={{ width: "100%" }} size="small" key={`history-${index}`}>
              <Tooltip
                key={index}
                title={`${data?.customerName} #${data?.orderName} [${data.updatedAt}]`}
              >
                <QuestionCircleOutlined />
              </Tooltip>
              <span>{data.name}</span>
              {variantId === data.productVariantId ? (
                <Button type="link" size="small" onClick={() => setPrice(data?.price)}>
                  ${data?.price}
                </Button>
              ) : (
                <Popconfirm
                  title="Different unit, use this price?"
                  onConfirm={() => handleConfirm(data?.price)}
                  okText="Yes"
                  cancelText="No"
                >
                  <span style={{ cursor: "pointer" }}>${data?.price}</span>
                </Popconfirm>
              )}
            </Space>
          );
        })
      ) : (
        <Typography.Text type="secondary">N/A</Typography.Text>
      )}
    </div>
  );
};
