import moment from "moment";

export const getDateColor = (paymentDate) => {
  const datetime = moment(paymentDate);
  const diffDays = moment().diff(datetime, "days");
  if (diffDays >= 30) {
    return "danger";
  } else if (diffDays >= 14) {
    return "warning";
  } else if (diffDays >= 7) {
    return "success";
  }
  return null;
};
