import styled from "styled-components";

export const PageContentContainer = styled.div`
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0 0 24px 0;
  background: #fff;
  border-radius: 5px;
  box-shadow: 0 0 2px 0 rgb(145 158 171 / 20%), 0 12px 24px -4px rgb(145 158 171 / 12%);
`;
