import { Table } from "antd";
import { MoneyCell } from "../tablecells";
import { formatMoney } from "../../utils";

export const LineItemsColumns = [
  {
    title: "#",
    dataIndex: "sortingIndex",
    key: "sortingIndex",
    render: (text) => Number(text) + 1,
  },
  {
    title: "Description",
    dataIndex: "name",
    key: "name",
  },

  {
    title: "QTY",
    dataIndex: "quantity",
    key: "qty",
    align: "right",
  },
  {
    title: "Price",
    dataIndex: "unitPrice",
    key: "price",
    ...MoneyCell,
  },
  {
    title: "Line Total",
    dataIndex: "amount",
    key: "amount",
    align: "right",
    render: (_, record) => formatMoney(+record.quantity * +record.unitPrice),
  },
];

export const CnDetailItemsTable = ({ items, loading }) => {
  return (
    <Table
      size="small"
      pagination={false}
      columns={LineItemsColumns}
      dataSource={items}
      loading={loading}
      rowKey="id"
    />
  );
};
