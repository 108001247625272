import {
  Form,
  Space,
  Input,
  InputNumber,
  Row,
  Col,
  Button,
  Card,
  Divider,
  Typography,
  DatePicker,
  Drawer,
} from "antd";
import { ExportOutlined, MinusCircleOutlined, CaretDownOutlined } from "@ant-design/icons";
import { useState, useEffect, useContext } from "react";
import { useLazyQuery } from "@apollo/client";
import { useLocation } from "react-router-dom";

import { SingleCustomerForOrderQuery } from "../../graphql/customers.graphql";

import { CustomerDetailCard } from "../CustomerDetailCard";
import { CustomerSelection } from "../CustomerSelection";
import { ProductSearchSelect } from "../formFields/ProductSearchSelect";
import { PageContainer } from "../atoms/PageContainer";
import { ReadonlyField } from "../formFields/ReadonlyField";
import { ProductsContext } from "../../contexts/ProductsContext";
import { calculateUnitPrice } from "../../utils";
import moment from "moment-timezone";

const initialValues = {
  settings: {
    isFixedMargin: false,
  },
  lineItems: [undefined],
  //TODO: Property "invisibleItem" is just used for force-rerendering the form. It does not show up on this page.
  invisibleItem: "",
};
const { RangePicker } = DatePicker;

export const QuotationForm = ({ quotation = {}, customer = {}, setCustomer, onSave }) => {
  const [selectedCustomer, setSelectedCustomer] = useState(customer);
  const [fetchCustomer, { data: newCustomer }] = useLazyQuery(SingleCustomerForOrderQuery, {
    fetchPolicy: "network-only",
  });
  const [customerDrawerOpen, setCustomerDrawerOpen] = useState(false);
  const [form] = Form.useForm();
  const location = useLocation();
  const { variants } = useContext(ProductsContext);
  const [selectedIndex, setSelectedIndex] = useState(-1);
  const [selectedVariant, setSelectedVariant] = useState("");
  const marginType = "default";

  useEffect(() => {
    if (location.pathname !== "/quotations/new") {
      const tempData = { ...quotation };
      form.setFieldsValue(tempData);
    }
  }, [form, quotation, location]);

  useEffect(() => {
    if (customer) {
      setSelectedCustomer(customer);
      form.setFields([
        { name: "deliveryAddress", value: customer.deliveryAddress },
        { name: "notes", value: customer.notes },
      ]);
    }
    if (newCustomer?.customer && setCustomer) {
      setSelectedCustomer(newCustomer?.customer);
      setCustomerDrawerOpen(false);
      setCustomer(newCustomer?.customer);
      form.setFields([
        {
          name: "deliveryAddress",
          value: newCustomer?.customer.deliveryAddress,
        },
        { name: "notes", value: newCustomer?.customer.notes },
      ]);
    }
  }, [customer, setCustomer, newCustomer, form]);

  const handleProductSelect = (option, index) => {
    if (!option.variant) {
      const lineItem = {
        name: "",
        quantity: "1",
        unitPrice: "10.0",
        pickingStatus: "pack",
        isOpenItem: true,
      };
      form.setFields([{ name: ["lineItems", index], value: lineItem }]);
      return;
    }

    const currentVariant = option.variant;
    const unitPrice = calculateUnitPrice(marginType, customer.fixedMargin, currentVariant);
    const lineItem = {
      name: currentVariant.product.name,
      productVariant: {
        name: currentVariant.name,
        id: currentVariant.id,
        basePrice: currentVariant.basePrice,
        defaultMargin: currentVariant.defaultMargin,
      },
      product: currentVariant.product,
      // packagingUnit: currentVariant.name,
      unitPrice: unitPrice,
      quantity: "1",
      pickingStatus: "pack",
      cost: currentVariant.basePrice,
      isOpenItem: false,
    };
    form.setFields([{ name: ["lineItems", index], value: lineItem }]);
    setSelectedIndex(index);
    setSelectedVariant(currentVariant.id);
  };

  const handleFinish = async (values, isApproving = false) => {
    if (isApproving) values.status = "confirmed";
    // let newValues = values.lineItems.filter((item) => item.name !== undefined);
    values = { ...values, start: moment(), end: moment() };
    delete values.dateRange;
    onSave(values);
  };

  const handleCustomerDrawerClose = () => {
    setCustomerDrawerOpen(false);
  };

  const handleCustomerChange = (_, { id }) => {
    fetchCustomer({ variables: { id: id } });
  };

  const handleFocus = (e) => {
    e.target.select();
  };

  const handleFieldsChange = (e) => {
    console.log("e", e);
  };
  const isDuplicate = (index) => {
    if (!selectedVariant) return false;
    return (
      index !== selectedIndex &&
      !form.getFieldValue().lineItems[index]?.isOpenItem &&
      form.getFieldValue().lineItems[index]?.productVariant?.id === selectedVariant
    );
  };

  const handleSaveDraft = () => {
    const values = form.getFieldsValue();
    handleFinish(values);
  };
  const handleSaveApprove = () => {
    const values = form.getFieldsValue();
    handleFinish(values, true);
  };

  return (
    <>
      <Drawer
        title="Basic Drawer"
        placement="right"
        width="50%"
        onClose={handleCustomerDrawerClose}
        visible={customerDrawerOpen}
      >
        <CustomerSelection onCustomerSelect={handleCustomerChange} />
      </Drawer>
      <Form
        layout="vertical"
        form={form}
        onFieldsChange={handleFieldsChange}
        initialValues={initialValues}
      >
        <Row gutter={16}>
          <Col span={18} style={{ marginTop: "-15px" }}>
            <PageContainer>
              <Card>
                <Row align="center" gutter={0}>
                  <Col span={1}>
                    <Typography.Text strong></Typography.Text>
                  </Col>
                  <Col span={1}>
                    <Typography.Text strong>#</Typography.Text>
                  </Col>
                  <Col span={6}>
                    <Typography.Text strong>Item</Typography.Text>
                  </Col>
                  <Col span={4}>
                    <Typography.Text strong style={{ paddingLeft: "11px" }}>
                      Unit
                    </Typography.Text>
                  </Col>
                  <Col span={4}>
                    <Typography.Text strong>Price</Typography.Text>
                  </Col>
                  <Col span={4}>
                    <Typography.Text strong></Typography.Text>
                  </Col>
                </Row>
                <Form.List name="lineItems" shouldUpdate={true}>
                  {(fields, { add, remove, move }) => (
                    <PageContainer>
                      {fields.map(({ key }, index) => {
                        return (
                          <div key={key}>
                            <Row
                              align="center"
                              key={key}
                              gutter={0}
                              style={{
                                width: "100%",
                                padding: "5px 3px 0px 0px",
                                backgroundColor: isDuplicate(index) ? "orange" : "initial",
                              }}
                            >
                              <Col span={1}>
                                <Space key={key}>
                                  <Button
                                    type="link"
                                    icon={<CaretDownOutlined />}
                                    onClick={() => {
                                      move(index, index + 1);
                                    }}
                                  />
                                </Space>
                              </Col>
                              <Col span={1}>
                                <Space style={{ height: "100%", paddingBottom: "4px" }}>
                                  {index + 1}
                                </Space>
                              </Col>
                              <Col span={6}>
                                <ProductSearchSelect
                                  name={[index, "name"]}
                                  options={variants}
                                  onSelect={(option) => {
                                    handleProductSelect(option, index);
                                    add();
                                  }}
                                />
                              </Col>
                              <Col span={4}>
                                <ReadonlyField
                                  key={key}
                                  name={[index, "productVariant", "name"]}
                                  rules={[{ required: true, message: "Unit is required!" }]}
                                />
                              </Col>
                              <Col span={4}>
                                <Form.Item
                                  key={key}
                                  name={[index, "unitPrice"]}
                                  style={{ marginBottom: 5 }}
                                  rules={[{ required: true, message: "Price is required!" }]}
                                >
                                  <InputNumber
                                    precision={2}
                                    addonBefore="$"
                                    stringMode={true}
                                    onFocus={handleFocus}
                                  />
                                </Form.Item>
                              </Col>
                              <Col span={4}>
                                <Button key={key} onClick={() => remove(index)}>
                                  <MinusCircleOutlined />
                                </Button>
                              </Col>
                            </Row>
                            <Divider style={{ margin: 0 }} />
                          </div>
                        );
                      })}
                      <Form.Item style={{ marginTop: 15 }}>
                        <Button type="dashed" block onClick={() => add()}>
                          + Add Line
                        </Button>
                      </Form.Item>
                    </PageContainer>
                  )}
                </Form.List>
              </Card>
              <Space style={{ marginTop: 15 }}>
                <Button htmlType="submit" onClick={handleSaveDraft}>
                  Save only (quotation will be inactive)
                </Button>
                <Button htmlType="submit" type="primary" onClick={handleSaveApprove}>
                  Save and activate
                </Button>
              </Space>
            </PageContainer>
          </Col>
          {/* cutomer section */}
          <Col span={6}>
            <CustomerDetailCard
              customer={selectedCustomer}
              extra={<Button onClick={() => setCustomerDrawerOpen(true)}>Change</Button>}
              footer={
                <Button type="link">
                  <Space>
                    <a target="_blank" rel="noreferrer" href={`/customers/${customer.id}/history`}>
                      View orders history
                    </a>
                    <ExportOutlined />
                  </Space>
                </Button>
              }
            />
            <Card bodyStyle={{ paddingTop: "5px" }}>
              <Divider orientation="left">Delivery</Divider>
              {/* <Space direction="horizontal">
                <Form.Item
                  label="Date Range"
                  name="dateRange"
                  rules={[{ required: true, message: "Date is required!" }]}
                >
                  <RangePicker style={{ width: "100%" }} />
                </Form.Item>
              </Space> */}

              <Form.Item label="Notes" name="notes">
                <Input.TextArea />
              </Form.Item>
              {/* <Divider orientation="left">Packer</Divider>
              <Form.Item label="Packer Notes" name="packerNotes">
                <Input.TextArea />
              </Form.Item> */}
            </Card>
          </Col>
        </Row>
      </Form>
    </>
  );
};
