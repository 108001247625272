import { useState, useEffect } from "react";
import { useLazyQuery } from "@apollo/client";
import { PageHeader, Table, message, Space } from "antd";
import { Link, useSearchParams, createSearchParams } from "react-router-dom";
import moment from "moment";

import { PageContainer } from "../components/atoms/PageContainer";
import { LineItemsPageQuery } from "../graphql/lineItems.graphql";
import { PageContentContainer } from "../components/atoms/PageContentContainer";
import { ResourceToolbar } from "../components/ResourceToolbar";
import { getSearchParams } from "../utils/getSearchParams";
import { StatusTag } from "../components/tags/StatusTag";

const columns = [
  {
    title: "Item Name",
    dataIndex: "name",
    key: "name",
  },
  {
    title: "Comments",
    dataIndex: "notes",
    key: "notes",
  },
  {
    title: "Unit",
    dataIndex: ["productVariant", "name"],
    key: "packagingUnit",
  },
  {
    title: "Quantity",
    dataIndex: "quantity",
    key: "quantity",
  },
  {
    title: "Order",
    dataIndex: ["order", "name"],
    key: "order",
    render: (order, record) => {
      return (
        <Space>
          {"#" + order}
          {<StatusTag status={record.order.status} />}
          {<StatusTag status={record.order.fulfillmentStatus} />}
        </Space>
      );
    },
  },
  {
    title: "Picking Status",
    dataIndex: "pickingStatus",
    key: "pickingStatus",
    render: (text) => <StatusTag status={text} />,
  },
  {
    title: "Customer",
    dataIndex: ["order", "customer", "name"],
    key: "customer",
  },
  {
    title: "Action",
    dataIndex: "action",
    key: "action",
    render: (_, record) => <Link to={`/orders/${record.order.id}`}>Go to order</Link>,
  },
];
const pageSize = 50;

export const LineItems = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const [isInital, setIsInital] = useState(true);
  const [currentPage, setCurrentPage] = useState(1);
  const [lineItems, setLineItems] = useState([]);
  const [filters, setFilters] = useState({
    name: { contains: "", mode: "insensitive" },
    order: {
      orderDate: { gte: moment().startOf("day").toISOString() },
      status: { not: { equals: "cancelled" } },
    },
  });
  const [searchLineItems, { data, loading, error }] = useLazyQuery(LineItemsPageQuery, {
    variables: { filters: filters, take: pageSize, skip: (currentPage - 1) * pageSize },
    fetchPolicy: "cache-and-network",
  });

  useEffect(
    () => {
      const name = searchParams.get("name");
      const gte = searchParams.get("gte");
      const lte = searchParams.get("lte");
      const page = Number(searchParams.get("currentPage"));
      if (page) setCurrentPage(page);
      if (!name) return;
      let initalDate = {};
      if (!gte && !lte) {
        setFilters({ name: { contains: name, mode: "insensitive" } });
        return;
      } else if (!gte) {
        initalDate = { lte: lte };
      } else if (!lte) {
        initalDate = { gte: gte };
      } else {
        initalDate = { gte: gte, lte: lte };
      }
      setFilters({
        name: { contains: name, mode: "insensitive" },
        order: {
          orderDate: initalDate,
        },
      });
    },
    // eslint-disable-next-line
    [
      //searchParams
    ]
  );

  useEffect(() => {
    if (data?.lineItems) {
      setLineItems(data.lineItems);
    }
  }, [data]);

  useEffect(() => {
    if (filters.name.contains) {
      searchLineItems(filters);
      if (!isInital) setCurrentPage(1);
    }
    // eslint-disable-next-line
  }, [filters]);

  useEffect(() => {
    setSearchParams(
      createSearchParams(
        getSearchParams(
          filters.name.contains,
          filters.order?.orderDate?.gte,
          filters.order?.orderDate?.lte,
          currentPage
        )
      )
    );
  }, [currentPage, filters, setSearchParams]);

  useEffect(() => {
    if (error) message.error("Failed to fetch line items.");
  }, [error]);

  const handleSearch = (value) => {
    if (value) {
      setFilters({
        ...filters,
        name: { contains: value, mode: "insensitive" },
      });
      setIsInital(false);
    }
  };

  const handleRangeChange = (range) => {
    setIsInital(false);
    if (!range) {
      const fil = { ...filters };
      delete fil.order;
      setFilters(fil);
      return;
    }

    const [start, end] = range;

    if (!start) {
      setFilters((f) => ({
        ...f,
        order: {
          orderDate: {
            lte: end.endOf("day").toISOString(),
          },
        },
      }));
      return;
    }
    if (!end) {
      setFilters((f) => ({
        ...f,
        order: {
          orderDate: {
            gte: start.startOf("day").toISOString(),
          },
        },
      }));
      return;
    }

    setFilters((f) => ({
      ...f,
      order: {
        orderDate: {
          gte: start.startOf("day").toISOString(),
          lte: end.endOf("day").toISOString(),
        },
      },
    }));
  };

  return (
    <PageContainer>
      <PageHeader
        ghost={true}
        title="Search Items"
        subTitle={`Showing ${lineItems.length} lineItems`}
      />
      <PageContentContainer>
        <ResourceToolbar
          search={{ onSearch: handleSearch, value: searchParams.get("name") }}
          dateRange={{
            onRangeChange: handleRangeChange,
            range: [
              searchParams.get("gte") ? moment(searchParams.get("gte")) : null,
              searchParams.get("lte") ? moment(searchParams.get("lte")) : null,
            ],
          }}
          isAutoComplete={true}
        />

        <Table
          dataSource={lineItems}
          columns={columns}
          rowKey="id"
          size="small"
          loading={loading}
          pagination={{
            current: currentPage,
            total: data?.lineItemsCount,
            pageSize: pageSize,
            showSizeChanger: false,
            onChange: (currentPage) => {
              setCurrentPage(currentPage);
            },
          }}
        />
      </PageContentContainer>
    </PageContainer>
  );
};
