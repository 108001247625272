import { Menu } from "antd";
import { useMutation } from "@apollo/client";

import {
  QuotationsQuery,
  SoftDeleteQuotationMutation,
  UpdateQuotationStatusMutation,
} from "../../../graphql/quotations.graphql";

export const QuotationsActionsMenu = ({ quotation }) => {
  const [softDeleteQuotation] = useMutation(SoftDeleteQuotationMutation, {
    refetchQueries: [
      {
        query: QuotationsQuery,
      },
    ],
  });

  const [updateQuotationStatus] = useMutation(UpdateQuotationStatusMutation, {
    refetchQueries: [
      {
        query: QuotationsQuery,
      },
    ],
  });
  const handleMenuClick = ({ key }) => {
    if (key === "delete") {
      const res = softDeleteQuotation({ variables: { id: quotation.id } });
      console.log("quotation cancelled: ", res);
    }

    if (key === "inactive") {
      console.log("quotation status: ", quotation.status);
      updateQuotationStatus({
        variables: {
          id: quotation.id,
          status: quotation.status === "confirmed" ? "draft" : "confirmed",
        },
      });
    }
  };

  return (
    <>
      <Menu onClick={handleMenuClick}>
        {/* <Menu.Divider /> */}
        <Menu.Item key="inactive" primary>
          {quotation.status === "confirmed" ? "Deactivate" : "Activate"}
        </Menu.Item>
        <Menu.Item key="delete" danger>
          Delete
        </Menu.Item>
      </Menu>
    </>
  );
};
