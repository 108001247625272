import _ from "lodash";

export const getProductVariantsGroupedByCategories = (products = []) => {
  return _.chain(products)
    .groupBy("category.name")
    .mapValues((value) =>
      _.flatMap(value, (product) =>
        product.productVariants.map((variant) => ({ ...variant, productName: product.name }))
      )
    )
    .value();
};
