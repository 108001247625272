import { gql } from "@apollo/client";

export const CreateExpensesMutation = gql`
  mutation CreateExpensesMutation($expenseCreated: [ExpenseCreateInput!]!) {
    createExpenses(data: $expenseCreated) {
      id
      createdAt
      updatedAt
      spendAt
      name
      amount
      notes
      type
    }
  }
`;

export const ExpensesQuery = gql`
  query ExpensesQuery {
    expenses(orderBy: { spendAt: desc }) {
      id
      name
      amount
      createdAt
      updatedAt
      spendAt
      notes
      type
    }
  }
`;
