import { useMutation } from "@apollo/client";
import { PageHeader, Button, Form, notification } from "antd";
import { useNavigate } from "react-router-dom";
import { PageContainer } from "../components/atoms/PageContainer";
import { ProductForm } from "../components/ProductForm";
import { CreateProductMutation } from "../graphql/products.graphql";
import { prepareProductFormValues } from "../utils";
import UnsavedReminder from "../components/atoms/UnsavedReminder";
import { useCallbackPrompt } from "../hooks/useCallbackPrompt";
import { useEffect, useState } from "react";

export const NewProduct = () => {
  const [createProduct] = useMutation(CreateProductMutation);
  const [form] = Form.useForm();
  const navigate = useNavigate();
  const [showModal, setShowModal] = useState(false);
  const [showPrompt, confirmNavigation, cancelNavigation] = useCallbackPrompt(true);
  const [isSaved, setIsSaved] = useState(false);
  useEffect(() => {
    if (isSaved) {
      setShowModal(false);
    } else setShowModal(showPrompt);
  }, [showPrompt, isSaved]);

  const handleSave = async () => {
    setIsSaved(true);
    try {
      await form.validateFields();
      const values = form.getFieldsValue();
      const { payload } = prepareProductFormValues(values);

      const res = await createProduct({ variables: { data: payload } });
      if (res.data.createProduct.id) {
        notification.success({
          message: `${res.data.createProduct.name} successfully created.`,
        });
        navigate("/products");
        confirmNavigation();
      }
    } catch (error) {
      console.log("error: ", error);
    }
  };

  return (
    <PageContainer>
      <UnsavedReminder
        showModal={showModal}
        confirmNavigation={confirmNavigation}
        cancelNavigation={cancelNavigation}
      />
      <PageHeader
        ghost={true}
        title="New product"
        onBack={() => navigate(-1)}
        extra={[
          <Button type="primary" key="save" onClick={handleSave}>
            Save
          </Button>,
        ]}
      />

      <ProductForm form={form} />
    </PageContainer>
  );
};
