import { useState, useEffect } from "react";
import { useQuery } from "@apollo/client";
import { PageHeader, Button, Table, Typography } from "antd";
import { Link } from "react-router-dom";
import { PageContainer } from "../components/atoms/PageContainer";
import { PageContentContainer } from "../components/atoms/PageContentContainer";
import { ResourceToolbar } from "../components/ResourceToolbar";
import { ROUTES } from "../constants";

import { FranchisesQuery } from "../graphql/customers.graphql";

const columns = [
  {
    title: "Franchise",
    dataIndex: "name",
    key: "name",
    render: (customerName, record) => {
      return (
        <Typography.Title level={5} style={{ marginBottom: 0 }}>
          <Link to={`/customers/${record.id}`}>{customerName}</Link>{" "}
        </Typography.Title>
      );
    },
  },
  {
    title: "No. of branches",
    dataIndex: "branchesCount",
    key: "branchesCount",
    render: (count) => `${count} branch`,
  },
];

const pageSize = 50;
export const Franchises = () => {
  const [queryString, setQueryString] = useState("");
  const [franchiseCustomers, setFranchiseCustomers] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [customersCount, setCustomersCount] = useState(0);

  const { data, loading, refetch, networkStatus } = useQuery(FranchisesQuery, {
    variables: { take: pageSize, skip: (currentPage - 1) * pageSize, queryString },
    fetchPolicy: "cache-and-network",
    notifyOnNetworkStatusChange: true,
  });

  useEffect(() => {
    if (data?.customers) {
      setFranchiseCustomers(data?.customers);
      setCustomersCount(data?.customersCount);
    }
  }, [data, loading]);

  const handleSearch = (value) => {
    setQueryString(value);
    setCurrentPage(1);
  };

  const handleRefresh = () => {
    console.log("Refreshing...");
    refetch();
  };

  return (
    <PageContainer>
      <PageHeader
        ghost={true}
        title="Franchises"
        subTitle={`Showing ${franchiseCustomers.length} franchises of ${customersCount} `}
        extra={[
          <Link key="new-franchises" to={ROUTES.franchisesNew}>
            <Button type="primary" disabled>
              New Franchise
            </Button>
          </Link>,
        ]}
      />
      <PageContentContainer>
        <ResourceToolbar onSearch={handleSearch} onRefresh={handleRefresh} />
        <Table
          dataSource={franchiseCustomers}
          columns={columns}
          rowKey="id"
          size="medium"
          loading={loading || networkStatus}
          pagination={{
            total: customersCount,
            pageSize: pageSize,
            showSizeChanger: false,
            onChange: (currentPage) => {
              setCurrentPage(currentPage);
            },
          }}
        />
      </PageContentContainer>
    </PageContainer>
  );
};
