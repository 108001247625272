import { useQuery } from "@apollo/client";
import {
  Form,
  Col,
  Row,
  Card,
  Input,
  AutoComplete,
  Divider,
  InputNumber,
  DatePicker,
} from "antd";
import { useEffect, useState } from "react";
import { UnpaidOrdersQuery } from "../../graphql/orders.graphql";
import { useAllCustomersOptions } from "../../hooks/useAllCustomersOptions";

export const PaymentForm = ({ form }) => {
  const [ordersOptions, setOrdersOptions] = useState([]);
  const { data: unpaidOrders } = useQuery(UnpaidOrdersQuery);
  const { options } = useAllCustomersOptions();

  useEffect(() => {
    if (unpaidOrders?.orders) {
      console.log("unpaidOrders: ", unpaidOrders);
      const opts = unpaidOrders.orders.map(order => ({
        label: `${order.name} · ${order.customer.name}`,
        value: `${order.name} · ${order.customer.name}`,
        id: order.id,
      }));
      setOrdersOptions([{ label: "SET TO NONE", value: null }, ...opts]);
    }
  }, [unpaidOrders]);

  const handleCustomerSelect = (_, option) => {
    form.setFields([{ name: "customerId", value: option.id }]);
  };

  const handleOrderSelect = (_, option) => {
    form.setFields([{ name: "orderId", value: option.id }]);
  };
  return (
    <Form
      form={form}
      layout="vertical"
      initialValues={{ productVariants: [null] }}
    >
      <Row gutter={[32, 16]}>
        <Col span={16}>
          <Card title="Baisc information">
            <Form.Item name="createdAt" label="Received at">
              <DatePicker />
            </Form.Item>
            <Form.Item name="notes" label="Notes">
              <Input />
            </Form.Item>
            <Form.Item name="amount" label="Amount">
              <InputNumber />
            </Form.Item>
          </Card>
        </Col>
        <Col span={8}>
          <Card title="Order and Customer">
            <Form.Item name="customer" label="Customer">
              <AutoComplete
                valuepropname="id"
                onSelect={handleCustomerSelect}
                options={options}
              />
            </Form.Item>
            <Form.Item hidden name="customerId">
              <Input />
            </Form.Item>
            <Form.Item hidden name="orderId">
              <Input />
            </Form.Item>
            <Form.Item name="order" label="Order">
              <AutoComplete
                valuepropname="id"
                onSelect={handleOrderSelect}
                options={ordersOptions}
              />
            </Form.Item>
          </Card>
          <Divider />
        </Col>
      </Row>
    </Form>
  );
};
