import { useContext, useState } from "react";
import { Button, Col, Row, Space, Table } from "antd";
import { ProductsContext } from "../contexts/ProductsContext";
import styled from "styled-components";

function findClosestMatch(input, candidates) {
  // Calculate Levenshtein distance for each candidate
  const distances = candidates.map((candidate) => {
    const matrix = Array.from({ length: input.length + 1 }, (_, i) =>
      Array.from({ length: candidate.length + 1 }, (_, j) => (i === 0 ? j : j === 0 ? i : 0))
    );

    for (let i = 1; i <= input.length; i++) {
      for (let j = 1; j <= candidate.length; j++) {
        if (input[i - 1] !== candidate[j - 1]) {
          matrix[i][j] = Math.min(
            matrix[i - 1][j] + 1,
            matrix[i][j - 1] + 1,
            matrix[i - 1][j - 1] + 1
          );
        } else {
          matrix[i][j] = matrix[i - 1][j - 1];
        }
      }
    }

    return matrix[input.length][candidate.length];
  });

  // Find the index of the minimum distance
  const minDistanceIndex = distances.indexOf(Math.min(...distances));

  return candidates[minDistanceIndex];
}

const PageWrapper = styled.div`
  width: 100%;
  min-height: 100vh;
  padding: 20px;
  overflow-x: hidden;
`;

export const Playground = () => {
  const [userInput, setUserInput] = useState("");
  const [matches, setMatches] = useState([]);
  const { variants } = useContext(ProductsContext);

  // Function to handle user input
  const handleUserInput = (event) => {
    setUserInput(event.target.value);
  };

  // Function to handle form submission
  const handleSubmit = () => {
    // Do something with the user input, for example, log it
    console.log("User input:", userInput);

    const possibleMatches = userInput.split("/n").map((input) => {
      const closes = findClosestMatch(input, variants);

      return console.log("closes: ", closes);
    });
  };

  return (
    <PageWrapper>
      <Row>
        <Col span={16}>
          <Space direction="vertical">
            <label htmlFor="userInput">Enter your text:</label>
            <textarea
              id="userInput"
              rows="4"
              cols="50"
              value={userInput}
              onChange={handleUserInput}
            ></textarea>

            <div>
              <Button onClick={handleSubmit}>Process</Button>
            </div>
          </Space>
        </Col>
        <Col span={8}>
          <Table
            columns={[
              { title: "Nane", dataIndex: "name" },
              { title: "Qty", dataIndex: "quantity" },
            ]}
            dataSource={matches}
            size="small"
            pagination={null}
          />
        </Col>
      </Row>
    </PageWrapper>
  );
};
