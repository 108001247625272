import { Result, Button, Typography } from "antd";
// If you're using react-router-dom
import { useNavigate } from "react-router-dom";

export const MaintenancePage = () => {
  const navigate = useNavigate();

  return (
    <>
      <Typography.Title style={{ textAlign: "center", marginTop: "20px" }} level={2}>
        Fresh Cloud
      </Typography.Title>
      <Result
        status="info"
        title="System Maintenance"
        subTitle="We are currently performing scheduled maintenance. Please check back later."
        extra={
          <Button type="primary" onClick={() => navigate("/")}>
            Refresh Page
          </Button>
        }
      />
    </>
  );
};
