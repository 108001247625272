import { Form, Space, InputNumber } from "antd";

const SummaryNumberField = ({ label, name, hidden = false }) => {
  return (
    <Form.Item style={{ marginBottom: 0 }} shouldUpdate label={label} name={name} hidden={hidden}>
      <InputNumber
        style={{
          outline: "none",
          border: "none",
          color: "#000",
          background: "#eaeaea",
          textAlign: "right",
          fontSize: "16px",
          fontWeight: "bold",
        }}
        precision={2}
        disabled
      />
    </Form.Item>
  );
};

export const OrderTotal = () => {
  return (
    <Space style={{ padding: "8px", width: "100%", justifyContent: "flex-end" }}>
      <SummaryNumberField label="Subtotal" name={["total", "totalWithoutGst"]} />
      <SummaryNumberField label="Discount" name={["total", "discountAmount"]} />
      <SummaryNumberField hidden label="Total cost" name={["total", "totalBasePrice"]} />
      <SummaryNumberField label="Total" name={["total", "total"]} />
    </Space>
  );
};
