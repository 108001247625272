import { Modal } from "antd";

const UnsavedReminder = ({ showModal, confirmNavigation, cancelNavigation }) => {
  return (
    <>
      <Modal
        title="Warning"
        onOk={confirmNavigation}
        onCancel={cancelNavigation}
        okText="Confirm"
        cancelText="Cancel"
        visible={showModal}
      >
        <p>Changes that you made may not be saved</p>
      </Modal>
    </>
  );
};

export default UnsavedReminder;
