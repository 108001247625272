import { gql } from "@apollo/client";

export const CurrentUserQuery = gql`
  query CurrentUserQuery {
    authenticatedItem {
      ... on User {
        id
        email
        name
        packerId
        role
        organisations {
          id
          name
        }
      }
    }
  }
`;

export const LoginMutation = gql`
  mutation loginMutation($username: String!, $password: String!) {
    authenticateUserWithPassword(username: $username, password: $password) {
      ... on UserAuthenticationWithPasswordSuccess {
        item {
          id
          email
          name
          packerId
          role
          username
          organisations {
            id
            name
          }
        }
      }
      ... on UserAuthenticationWithPasswordFailure {
        message
      }
    }
  }
`;

export const LogoutMutation = gql`
  mutation logoutMutation {
    endSession
  }
`;

export const PackersQuery = gql`
  query PackersQuery {
    users(where: { role: { equals: packer } }) {
      id
      name
      role
      packerId
    }
  }
`;

export const SalespeopleQuery = gql`
  query SalespeopleQuery {
    users(where: { role: { equals: sales } }) {
      id
      name
      role
      packerId
    }
  }
`;
