import { useEffect, useState } from "react";
import { useQuery } from "@apollo/client";
import { PageHeader } from "antd";
import { useParams, Link } from "react-router-dom";

import { PageContainer } from "@/components/atoms/PageContainer";
import { LineItemsTableForQuotation } from "@/components/LineItemsTableForQuotation";
import { QuotationInfoCard } from "@/components/QuotationInfoCard";
import { StatusTag } from "@/components/tags/StatusTag";
import { QuotationPDF } from "@/components/pdf/QuotationPDF";

import { Space, Typography, Button, Row, Col, Card, Divider, Dropdown, Menu } from "antd";
import { EditFilled, SyncOutlined, PrinterOutlined, FilePdfOutlined } from "@ant-design/icons";
import { usePDF } from "@react-pdf/renderer";

import { SingleQuotationQuery } from "../graphql/quotations.graphql";

const itemPaddingLeft = { paddingLeft: "18px" };

export const QuotationDetail = () => {
  const params = useParams();
  const [quotation, setQuotation] = useState({});
  const [customers, setCustomers] = useState([]);
  const [items, setItems] = useState([]);
  const { data, loading, refetch } = useQuery(SingleQuotationQuery, {
    variables: { id: params.id },
    fetchPolicy: "cache-and-network",
  });

  const [quotationMulti, updateQuotationMulti] = usePDF({
    document: <QuotationPDF quotation={quotation} language="multi" />,
  });
  const [quotationEnglish, updateQuotationSingle] = usePDF({
    document: <QuotationPDF quotation={quotation} language="single" />,
  });

  useEffect(() => {
    if (data?.quotation) {
      setQuotation(data.quotation);
      setCustomers(data.quotation.customers);
      setItems(data.quotation.lineItems);
    }
  }, [data]);

  useEffect(() => {
    if (quotation.id) {
      updateQuotationSingle();
    }
    if (quotation.id) {
      updateQuotationMulti();
    }
    // eslint-disable-next-line
  }, [quotation]);

  const printPDF = (
    <Menu
      items={[
        {
          key: "1",
          icon: <PrinterOutlined />,
          style: itemPaddingLeft,
          label: (
            <a target="_blank" rel="noreferrer" href={quotationMulti.url}>
              ZH & EN
            </a>
          ),
        },
        {
          key: "2",
          icon: <PrinterOutlined />,
          style: itemPaddingLeft,
          label: (
            <a target="_blank" rel="noreferrer" href={quotationEnglish.url}>
              English
            </a>
          ),
        },
      ]}
    />
  );

  const downloadPDF = (
    <Menu
      items={[
        {
          key: "3",
          icon: <FilePdfOutlined />,
          style: itemPaddingLeft,
          label: (
            <a
              target="_blank"
              rel="noreferrer"
              href={quotationMulti.url}
              loading={quotationMulti.loading}
              download={`QUO#${quotation.name}.pdf`}
            >
              ZH & EN
            </a>
          ),
        },
        {
          key: "4",
          icon: <FilePdfOutlined />,
          style: itemPaddingLeft,
          label: (
            <a
              target="_blank"
              rel="noreferrer"
              href={quotationEnglish.url}
              loading={quotationMulti.loading}
              download={`QUO#${quotation.name}.pdf`}
            >
              English
            </a>
          ),
        },
      ]}
    />
  );

  return (
    <>
      <PageHeader
        title={
          <Space>
            <Space>
              <Typography.Text># {quotation.name}</Typography.Text>
            </Space>
            <Space>
              <StatusTag status={quotation.status} />
            </Space>
          </Space>
        }
        extra={[
          <Link key="edit-quotation" to={`/quotations/${quotation.id}/edit`}>
            <Button type="primary" icon={<EditFilled />}>
              Edit
            </Button>
          </Link>,
          <Dropdown key="print-quotation" overlay={printPDF} placement="bottom">
            <Button
              loading={quotationMulti.loading || quotationEnglish.loading}
              icon={<PrinterOutlined />}
            >
              Print
            </Button>
          </Dropdown>,
          <Dropdown key="download-quotation" overlay={downloadPDF} placement="bottom">
            <Button
              loading={quotationMulti.loading || quotationEnglish.loading}
              icon={<FilePdfOutlined />}
            >
              Download
            </Button>
          </Dropdown>,
          <Button
            key="refresh"
            onClick={() => refetch()}
            loading={loading}
            icon={<SyncOutlined />}
          />,
        ]}
      />
      <PageContainer>
        <Row gutter={16}>
          <Col span={16}>
            <Card style={{ width: "100%" }}>
              <LineItemsTableForQuotation items={items} loading={loading} />
              <Divider />
            </Card>
          </Col>
          <Col span={8}>
            <QuotationInfoCard customers={customers} quotation={quotation} />
          </Col>
        </Row>
      </PageContainer>
    </>
  );
};
