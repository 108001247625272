import { Form, Select, Tag } from "antd";
const pickingStatusOptions = [
  {
    label: <Tag color="warning">Missing</Tag>,
    value: "missing",
  },
  {
    label: <Tag color="success">Done</Tag>,
    value: "done",
  },
  {
    label: <Tag color="red">N/A</Tag>,
    value: "na",
  },
  {
    label: <Tag color="blue">Replace</Tag>,
    value: "replace",
  },
  {
    label: <Tag color="">Pack</Tag>,
    value: "pack",
  },
];

export const PickingStatusSelectV2 = ({ index, shouldUpdate = true }) => {
  return (
    <Form.Item name={[index, "pickingStatus"]} shouldUpdate={shouldUpdate} noStyle>
      <Select bordered={false} options={pickingStatusOptions} />
    </Form.Item>
  );
};
