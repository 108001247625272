import { Table, Button, Space } from "antd";
import { useContext } from "react";
import { FormListContext } from "../FormListContext";
import { AuthContext } from "../../../contexts/AuthContext";
import { fullColumns, draftColumns } from "./columns";
import { OrderFormContext } from "../../../contexts/OrderFormContext";

export const LineItemsEditingTable = ({ isEditing }) => {
  const { user } = useContext(AuthContext);
  const { form } = useContext(OrderFormContext);
  const { fields, add, itemsCount } = useContext(FormListContext);

  const columns = user.role === "admin" && isEditing ? fullColumns : draftColumns;

  const handleMarkAllItems = () => {
    const lineItems = form.getFieldValue("lineItems");
    const newLineItems = Array.from(lineItems).map((item) =>
      item?.pickingStatus !== "replace" && item?.pickingStatus !== "na" && item?.name
        ? {
            ...item,
            pickingStatus: "done",
          }
        : item
    );
    form.setFields([{ name: "lineItems", value: newLineItems }]);
  };

  return (
    <>
      <Table
        columns={columns}
        rowKey={(record) => record.key}
        dataSource={fields}
        size="small"
        pagination={false}
        bordered={true}
        title={() => {
          return (
            <Space style={{ justifyContent: "space-between", width: "100%" }}>
              <span>{itemsCount} items</span>
              <Button size="small" type="link" onClick={handleMarkAllItems}>
                Mark all items done
              </Button>
            </Space>
          );
        }}
        footer={() => (
          <Button block type="dashed" onClick={add}>
            Add
          </Button>
        )}
      />
    </>
  );
};
