import { Link } from "react-router-dom";
import styled from "styled-components";
import { Tag, Space, Button } from "antd";
import { UserAvatar } from "./UserAvatar";
import { LogoWithText } from "./LogoWithText";
import { ROUTES } from "../../../constants";

const nodeEnv = process.env.NODE_ENV;

const HeaderInnerWrapper = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 3.5rem;
  position: fixed;
  top: 0;
  left: 0;
  color: #fff;
  background: #000;
  border-bottom: 0.35px solid lightgrey;
  padding: 0px 24px;
  /* box-shadow: 0 0.125rem 0.125rem -0.0625rem #00000026; */
  z-index: 20;
`;

export const TopHeader = () => {
  return (
    <HeaderInnerWrapper>
      <Space>
        <LogoWithText />
      </Space>
      <Space>
        {nodeEnv === "development" && (
          <Space>
            <Tag color="error">{nodeEnv.toUpperCase()}</Tag>
            <Button type="danger">
              <Link to={ROUTES.ordersNew}>+ Order</Link>
            </Button>
          </Space>
        )}
        <UserAvatar />
      </Space>
    </HeaderInnerWrapper>
  );
};
