import { useContext, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useQuery } from "@apollo/client";
import moment from "moment";
import { Button, notification, Spin, Form, Modal, Space } from "antd";
import _ from "lodash";

import { getUnitPrice, getOrderTotal } from "@/utils";
import { SingleOrderQuery } from "@/graphql/orders.graphql";
import { useCallbackPrompt } from "@/hooks/useCallbackPrompt";

import { CustomerContext } from "@/contexts/CustomerContext";
import { OrderFormProvider } from "@/contexts/OrderFormContext";
import { sanitizeOrderFormValues } from "@/utils";

import { OrderFormV2 } from "@/components/OrderFormV2";
import { AffixedPageHeader } from "@/components/atoms/AffixedPageHeader";
import { StatusTag } from "@/components/tags/StatusTag";
import styled from "styled-components";
// import { BachedHistoryPriceQuery } from "@/graphql/lineItems.graphql";
import { useAuth } from "@/hooks/useAuth";
import axios from "axios";
import { STATUS_OPERATION } from "@/constants";

const StatusWrapper = styled.div`
  display: flex;
  align-items: center;
  column-gap: 2px;

  * {
    margin: 0;
  }
`;

function splitIntoFolds(array, foldSize) {
  const folds = [];
  for (let i = 0; i < array.length; i += foldSize) {
    const fold = array.slice(i, i + foldSize);
    folds.push(fold);
  }
  return folds;
}

export const EditOrderV2 = () => {
  const [initalIds, setInitalIds] = useState([]);
  const params = useParams();
  const navigate = useNavigate();
  const [form] = Form.useForm();
  const [order, setOrder] = useState(null);
  const [activeQuotation, setActiveQuotation] = useState(null);
  const [historyData, setHistoryData] = useState([]);
  const { user } = useAuth();
  const [loading, setLoading] = useState(false);
  const [historyDataLoading, setHistoryDataLoading] = useState(true);
  const [totalBeforeUpdate, setTotalBeforeUpdate] = useState(0);

  // unsavedReminder
  const [showPrompt, confirmNavigation, cancelNavigation] = useCallbackPrompt(true);
  const [isSaved, setIsSaved] = useState(false);

  const { data } = useQuery(SingleOrderQuery, {
    variables: { id: params.id },
    fetchPolicy: "cache-first",
  });

  const { customer, fetchCustomer, resetCustomer } = useContext(CustomerContext);

  useEffect(() => {
    if (data && data.order) {
      setOrder(data.order);
      setTotalBeforeUpdate(data.order.total);
      setInitalIds(
        data.order.lineItems.map((item) => {
          return { id: item.id };
        })
      );

      // fetchCustomer by customer id and save in customerContext, the customer would be used in orderForm
      const filters = {
        status: { equals: "confirmed" },
      };
      fetchCustomer({ variables: { id: data.order.customer.id, filters: filters } });
    }

    return () => {
      console.log("cleaning up in Edit.js......");
      resetCustomer();
    };
  }, [data]);

  useEffect(() => {
    if (order && customer) {
      const productIds = order.lineItems.map((item) => item.product?.id);
      processProductIds(productIds, customer);

      const {
        totalWithoutGst,
        gstAmount,
        total,
        grossProfit,
        totalBasePrice,
        lineItems: unmappedLineItems,
        orderDate,
        ...basicInfo
      } = order;

      let lineItems = [];
      let orderTotal = {
        totalWithoutGst: order.totalWithoutGst,
        gstAmount: order.gstAmount,
        total: order.total,
        grossProfit: order.grossProfit,
      };

      if (order.status === "draft") {
        const quotations = customer?.quotations;
        const firstQuotation = quotations[0];
        // console.log("firstQuotation: ", firstQuotation);
        setActiveQuotation(firstQuotation);
        lineItems = unmappedLineItems.map((item) => {
          let quotedPrice = null;
          if (firstQuotation) {
            const itemInQuotation = firstQuotation.lineItems.find(
              (quotationItem) => quotationItem.productVariant.id === item.productVariant.id
            );
            if (parseFloat(itemInQuotation?.unitPrice)) {
              quotedPrice = itemInQuotation.unitPrice;
            }
          }

          const selling =
            quotedPrice ||
            getUnitPrice({
              productVariant: item.productVariant,
              pickingStatus: item.pickingStatus,
            });
          const base = item.unitCost || item.productVariant.basePrice || "0";

          const margin = selling ? ((+selling - +base) / +selling).toFixed(4) : null;
          return {
            ...item,
            productVariantId: item.productVariant.id,
            productVariantName: item.productVariant.name,
            unitPrice: selling,
            unitCost: base,
            isQuotationPrice: !!quotedPrice,
            margin: margin,
          };
        });
      } else {
        lineItems = unmappedLineItems.map((item) => ({
          ...item,
          productVariantId: item.productVariant.id,
          productVariantName: item.productVariant.name,
          unitCost: item.unitCost || item.productVariant.basePrice || "0",
        }));
      }

      orderTotal = getOrderTotal(lineItems);

      const formValue = {
        basicInfo: { ...basicInfo, orderDate: moment(orderDate) },
        lineItems,

        total: orderTotal,
      };
      form.setFieldsValue(formValue);
    }
  }, [order, customer]);

  useEffect(() => {
    window.addEventListener("beforeunload", (event) => {
      event.preventDefault();
      event.returnValue = "";
    });

    return () => {
      window.removeEventListener("beforeunload", (event) => {
        event.preventDefault();
        event.returnValue = "";
      });
    };
  });

  useEffect(() => {
    if (!isSaved && showPrompt) {
      Modal.confirm({
        okText: "Confirm",
        cancelText: "Cancel",
        onOk: confirmNavigation,
        onCancel: cancelNavigation,
        title: "Warning",
        content: "Changes you have made may lost",
        closable: true,
        maskClosable: false,
      });

      // return Modal.destroyAll();
    }
  }, [showPrompt, confirmNavigation, cancelNavigation, isSaved]);

  const processProductIds = async (productIds, customer) => {
    if (user?.role === "staff") return;
    if (productIds && customer) {
      const res = await axios.post(
        `${process.env.REACT_APP_REST_API_ENDPOINT}/orders/${order.id}/history-price`,
        {
          productIds: productIds,
          customerId: customer.id,
          franchiseId: customer.franchise?.id,
        }
      );
      setHistoryData(res.data);
      setHistoryDataLoading(false);
    }
  };

  const handleSave = async (isConfirm = true) => {
    setIsSaved(true);
    setLoading(true);
    const values = form.getFieldsValue();
    const tempData = sanitizeOrderFormValues({ values, operation: "update" });
    tempData.status = isConfirm ? "confirmed" : "draft";
    if (order.status === "draft" && isConfirm) {
      tempData.statusOperation = STATUS_OPERATION.CONFIRM_DRAFT_ORDER;
    }
    if (order.status === "draft" && !isConfirm) {
      tempData.statusOperation = STATUS_OPERATION.UPDATE_DRAFT_ORDER;
    }
    if (order.status === "confirmed") {
      tempData.statusOperation = STATUS_OPERATION.UPDATE_CONFIRMED_ORDER;
    }
    const result = tempData.lineItems.reduce(
      (prev, current) => {
        const id = current.id;
        delete current.id;
        if (id) {
          prev.updatedLineItems.push({ where: { id: id }, data: current });
          prev.deletedLineItems.splice(
            prev.deletedLineItems.findIndex((item) => item.id === id),
            1
          );
        } else {
          prev.newLineItems.push(current);
        }
        return prev;
      },
      {
        deletedLineItems: _.cloneDeep(initalIds),
        updatedLineItems: [],
        newLineItems: [],
      }
    );

    const payload = {
      ...tempData,
      totalBeforeUpdate,
      lineItems: result,
    };

    try {
      const response = await axios.put(
        `${process.env.REACT_APP_REST_API_ENDPOINT}/orders/${params.id}`,
        payload
      );
      if (response.status >= 200 && response.data) {
        notification["success"]({
          message: `Order #${data.order.name} updated.`,
        });
        navigate(`/orders/${params.id}`);
        confirmNavigation();
      }
    } catch (error) {
      console.log("error: ", error);
      setLoading(false);
    }
  };

  return (
    <>
      <AffixedPageHeader
        title={
          <Space>
            <StatusTag status={data?.order.status} />
            <span>
              Edit order · #{data?.order?.name} · {customer?.name}
            </span>
            <StatusWrapper>
              <StatusTag status={data?.order.fulfillmentStatus} />
              <StatusTag status={data?.order.paymentStatus} />
              <StatusTag status={data?.order.invoiceStatus} />
            </StatusWrapper>
          </Space>
        }
        extra={[
          <Button key="save" onClick={() => handleSave(false)}>
            Save as draft
          </Button>,
          <Button type="primary" key="save-and-confirm" onClick={() => handleSave()}>
            Save and confirm
          </Button>,
        ]}
      />

      {customer && !loading ? (
        <OrderFormProvider
          order={data?.order}
          historyData={historyData}
          historyDataLoading={historyDataLoading}
          isEditing={true}
          form={form}
          activeQuotation={activeQuotation}
        >
          <OrderFormV2 />
        </OrderFormProvider>
      ) : (
        <Spin />
      )}
    </>
  );
};
