import { useEffect } from "react";
import { Card, Button, Table } from "antd";
import { PrinterOutlined } from "@ant-design/icons";
import { usePDF } from "@react-pdf/renderer";

import { SalespersonPDF } from "../pdf/SalespersonPDF";

export const TableCard = ({ title = "Card title", data, columns, loading }) => {
  const [file, updateFile] = usePDF({ document: <SalespersonPDF data={data} columns={columns} /> });

  useEffect(() => {
    updateFile();
  }, [data, updateFile]);

  return (
    <Card
      title={title}
      extra={
        <a key="print-invoice-en" href={file.url} rel="noreferrer" target="_blank">
          <Button type="primary" loading={file.loading} icon={<PrinterOutlined />}>
            Download
          </Button>
        </a>
      }
      headStyle={{ borderBottom: "0px" }}
      bodyStyle={{ paddingTop: "0px" }}
    >
      <Table
        dataSource={data}
        columns={columns}
        rowKey="id"
        size="medium"
        loading={loading}
        pagination={false}
        showHeader={false}
      />
    </Card>
  );
};
