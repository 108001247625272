import PropTypes from "prop-types";
import { AuthGuard } from "../guards/AuthGuard";
import { MaintenancePage } from "../pages/MaintenancePage";
import { DashboardLayout } from "./dashboard";

// Add maintenance mode check
const MAINTENANCE_MODE = process.env.REACT_APP_MAINTENANCE_MODE === "true";
// Optional: Add allowed IPs or admin override
const MAINTENANCE_ALLOWED_IPS = process.env.REACT_APP_MAINTENANCE_ALLOWED_IPS?.split(",") || [];

const MaintenanceGuard = ({ children }) => {
  // Optional: Add logic to check if current user/IP is allowed to bypass maintenance mode
  const shouldBypassMaintenance = () => {
    // Add your bypass logic here
    // Example: check if user is admin or IP is in allowed list
    return false;
  };

  if (MAINTENANCE_MODE && !shouldBypassMaintenance()) {
    return <MaintenancePage />;
  }

  return children;
};

export const DefaultLayout = ({ children }) => {
  return (
    <MaintenanceGuard>
      <AuthGuard>
        <DashboardLayout>{children}</DashboardLayout>
      </AuthGuard>
    </MaintenanceGuard>
  );
};

export const DefaultUnauthenticatedLayout = ({ children }) => {
  return (
    <MaintenanceGuard>
      <DashboardLayout>{children}</DashboardLayout>
    </MaintenanceGuard>
  );
};

DefaultLayout.propTypes = {
  children: PropTypes.node.isRequired,
};

MaintenanceGuard.propTypes = {
  children: PropTypes.node.isRequired,
};
