import React from "react";

import styled from "styled-components";
import { Typography } from "antd";
import { Draggable } from "react-beautiful-dnd";
import type { DraggableCustomerCardProps } from ".";

const CustomerCardWrapper = styled.div`
  border-radius: 2px;
  padding: 8px;
  min-width: 160px;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
  background: #fff;
  border-width: 1px;
`;

export const DraggableCustomerCard = ({ customer, index }: DraggableCustomerCardProps) => {
  return (
    <Draggable draggableId={customer.id} index={index} key={customer.id}>
      {(provided, snapshot) => {
        return (
          <CustomerCardWrapper
            ref={provided.innerRef}
            {...provided.draggableProps}
            {...provided.dragHandleProps}
          >
            <Typography.Title level={5}>{customer.name}</Typography.Title>
            {customer.franchise && <Typography.Text>{customer.franchise.name}</Typography.Text>}
          </CustomerCardWrapper>
        );
      }}
    </Draggable>
  );
};
