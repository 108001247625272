import { useState } from "react";
import { useAuth } from "../../../hooks/useAuth";
import { Avatar, Typography, Space, Menu, Dropdown, Button } from "antd";
import { DownOutlined, UpOutlined } from "@ant-design/icons";
import { useNavigate } from "react-router-dom";
const { Text } = Typography;

export const UserAvatar = () => {
  const { user, logout } = useAuth();
  const navigate = useNavigate();
  const [menuVisible, setMenuVisible] = useState(false);

  const handleLogout = async () => {
    const res = await logout();
    if (res?.endSession) {
      navigate("/");
    }
  };
  const dropdown = (
    <Menu>
      <Menu.Item key="username">
        <Text type="secondary">Logged in as</Text> {user?.name}
      </Menu.Item>
      <Menu.Item key="email">
        <Text type="secondary">Email:</Text> {user?.email}
      </Menu.Item>
      <Menu.Item key="logout">
        <Button onClick={handleLogout}>Logout</Button>
      </Menu.Item>
    </Menu>
  );
  return (
    <Dropdown
      overlay={dropdown}
      trigger={["click"]}
      arrow
      onVisibleChange={isVisible => {
        setMenuVisible(isVisible);
      }}
    >
      <Space>
        <Avatar style={{ color: "#f56a00", backgroundColor: "#fde3cf" }}>
          {user?.name}
        </Avatar>
        {user?.name}
        {menuVisible ? <UpOutlined /> : <DownOutlined />}
      </Space>
    </Dropdown>
  );
};
