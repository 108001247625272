//components,utils and atoms

import { ExpensesQuery } from "../graphql/expenses.graphql";
import { Link } from "react-router-dom";
import moment from "moment";
import { useQuery } from "@apollo/client";
import { Table, Button, PageHeader } from "antd";
import { useEffect, useState } from "react";
import { PageContainer } from "../components/atoms/PageContainer";
import { formatMoney } from "../utils";

const columns = [
  {
    title: "SpendAt",
    dataIndex: "spendAt",
    key: "spendAt",
    render: (_, record) => moment(record.spendAt).format("DD/MM/yyyy"),
  },
  { title: "Type", dataIndex: "type", key: "type" },
  { title: "Name", dataIndex: "name", key: "name" },
  {
    title: "Amount",
    dataIndex: "amount",
    key: "amount",
    render: (_, record) => formatMoney(record.amount),
  },
  {
    title: "Notes",
    dataIndex: "notes",
    key: "notes",
  },
];

export const ExpensesHome = () => {
  const [expensesData, setExpensesData] = useState([]);
  const { data, loading } = useQuery(ExpensesQuery, {
    fetchPolicy: "no-cache",
  });

  useEffect(() => {
    if (data) {
      setExpensesData(data.expenses);
    }
  }, [data, loading]);

  return (
    <PageContainer>
      <PageHeader
        title="Expenses"
        extra={[
          <Button key="cash" type="primary">
            <Link to="/expenses/new?type=cash">New Cash</Link>
          </Button>,
          <Button key="cheque" type="primary">
            <Link to="/expenses/new?type=cheque">New Cheque/BankCard</Link>
          </Button>,
          <Button key="eft" type="primary">
            <Link to="/expenses/new?type=eft">New EFT</Link>
          </Button>,
        ]}
      />

      <Table
        dataSource={expensesData}
        columns={columns}
        rowKey="id"
        size="middle"
        loading={loading}
        pagination={false}
      />
    </PageContainer>
  );
};
