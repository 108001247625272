import axios from "axios";
import { useEffect, useState } from "react";

export const useOrders = ({ take, skip, startDate, endDate, searchQuery }) => {
  const [data, setData] = useState(null);
  const [total, setTotal] = useState(0); // data.total.count
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);

  const fetchData = async ({ take, skip, startDate, endDate, searchQuery }) => {
    setLoading(true);
    try {
      const response = await axios(`${process.env.REACT_APP_REST_API_ENDPOINT}/orders`, {
        params: {
          take,
          skip,
          startDate,
          endDate,
          searchQuery,
        },
      });

      setData(response.data.orders);
      setTotal(response.data.total);
    } catch (error) {
      setError(error);
    }
    setLoading(false);
  };

  useEffect(() => {
    fetchData({ take, skip, startDate, endDate, searchQuery });
  }, [endDate, skip, startDate, take, searchQuery]);

  return {
    data,
    total,
    error,
    loading,
    fetchData,
  };
};
