import { useState, useEffect } from "react";
import {
  Table,
  Space,
  PageHeader,
  Button,
  Tag,
  Typography,
  notification,
  Menu,
  Dropdown,
  DatePicker,
  Statistic,
  Card,
  Row,
  Empty,
  Select,
} from "antd";
import { PageContentContainer } from "../components/atoms/PageContentContainer";
import { useLazyQuery } from "@apollo/client";
import { Link } from "react-router-dom";
import { CustomerCell, OrdersActionsCell } from "../components/tablecells";
import { StatusTag } from "../components/tags/StatusTag";
import moment from "moment";
import { FilePdfOutlined, PrinterFilled, SyncOutlined } from "@ant-design/icons";
import { PageContainer } from "../components/atoms/PageContainer";
import { OrdersQuery } from "../graphql/orders.graphql";
import { getTodayTomorrowTag, getFulfillmentTypeTag, formatMoney } from "../utils";
import styled from "styled-components";
import axios from "axios";
import { set } from "lodash";

const ToolbarWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  padding: 16px 12px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.06);
  margin-bottom: 8px;
`;

const columns = [
  {
    title: "#",
    dataIndex: "name",
    key: "name",
    render: (orderNumber, record) => {
      return (
        <Typography.Title level={5} style={{ marginBottom: 0 }}>
          <Link to={`/orders/${record.id}`}>{orderNumber}</Link>
          {getFulfillmentTypeTag(record.fulfillmentType)}
        </Typography.Title>
      );
    },
  },

  {
    title: "Date",
    dataIndex: "orderDate",
    key: "orderDate",
    render: (orderDate) => {
      const { dateStr, tag } = getTodayTomorrowTag(orderDate);
      return (
        <Space>
          {dateStr} {tag && <Tag color={tag.color}>{tag.text}</Tag>}
        </Space>
      );
    },
  },

  {
    title: "Customer",
    dataIndex: "customer",
    key: "customer",
    render: (_, record) => <CustomerCell order={record} customer={record.customer || {}} />,
  },

  {
    title: "Status",
    dataIndex: "status",
    key: "status",
    render: (text) => <StatusTag status={text} />,
  },

  {
    title: "Fulfillment",
    dataIndex: "fulfillmentStatus",
    key: "fulfillmentStatus",
    render: (text) => <StatusTag status={text} />,
  },

  {
    title: "No. of items",
    dataIndex: "lineItemsCount",
    key: "lineItemsCount",
    render: (count) => `${count} items`,
  },

  {
    title: "Total",
    dataIndex: "total",
    key: "total",
    align: "right",
    render: (total, record) =>
      record.status === "draft" ? <StatusTag status="pending" /> : formatMoney(total),
  },

  {
    title: "Actions",
    dataIndex: "actions",
    key: "actions",
    align: "right",
    render: (_, record) => {
      return <OrdersActionsCell order={record} />;
    },
  },
];
const pageSize = 20;
const today = moment().format("DD/MM/YYYY");

const organisationsOptions = [
  {
    value: "clu39qgmq1325560r8t8f321fui",
    label: "Everfresh",
    id: "clu39qgmq1325560r8t8f321fui",
    info: "Everfresh report only contains orders from Everfresh Group",
  },
  {
    value: null,
    id: "clu39orma1325180r8t4aqlqh1k",
    label: "Newnorth",
    info: "Newnorth report contains orders from Newnorth Group, Glory Fresh and Everfresh Group",
  },
];

export const OrdersReportPage = () => {
  const [dateRange, setDateRange] = useState([moment(), moment()]);
  const [data, setData] = useState({});
  const [loading, setLoading] = useState(false);
  const [organisation, setOrganisation] = useState(organisationsOptions[0]);

  const hanldeSearch = async () => {
    setLoading(true);
    const [start, end] = dateRange;
    if (!start || !end) {
      notification.error({ message: "Please select a date range" });
      setLoading(false);
      return;
    }

    try {
      const params = {
        startDate: start.startOf("day").toISOString(),
        endDate: end.endOf("day").toISOString(),
      };
      if (organisation.value) {
        params.organisationId = organisation.value;
      }
      const { data } = await axios.get(`${process.env.REACT_APP_REST_API_ENDPOINT}/orders/report`, {
        params: params,
      });
      setData(data);
    } catch (error) {
      notification.error({ message: "Failed to fetch orders" });
    }
    setLoading(false);
  };

  const handleRangeChange = (range) => {
    setDateRange(range);
  };

  const handleItemsReportCsvDownload = () => {
    if (!data.itemsReport) {
      notification.error({ message: "No CSV data available" });
      return;
    }

    const [start, end] = dateRange;
    const fileName = `${start.startOf("day").format("YYYY-MM-DD")}-${end
      .endOf("day")
      .format("YYYY-MM-DD")}.csv`;

    const csvData = new Blob([data.itemsReport], { type: "text/csv" });
    const csvUrl = URL.createObjectURL(csvData);

    const link = document.createElement("a");
    link.href = csvUrl;
    link.download = fileName;
    link.click();

    URL.revokeObjectURL(csvUrl);
  };

  const handleOrdersCsvDownload = async () => {
    setLoading(true);
    if (!dateRange[0] || !dateRange[1]) {
      notification.error({ message: "Please select a date range" });
      setLoading(false);

      return;
    }

    try {
      const params = {
        startDate: dateRange[0].startOf("day").toISOString(),
        endDate: dateRange[1].endOf("day").toISOString(),
      };
      if (organisation.value) {
        params.organisationId = organisation.value;
      }
      const { data } = await axios.get(`${process.env.REACT_APP_REST_API_ENDPOINT}/orders/csv`, {
        params,
      });

      if (data.csvContent) {
        const csvData = new Blob([data.csvContent], { type: "text/csv" });
        const csvUrl = URL.createObjectURL(csvData);
        const [start, end] = dateRange;

        const fileName = `Orders_${start.startOf("day").format("YYYY-MM-DD")}_${end
          .endOf("day")
          .format("YYYY-MM-DD")}.csv`;
        const link = document.createElement("a");
        link.href = csvUrl;
        link.download = fileName;
        link.click();

        URL.revokeObjectURL(csvUrl);
      }
    } catch (error) {
      notification.error({ message: "Failed to fetch orders" });
    }

    setLoading(false);
  };

  return (
    <PageContainer>
      <PageHeader ghost={true} title={"Order reports"} />

      <PageContentContainer>
        <ToolbarWrapper>
          <Space>
            <Typography.Title level={5} style={{ margin: 0 }}>
              Select group:
            </Typography.Title>
            <Select
              options={organisationsOptions}
              value={organisation.value}
              defaultValue={organisation.value}
              onChange={(_, option) => setOrganisation(option)}
            />

            <DatePicker.RangePicker
              defaultValue={dateRange.range || [moment(), moment()]}
              allowEmpty={[false, false]}
              allowClear={false}
              ranges={{
                Today: [moment(), moment()],
                Tomorrow: [moment().add({ days: 1 }), moment().add({ days: 1 })],
                "This Month": [moment().startOf("month"), moment().endOf("month")],
              }}
              onChange={handleRangeChange}
            />
            <Button onClick={hanldeSearch} type="primary">
              Search orders
            </Button>
          </Space>

          <Space>
            <Space size={0}>
              <Button
                loading={loading}
                icon={<FilePdfOutlined />}
                onClick={handleOrdersCsvDownload}
              >
                Orders report
              </Button>
              <Button
                loading={loading}
                icon={<FilePdfOutlined />}
                onClick={handleItemsReportCsvDownload}
                disabled={!!!data.itemsReport}
              >
                Items report
              </Button>
            </Space>
          </Space>
        </ToolbarWrapper>
        <Row>
          <Card bordered={false}>
            <Typography.Text>{organisation.info}</Typography.Text>
            <Typography.Title level={5}>
              Please press search orders after select group or date range.
            </Typography.Title>
          </Card>
        </Row>

        {data.count ? (
          <Row>
            <Card bordered={false}>
              <Statistic
                title="Total sales"
                value={data.totalSales}
                loading={loading}
                precision={2}
                valueStyle={{ color: "#3f8600" }}
                prefix="$"
              />
            </Card>
            <Card bordered={false}>
              <Statistic
                title="Number of orders"
                value={data.count}
                loading={loading}
                valueStyle={{ color: "#3f8600" }}
              />
            </Card>
          </Row>
        ) : (
          <Empty />
        )}
      </PageContentContainer>
    </PageContainer>
  );
};
