export const DATE_FORMAT = "DD/MM/YYYY";

export const STATUS_OPERATION = {
  CONFIRM_DRAFT_ORDER: "CONFIRM_DRAFT_ORDER",
  UPDATE_CONFIRMED_ORDER: "UPDATE_CONFIRMED_ORDER",
  UPDATE_DRAFT_ORDER: "UPDATE_DRAFT_ORDER",
  CANCEL_DRAFT_ORDER: "CANCEL_DRAFT_ORDER",
  CANCEL_CONFIRMED_ORDER: "CANCEL_CONFIRMED_ORDER",
  REVERT_CONFIRMED_ORDER: "REVERT_CONFIRMED_ORDER",
};

export const RUNSHEET_DEFAULT_VALUE = [
  {
    truckName: "R1-T1",
    driverName: "Driver A",
    plateNumber: "61",
    orderIds: [],
  },
  { truckName: "R1-T2", driverName: "Driver B", plateNumber: "62", orderIds: [] },
  { truckName: "R1-T3", driverName: "Driver C", plateNumber: "63", orderIds: [] },
  { truckName: "R1-T4", driverName: "Driver D", plateNumber: "64", orderIds: [] },
  { truckName: "R1-T5", driverName: "Driver E", plateNumber: "65", orderIds: [] },
  { truckName: "R1-T6", driverName: "Driver F", plateNumber: "66", orderIds: [] },
  { truckName: "R1-T7", driverName: "Driver FF", plateNumber: "67", orderIds: [] },
  { truckName: "R1-T8", driverName: "Driver FF", plateNumber: "67", orderIds: [] },
  { truckName: "R1-T9", driverName: "Driver FF", plateNumber: "67", orderIds: [] },
  { truckName: "R1-T10", driverName: "Driver X", plateNumber: "67", orderIds: [] },
  { truckName: "R2-T1", driverName: "Driver G", plateNumber: "61", orderIds: [] },
  { truckName: "R2-T2", driverName: "Driver H", plateNumber: "62", orderIds: [] },
  { truckName: "R2-T3", driverName: "Driver I", plateNumber: "63", orderIds: [] },
  { truckName: "R2-T4", driverName: "Driver J", plateNumber: "64", orderIds: [] },
  { truckName: "R2-T5", driverName: "Driver K", plateNumber: "65", orderIds: [] },
  { truckName: "R2-T6", driverName: "Driver L", plateNumber: "66", orderIds: [] },
  { truckName: "R2-T7", driverName: "Driver LL", plateNumber: "67", orderIds: [] },
  { truckName: "R2-T8", driverName: "Driver LL", plateNumber: "67", orderIds: [] },
  { truckName: "R2-T9", driverName: "Driver LL", plateNumber: "67", orderIds: [] },
  { truckName: "R2-T10", driverName: "Driver x", plateNumber: "67", orderIds: [] },
];

export const OrderStatusTypes = {
  CONFIRMED: "confirmed",
  CANCELLED: "cancelled",
  DRAFT: "draft",
};

export const VendorsMap = {
  NEWNORTH: {
    label: "Newnorth",
    imgSrc:
      "https://res.cloudinary.com/wohu-studio/image/upload/v1693812314/FreshCloud/newnorth_ehdunj.jpg",
    value: "NEWNORTH",
  },
  GLORY_FRESH: {
    label: "Glory Fresh",
    imgSrc:
      "https://res.cloudinary.com/wohu-studio/image/upload/v1693812314/FreshCloud/glory_a5li5y.png",
    value: "GLORY_FRESH",
  },
  EVER_FRESH: {
    label: "Ever Fresh Group",
    imgSrc:
      "https://res.cloudinary.com/wohu-studio/image/upload/v1711949578/FreshCloud/logo-no-background_lxfcu5.png",
    value: "EVER_FRESH",
  },
};

export const SAMPLE_COMMENTS_OPTIONS = [
  { label: "Hydroponic", value: "Hydroponic" },
  { label: "Jones", value: "Jones" },
  { label: "Premium", value: "Premium" },
  { label: "Size-Large", value: "Size-Large" },
  { label: "Size-Medium", value: "Size-Medium" },
  { label: "Size-Small", value: "Size-Small" },
  { label: "With Flower", value: "With Flower" },
  { label: "Yellow 黄洋", value: "Yellow 黄洋" },
];
