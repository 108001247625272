const createAndClickDownloadLink = (href, filename, target = "_self") => {
  const link = document.createElement("a");
  link.href = href;
  link.target = target;
  link.setAttribute("download", filename);

  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);

  return link.href; // Return href in case we need to revoke it
};

export const downloadPdf = (data, filename) => {
  const blob = new Blob([data], { type: "application/pdf" });
  const url = window.URL.createObjectURL(blob);

  createAndClickDownloadLink(url, filename);
  window.URL.revokeObjectURL(url);
};

export const downloadBase64File = (uri, fileName) => {
  createAndClickDownloadLink(uri, fileName);
};
