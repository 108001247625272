import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useQuery, useMutation } from "@apollo/client";
import moment from "moment";
import { Button, Badge, notification, PageHeader, Modal, Spin } from "antd";
import _ from "lodash";

import { OrderForm } from "../components/OrderForm";
import { calcTotalPrice, prepareOrderFormValues, calculateUnitPrice } from "../utils";
import {
  // OrdersQuery,
  SingleOrderQuery,
  UpdateOrderAndLineItemsMutation,
} from "../graphql/orders.graphql";
import { PageContainer } from "../components/atoms/PageContainer";
import UnsavedReminder from "../components/atoms/UnsavedReminder";
import { useCallbackPrompt } from "../hooks/useCallbackPrompt";

export const EditOrder = () => {
  const [orderFormValue, setOrderFormValue] = useState({});
  const [customer, setCustomer] = useState({ name: "" });
  const [doFetch, setDoFetch] = useState(true);
  const [reminderOpen, setReminderOpen] = useState(false);
  const [initalIds, setInitalIds] = useState([]);
  const params = useParams();
  const navigate = useNavigate();

  // unsavedReminder
  const [showModal, setShowModal] = useState(false);
  const [showPrompt, confirmNavigation, cancelNavigation] = useCallbackPrompt(true);
  const [isSaved, setIsSaved] = useState(false);

  const { data } = useQuery(SingleOrderQuery, {
    variables: { id: params.id },
    fetchPolicy: "cache-first",
  });

  const [mutate, { loading }] = useMutation(UpdateOrderAndLineItemsMutation);

  useEffect(() => {
    if (data && data.order) {
      setInitalIds(
        data.order.lineItems.map((item) => {
          return { id: item.id };
        })
      );
      let lineItems = [];
      let totalPrice = {
        totalWithoutGst: data.order.totalWithoutGst,
        gstAmount: data.order.gstAmount,
        total: data.order.total,
        grossProfit: data.order.grossProfit,
      };
      if (data.order.status === "draft") {
        lineItems = data.order.lineItems.map((item) => {
          return {
            ...item,
            unitPrice: calculateUnitPrice(
              "default",
              customer.fixedMargin,
              item.productVariant,
              item.unitPrice,
              item.pickingStatus
            ),

            cost: item.isOpenItem ? item.unitPrice : item.productVariant.basePrice,
          };
        });
      } else {
        lineItems = data.order.lineItems.map((item) => ({
          ...item,
          cost: item.isOpenItem ? item.unitPrice : item.productVariant.basePrice,
        }));
      }
      totalPrice = calcTotalPrice(lineItems);

      const formValue = {
        ...data.order,
        lineItems,
        orderDate: moment(data.order.orderDate),
        totalPrice: totalPrice,
      };
      setOrderFormValue(formValue);
      setCustomer(data.order.customer);
    }
  }, [data, customer.fixedMargin]);

  useEffect(() => {
    window.addEventListener("beforeunload", (event) => {
      event.preventDefault();
      event.returnValue = "";
    });

    return () => {
      window.removeEventListener("beforeunload", (event) => {
        event.preventDefault();
        event.returnValue = "";
      });
    };
  });

  useEffect(() => {
    if (isSaved) {
      setShowModal(false);
    } else setShowModal(showPrompt);
  }, [showPrompt, isSaved]);

  const handleSave = async (values) => {
    setIsSaved(true);
    setDoFetch(false);
    const tempData = prepareOrderFormValues(values, customer);
    const result = tempData.lineItems.reduce(
      (prev, current) => {
        const id = current.id;
        delete current.id;
        if (id) {
          prev.updatedItems.push({ where: { id: id }, data: current });
          prev.deletedIds.splice(
            prev.deletedIds.findIndex((item) => item.id === id),
            1
          );
        } else {
          prev.newItems.push(current);
        }
        return prev;
      },
      {
        deletedIds: _.cloneDeep(initalIds),
        updatedItems: [],
        newItems: [],
      }
    );

    const res = await mutate({
      variables: {
        id: params.id,
        data: { ...tempData, lineItems: { create: result.newItems } },
        deletedIds: result.deletedIds,
        lineItemsUpdateData: result.updatedItems,
      },
      // update: (cache, mutationResult) => {
      //   cache.evict(cache.identify(mutationResult.data.updateOrder));
      // },
      refetchQueries: [{ query: SingleOrderQuery, variables: { id: params.id } }],
    });

    if (res.data) {
      notification["success"]({
        message: `Order #${data.order.name} updated.`,
      });
      navigate(`/orders/${params.id}`);
      confirmNavigation();
    }
  };

  return (
    <PageContainer>
      <UnsavedReminder
        showModal={showModal}
        confirmNavigation={confirmNavigation}
        cancelNavigation={cancelNavigation}
      />
      <Modal
        visible={reminderOpen}
        onOk={() => navigate(-1)}
        onCancel={() => setReminderOpen(false)}
      >
        Unsaved changes, are you sure you want to go back?
      </Modal>
      <PageHeader
        title={`${customer?.name} · ${moment(orderFormValue?.orderDate).format("DD/MM/YYYY")}`}
        subTitle={`#${data?.order?.name}`}
        extra={[
          <Badge key="packer001" dot={true} status="success">
            <Button size="small">Connected</Button>
          </Badge>,
        ]}
      />
      {loading ? (
        <Spin />
      ) : (
        <OrderForm
          order={orderFormValue}
          customer={customer}
          setCustomer={setCustomer}
          onSave={handleSave}
          doFetch={doFetch}
        />
      )}
    </PageContainer>
  );
};
