import { Page, Document, Font, View } from "@react-pdf/renderer";
import { Typography, Stack } from "./utilities";
import moment from "moment-timezone";
import { StatusTag } from "../tags/StatusTag";

const today = moment().format("DD/MM/YYYY");

Font.register({
  family: "Microsoft YaHei",
  src: "/fonts/chinese.msyh.ttf",
});

const itemColumns = [
  {
    title: "Description",
    dataKey: "name",
    key: "name",
    span: 6,
  },
  {
    title: "Unit",
    dataKey: " unit",
    key: "unit",
    span: 3,
    render: (_, record) => record.productVariant.name,
  },
  {
    title: "Qty",
    dataKey: "quantity",
    key: "qty",
    span: 3,
  },
  {
    title: "Status",
    dataKey: "pickingStatus",
    key: "pickingStatus",
    align: "right",
    span: 3,
    render: (text) => <StatusTag status={text} />,
  },
];

export const MissingItemsByTruckPDF = ({ truck, items }) => {
  return (
    <Document>
      <Page
        size="A4"
        style={{
          paddingTop: 24,
          paddingBottom: 32,
          paddingHorizontal: 24,
          backgroundColor: "#fff",
          fontFamily: "Microsoft YaHei",
        }}
      >
        {/* pdf title */}
        <Stack sx={{ width: "100%" }}>
          <Stack sx={{ flexDirection: "row", justifyContent: "space-between" }}>
            <Typography sx={{ alignItems: "flex-start", textAlign: "left", fontSize: "16px" }}>
              {today} {truck.truckName}
            </Typography>
            <Typography sx={{ textAlign: "right", fontSize: "16px" }}>
              {truck.driverName} {truck.plateNumber}
            </Typography>
          </Stack>
          <Typography sx={{ textAlign: "left", fontSize: "16px" }}>Missing Items</Typography>
        </Stack>
        {/* pdf body */}

        <Stack>
          {items.map((order, index) => {
            return (
              <Stack key={index} sx={{ width: "100%", marginBottom: "30px" }}>
                <Typography sx={{ fontSize: "16px" }}>
                  # {order.name} {order.customer.name}
                </Typography>
                <View
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    flexWrap: "nowrap",
                    columnGap: "4px",
                    borderBottom: "0.5px solid black",
                  }}
                >
                  {itemColumns.map((th) => {
                    const columnWidth = `${(th.span / 24) * 100}%`;
                    return (
                      <Typography
                        key={th.key}
                        sx={{
                          fontWeight: "700",
                          textAlign: th.align,
                          width: columnWidth,
                          margin: "5px 4px",
                        }}
                      >
                        {th.title}
                      </Typography>
                    );
                  })}
                </View>
                {order.lineItems.map((lineItem, index) => {
                  return (
                    <View
                      key={index}
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        flexWrap: "nowrap",
                        columnGap: "4px",
                        padding: "4px",
                        borderBottom: "0.35px solid lightgrey",
                      }}
                    >
                      {itemColumns.map((col) => {
                        const columnWidth = `${(col.span / 24) * 100}%`;
                        const value = lineItem[col.dataKey];
                        return (
                          <Typography
                            key={col.key}
                            sx={{
                              width: columnWidth,
                              margin: "0 4px",
                              textAlign: col.align,
                              fontFamily: "Microsoft YaHei",
                            }}
                          >
                            {col.render ? col.render(value, lineItem, index) : value}
                          </Typography>
                        );
                      })}
                    </View>
                  );
                })}
              </Stack>
            );
          })}
        </Stack>
      </Page>
    </Document>
  );
};
