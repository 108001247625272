import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useQuery, useMutation } from "@apollo/client";
import { Button, notification, PageHeader, Form } from "antd";
import { prepareProductFormValues } from "../utils";

import { PageContainer } from "../components/atoms/PageContainer";
import { ProductForm } from "../components/ProductForm";

import {
  deleteProductVariantsMutation,
  ProductsQuery,
  SingleProductQuery,
  updateProductMutation,
  updateProductVariantsMutation,
} from "../graphql/products.graphql";
import { CategoriesWithProductsForBulkEdit } from "../graphql/categories.graphql";

import UnsavedReminder from "../components/atoms/UnsavedReminder";
import { useCallbackPrompt } from "../hooks/useCallbackPrompt";

export const EditProduct = () => {
  const params = useParams();
  const [form] = Form.useForm();
  const navigate = useNavigate();
  const [toDelete, setToDelete] = useState([]);

  // unsavedReminder
  const [showModal, setShowModal] = useState(false);
  const [showPrompt, confirmNavigation, cancelNavigation] = useCallbackPrompt(true);
  const [isSaved, setIsSaved] = useState(false);

  const { data } = useQuery(SingleProductQuery, {
    variables: { id: params.id },
    fetchPolicy: "network-only",
  });

  const [updateProduct, { loading }] = useMutation(updateProductMutation, {
    refetchQueries: [{ query: ProductsQuery }, { query: CategoriesWithProductsForBulkEdit }],
    update: (cache, mutationResult) => {
      cache.evict(cache.identify(mutationResult.data.updateProduct));
    },
  });

  const [updateProductVariants, { loading: updateProductVariantsLoading }] = useMutation(
    updateProductVariantsMutation
  );
  const [deleteProductVariants, { loading: deleteProductVariantsLoading }] = useMutation(
    deleteProductVariantsMutation
  );

  useEffect(() => {
    if (data && data.product) {
      form.setFieldsValue({
        ...data.product,
        category: data.product.category?.id,
      });
    }
  }, [data, form]);

  useEffect(() => {
    if (isSaved) {
      setShowModal(false);
    } else setShowModal(showPrompt);
  }, [showPrompt, isSaved]);

  const handleSave = async () => {
    setIsSaved(true);
    try {
      await form.validateFields();
      const values = form.getFieldsValue();
      const { toUpdate, payload } = prepareProductFormValues(values, "update");

      const res = await updateProduct({
        variables: { id: params.id, data: payload },
      });

      if (res.data.updateProduct.id) {
        if (toUpdate.length) {
          await updateProductVariants({
            variables: { data: toUpdate },
          });
        }

        if (toDelete.length) {
          await deleteProductVariants({
            variables: { where: toDelete },
          });
        }

        notification.success({
          message: `${res.data.updateProduct.name} successfully updated.`,
        });
        navigate("/products");
        confirmNavigation();
      }
    } catch (error) {
      console.log("error: ", error);
    }
  };

  return (
    <PageContainer>
      <UnsavedReminder
        showModal={showModal}
        confirmNavigation={confirmNavigation}
        cancelNavigation={cancelNavigation}
      />
      <PageHeader
        title={data?.product.name}
        onBack={() => navigate(-1)}
        extra={[
          <Button
            type="primary"
            key="save"
            onClick={handleSave}
            loading={loading || updateProductVariantsLoading || deleteProductVariantsLoading}
          >
            Save
          </Button>,
        ]}
      />
      <ProductForm setToDelete={setToDelete} form={form} />
    </PageContainer>
  );
};
