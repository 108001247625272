import { FullScreenWrapper } from "@/components/atoms/FullScreenWrapper";
import { useAuth } from "@/hooks/useAuth";
import { Button } from "antd";
import { useNavigate } from "react-router-dom";

export const Unauthorised = () => {
  const { logout } = useAuth();
  const navigate = useNavigate();

  const handleLogout = async () => {
    const res = await logout();
    console.log("res: ", res.data.endSession);
    if (res?.data?.endSession) {
      console.log("logged out");
      navigate("/login", { replace: true });
    }
  };

  return (
    <FullScreenWrapper>
      <h1>Unauthorised</h1>
      <p>Sorry, you are not authorised to view this page.</p>
      <Button onClick={handleLogout} type="primary">
        Login again
      </Button>
    </FullScreenWrapper>
  );
};
