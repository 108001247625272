import { Card } from "antd";
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from "recharts";

export const ChartCard = ({ title = "Chart title", data = [] }) => {
  return (
    <Card title={title} headStyle={{ borderBottom: "0px" }} bodyStyle={{ paddingTop: "0px" }}>
      <ResponsiveContainer height={350}>
        <LineChart data={data} margin={{ right: 20, top: 5 }}>
          <CartesianGrid strokeDasharray="5 5" vertical={false} />
          <XAxis dataKey="date" />
          <YAxis width={40} />
          <Tooltip />
          <Legend verticalAlign="bottom" align="right" height={5} />
          <Line type="monotone" dataKey="total" stroke="#82ca9d" activeDot={{ r: 5 }} />
          <Line type="monotone" dataKey="totalCost" stroke="#0000ff" activeDot={{ r: 5 }} />
          <Line type="monotone" dataKey="totalExpense" stroke="#ff0000" activeDot={{ r: 5 }} />
        </LineChart>
      </ResponsiveContainer>
    </Card>
  );
};
