import { Page, View, Document, Font } from "@react-pdf/renderer";
import { HorizontalLine, Typography, Row } from "./utilities";
import { VendorHeader } from "./VendorHeader";

Font.register({
  family: "Microsoft YaHei",
  src: "/fonts/chinese.msyh.ttf",
});

export const SalespersonPDF = ({ data, columns = [] }) => {
  if (!data || data.length === 0) return null;

  return (
    <Document>
      <Page
        size="A4"
        style={{
          paddingTop: 32,
          paddingBottom: 32,
          paddingHorizontal: 32,
          backgroundColor: "#fff",
        }}
      >
        <Row>
          <VendorHeader />
        </Row>
        {/* table header  */}
        <View
          style={{
            display: "flex",
            flexDirection: "row",
            flexWrap: "nowrap",
            columnGap: "4px",
            marginTop: "20px",
          }}
        >
          {columns.map((th) => {
            const columnWidth = `${(th.span / 12) * 100}%`;
            return (
              <Typography
                key={th.key}
                sx={{
                  fontWeight: "bold",
                  width: columnWidth,
                  textAlign: th.align,
                  margin: "0 4px",
                }}
              >
                {th.title}
              </Typography>
            );
          })}
        </View>
        <HorizontalLine />
        {/* table body  */}
        {data.map((item) => {
          return (
            <View
              key={item.id}
              style={{
                display: "flex",
                flexDirection: "row",
                flexWrap: "nowrap",
                columnGap: "4px",
                padding: "4px",
                borderBottom: "0.35px solid lightgrey",
              }}
            >
              {columns.map((th) => {
                const columnWidth = `${(th.span / 12) * 100}%`;
                const value = item[th.dataKey];
                const textValue = th.render ? th.render(value) : value;
                return (
                  <Typography
                    key={th.key}
                    sx={{
                      width: columnWidth,
                      margin: "0 4px",
                      textAlign: th.align,
                      fontFamily: "Microsoft YaHei",
                    }}
                  >
                    {textValue}
                  </Typography>
                );
              })}
            </View>
          );
        })}
        <HorizontalLine />
      </Page>
    </Document>
  );
};
