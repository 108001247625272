import { Table } from "antd";
import { MoneyCell } from "@/components/tablecells";

export const LineItemsColumns = [
  {
    title: "#",
    dataIndex: "sortingIndex",
    key: "sortingIndex",
    render: (text) => Number(text) + 1,
    // Number(text) + 1,
  },
  {
    title: "Description",
    dataIndex: "name",
    key: "name",
  },
  {
    title: "Unit",
    dataIndex: "unit",
    key: "unit",
    render: (_, record) => {
      return record.productVariant && record.productVariant.name;
    },
  },
  {
    title: "Price",
    dataIndex: "unitPrice",
    key: "price",
    ...MoneyCell,
  },
];

export const LineItemsTableForQuotation = ({ items, loading }) => {
  return (
    <Table
      size="small"
      pagination={false}
      columns={LineItemsColumns}
      dataSource={items}
      loading={loading}
      rowKey="id"
    />
  );
};
