import { useState, useEffect } from "react";
import { useLazyQuery } from "@apollo/client";
import { PageHeader, Button, Typography, DatePicker, Radio, Space, Card } from "antd";
import { usePDF } from "@react-pdf/renderer";
import moment from "moment";
import styled from "styled-components";

import { PageContainer } from "../components/atoms/PageContainer";
import { SalesReportPDF } from "../components/pdf/SalesReportPDF";
import { AllProductsOldAndNewQuery } from "../graphql/products.graphql";
import { TodaysLineItemsQuery } from "../graphql/lineItems.graphql";
import {
  exportExcel,
  getProductVariantsGroupedByCategories,
  getProductVariantsSoldBreakdown,
} from "../utils";
import { FileExcelFilled, FilePdfFilled } from "@ant-design/icons";

const REPORT_STATUS = {
  fail: { type: "danger", message: "Something went wrong. Please try again." },
  success: { type: "success", message: "Report generate success." },
  empty: { type: "warning", message: "There is no order in the selected date range." },
  default: {},
};
const today = moment().format("DD/MM/YYYY");

const BuyersPortalItemWrapper = styled.div`
  margin-top: 16px;
`;

const columns = [
  {
    title: "Category",
    key: "category",
    dataIndex: "category",
    span: 6,
    align: "left",
  },
  {
    title: "Product",
    key: "product",
    dataIndex: "displayName_EN",
    span: 8,
    align: "left",
  },
  {
    title: "Unit",
    key: "unit",
    dataIndex: "unitName",
    span: 4,
    align: "left",
  },
  {
    title: "Sold",
    dataIndex: "sold",
    key: "sold",
    span: 3,
    align: "right",
    render: (value) => value ?? <Typography.Text>N/A</Typography.Text>,
  },
  {
    title: "On hand",
    dataIndex: "quantity",
    key: "quantity",
    span: 3,
    align: "right",
    render: (value) => value ?? <Typography.Text>N/A</Typography.Text>,
  },
];

export const BuyersPortal = () => {
  const [salesReportData, setSalesReportData] = useState([]);
  const [reportLoading, setReportLoading] = useState(false);
  const [reportType, setReportType] = useState("all");
  const [reportResult, setReportResult] = useState(REPORT_STATUS.default);
  const [filter, setFilter] = useState({
    order: {
      orderDate: {
        gte: moment().startOf("day").toISOString(),
        lte: moment().endOf("day").toISOString(),
      },
      status: { not: { equals: "cancelled" } },
    },
  });
  const [dateString, setDateString] = useState(`${today} - ${today}`);
  const [pdfFile, updatePdfFile] = usePDF({
    document: (
      <SalesReportPDF data={salesReportData} reportType={reportType} dateString={dateString} />
    ),
  });
  const [searchProducts, { data, error }] = useLazyQuery(AllProductsOldAndNewQuery, {
    fetchPolicy: "cache-and-network",
  });
  const [searchLineItems, { data: lineItemsData, error: lineItemsError }] = useLazyQuery(
    TodaysLineItemsQuery,
    {
      variables: { where: filter },
      fetchPolicy: "cache-and-network",
    }
  );

  useEffect(() => {
    if (data?.products.length > 0 && lineItemsData?.lineItems.length >= 0) {
      const productCatalogue = getProductVariantsGroupedByCategories(data.products);
      const productVariantsSoldBreakdown = getProductVariantsSoldBreakdown(lineItemsData.lineItems);

      const tempSalesReportData = {};

      for (const category in productCatalogue) {
        tempSalesReportData[category] = productCatalogue[category].flatMap((productVariant) => {
          const productVariantId = productVariant.id;
          const lineItemGroup = productVariantsSoldBreakdown[productVariantId];

          if (lineItemGroup) {
            const itemsWithComments = [];

            // const displayName = `${productVariant.productName} / ${productVariant.name}`;

            if (lineItemGroup["no_comments"]) {
              if (productVariant.quantity !== null) {
                console.log("productVariant: ", productVariant);
              }

              itemsWithComments.push({
                category: category,
                displayName_EN: `${productVariant.productName}`,
                unitName: `${productVariant.name}`,
                quantity: productVariant.quantity
                  ? parseFloat(productVariant.quantity).toFixed(2)
                  : "0",
                sold: lineItemGroup["no_comments"].totalQuantity.toFixed(2),
              });
            }

            for (const notes in lineItemGroup) {
              if (notes !== "no_comments") {
                itemsWithComments.push({
                  category: category,
                  displayName_EN: `${productVariant.productName} (${notes})`,
                  unitName: `${productVariant.name}`,
                  quantity: "0",
                  sold: lineItemGroup[notes].totalQuantity?.toFixed(2),
                });
              }
            }

            return itemsWithComments;
          }

          return [];
        });
      }

      setSalesReportData(tempSalesReportData);
      console.log("tempSalesReportData: ", tempSalesReportData);
    }
  }, [data, lineItemsData]);

  useEffect(() => {
    if (error || lineItemsError || pdfFile.error) {
      setReportResult(REPORT_STATUS.fail);
      setReportLoading(false);
    }
  }, [error, lineItemsError, pdfFile.error]);

  useEffect(() => {
    updatePdfFile();
    //eslint-disable-next-line
  }, [salesReportData, reportType, dateString]);

  useEffect(() => {
    if (lineItemsData?.lineItems) {
      if (reportLoading && !pdfFile.error && !pdfFile.loading) {
        if (lineItemsData?.lineItems.length) setReportResult(REPORT_STATUS.success);
        else setReportResult(REPORT_STATUS.empty);
        setReportLoading(false);
      }
    }
  }, [pdfFile, reportLoading, lineItemsData]);

  const handleDateRange = (range) => {
    setReportLoading(false);
    setReportResult(REPORT_STATUS.default);
    if (!range) {
      setFilter({});
      setDateString("All");
      return;
    }

    const [start, end] = range;

    if (!start) {
      setFilter({
        order: {
          orderDate: {
            lte: end.endOf("day").toISOString(),
          },
          status: { not: { equals: "cancelled" } },
        },
      });
      setDateString(`- ${end.format("DD/MM/YYYY")}`);
      return;
    }
    if (!end) {
      setFilter({
        order: {
          orderDate: {
            gte: start.startOf("day").toISOString(),
          },
          status: { not: { equals: "cancelled" } },
        },
      });
      setDateString(`${start.format("DD/MM/YYYY")} -`);
      return;
    }

    setFilter({
      order: {
        orderDate: {
          gte: start.startOf("day").toISOString(),
          lte: end.endOf("day").toISOString(),
        },
        status: { not: { equals: "cancelled" } },
      },
    });
    setDateString(`${start.format("DD/MM/YYYY")} - ${end.format("DD/MM/YYYY")}`);
  };

  const handleReportType = (e) => {
    setReportType(e.target.value);
    setReportLoading(false);
    setReportResult(REPORT_STATUS.default);
  };

  const generateReport = () => {
    if (!data?.products) searchProducts();
    searchLineItems(filter);
    setReportResult(REPORT_STATUS.default);
    setReportLoading(true);
  };

  const handleExcelExport = () => {
    const exportedData = Object.values(salesReportData).reduce((prev, current) => {
      return [...prev, ...current];
    }, []);
    exportExcel(exportedData, `Sales_Report_as_of_${dateString}.xlsx`);
  };

  return (
    <PageContainer>
      <PageHeader ghost={true} title="Buyer" />
      <Card>
        <Typography.Title level={5}>
          Please select date and report options, then click Generate Report button
        </Typography.Title>
        <BuyersPortalItemWrapper>
          <Typography.Title level={5}>Order Date</Typography.Title>
          <DatePicker.RangePicker
            defaultValue={[moment(), moment()]}
            allowEmpty={[true, true]}
            ranges={{
              Today: [moment(), moment()],
              Tomorrow: [moment().add({ days: 1 }), moment().add({ days: 1 })],
              "This Month": [moment().startOf("month"), moment().endOf("month")],
            }}
            onChange={handleDateRange}
          />
        </BuyersPortalItemWrapper>
        <BuyersPortalItemWrapper>
          <Typography.Title level={5}>Option</Typography.Title>
          <Radio.Group defaultValue="all" buttonStyle="solid" onChange={handleReportType}>
            <Radio.Button value="all">All</Radio.Button>
            <Radio.Button value="soldOnly">Sold products report only</Radio.Button>
          </Radio.Group>
        </BuyersPortalItemWrapper>

        <BuyersPortalItemWrapper>
          <Button type="primary" onClick={generateReport} loading={reportLoading}>
            Generate Report
          </Button>
        </BuyersPortalItemWrapper>
        <Typography.Text type={reportResult?.type}>{reportResult?.message}</Typography.Text>

        <BuyersPortalItemWrapper>
          <Space>
            <a
              href={pdfFile.url}
              target="_blank"
              rel="noreferrer"
              download={`Sales_Report_as_of_${dateString}.pdf`}
            >
              <Button icon={<FilePdfFilled />} disabled={reportResult.type !== "success"}>
                Download PDF
              </Button>
            </a>

            <Button
              icon={<FileExcelFilled />}
              disabled={reportResult.type !== "success"}
              onClick={handleExcelExport}
            >
              Download Excel
            </Button>
          </Space>
        </BuyersPortalItemWrapper>

        {/* {Object.entries(salesReportData).map(([key, value]) => {
          return (
            <>
              <Typography.Title level={2}>{key}</Typography.Title>
              <Table columns={columns} size="small" dataSource={value} />
            </>
          );
        })} */}
      </Card>
    </PageContainer>
  );
};
