import { useEffect, useState } from "react";
import { useLazyQuery } from "@apollo/client";
import { Button, Typography, Tooltip } from "antd";
import { getOrderTotal } from "../../utils";
import { HistoryPriceQuery } from "../../graphql/lineItems.graphql";
import moment from "moment";

export const HistoryPriceField = ({ index, productId, form, customer, doFetch }) => {
  const [fetchHistory, { data, loading }] = useLazyQuery(HistoryPriceQuery, {
    fetchPolicy: "network-only",
  });
  const [historyData, setHistoryData] = useState([]);

  useEffect(() => {
    if (!doFetch) return;
    if (productId) {
      const customerWhere = customer.franchise?.id
        ? { franchise: { id: { equals: customer.franchise.id } } }
        : { id: { equals: customer.id } };

      fetchHistory({
        variables: {
          customerWhere,
          productId,
        },
      });
    }
  }, [customer, form, index, productId, fetchHistory, doFetch]);

  useEffect(() => {
    if (data?.product) {
      const history = data.product.productVariants.reduce((prev, current) => {
        const historyLienItem = current.lineItems[0];
        if (historyLienItem) {
          return [
            ...prev,
            {
              name: current.name,
              customerName: historyLienItem?.order?.customer?.name || "N/A",
              orderName: historyLienItem?.order?.name || "N/A",
              price: historyLienItem?.unitPrice || "N/A",
              updatedOn: historyLienItem?.updatedAt
                ? moment(historyLienItem?.updatedAt).format("DD/MM")
                : "N/A",
              updatedAt: historyLienItem?.updatedAt
                ? moment(historyLienItem?.updatedAt).format("DD/MM/YYYY,HH:MM:SS")
                : "N/A",
            },
          ];
        } else {
          return prev;
        }
      }, []);

      setHistoryData(history);
      form.setFieldsValue({ invisibleItem: "" });
    }
  }, [data, form, index, loading]);

  const setPrice = (index, price) => {
    if (!price) return;
    form.setFields([
      {
        name: ["lineItems", index, "unitPrice"],
        value: price,
      },
    ]);
    let lineItemsList = form.getFieldValue().lineItems;
    if (lineItemsList[0]) {
      const total = getOrderTotal(lineItemsList);
      form.setFields([{ name: "total", value: total }]);
    }
  };

  if (loading) return <Typography.Text type="secondary">Loading</Typography.Text>;

  return (
    <div>
      {historyData.map((variant) => {
        return (
          <Tooltip
            key={variant?.id}
            title={`${variant?.customerName} - ${variant?.orderName} upated at: ${variant.updatedAt}`}
          >
            <Button
              type="link"
              size="small"
              onClick={() => setPrice(index, variant?.price)}
              style={{ padding: 0, fontSize: 12 }}
            >
              {variant.name}: {variant?.price}({variant?.updatedOn})
            </Button>
          </Tooltip>
        );
      })}
    </div>
  );
};
