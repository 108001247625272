import { AutoComplete, Form } from "antd";

export const ProductSearchSelect = ({ options, onSelect, name }) => {
  const handleSelect = (_, option) => {
    onSelect(option);
  };

  return (
    <Form.Item
      name={name}
      // key={key}
      style={{ marginBottom: 5 }}
      valuePropName="value"
    >
      <AutoComplete
        options={options}
        // bordered={false}
        onSelect={handleSelect}
        filterOption={(inputValue, option) =>
          option.value.toUpperCase().indexOf(inputValue.toUpperCase()) !== -1
        }
        placeholder="Type to search product..."
        // allowClear
        dropdownMatchSelectWidth={420}
      />
    </Form.Item>
  );
};
