import { createContext, useEffect, useState } from "react";
import PropTypes from "prop-types";

import { useMutation, useQuery } from "@apollo/client";
import { CurrentUserQuery, LoginMutation, LogoutMutation } from "../graphql/users.graphql";

const initialState = {
  authenticated: false,
  loading: true,
  user: null,
};

const AuthContext = createContext({
  ...initialState,
});

const AuthProvider = ({ children }) => {
  const [authState, setAuthState] = useState(initialState);
  const { data: currentUserData, loading: currentUserLoading } = useQuery(CurrentUserQuery);
  const [userLogin, { data: loginData, loading: loginLoading }] = useMutation(LoginMutation);
  const [userLogout, { data: logoutData }] = useMutation(LogoutMutation);

  useEffect(() => {
    if (currentUserData) {
      if (currentUserData?.authenticatedItem) {
        const user = currentUserData.authenticatedItem;
        setAuthState((a) => ({
          ...a,
          authenticated: !!user.id,
          user: user,
          loading: false,
        }));
      } else {
        setAuthState((a) => ({
          ...a,
          authenticated: false,
          user: null,
          loading: false,
        }));
      }
    }
  }, [currentUserData, currentUserLoading]);

  useEffect(() => {
    if (loginData?.authenticateUserWithPassword) {
      if (
        loginData?.authenticateUserWithPassword.__typename ===
        "UserAuthenticationWithPasswordSuccess"
      ) {
        const user = loginData.authenticateUserWithPassword.item;
        console.log("[user_logged_in]", user);

        setAuthState((a) => ({
          ...a,
          authenticated: true,
          loading: loginLoading,
          user: user,
        }));
      }
    }
  }, [loginData, loginLoading]);

  useEffect(() => {
    if (logoutData && logoutData.endSession) {
      setAuthState((a) => ({
        ...a,
        authenticated: false,
        user: null,
      }));
    }
  }, [logoutData]);

  const login = async (username, password) => {
    return await userLogin({ variables: { username, password } });
  };

  const logout = async () => {
    return await userLogout();
  };

  return (
    <AuthContext.Provider
      value={{
        ...authState,
        login,
        logout,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

AuthProvider.propTypes = {
  children: PropTypes.node,
};

export { AuthContext, AuthProvider };
