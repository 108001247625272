import { gql, useQuery } from "@apollo/client";
import { useState, useEffect } from "react";

const FranchisesQuery = gql`
  query FranchisesQuery {
    customers(where: { isFranchise: { equals: true } }) {
      id
      name
    }
  }
`;

export const useFranchises = () => {
  const [franchises, setFranchises] = useState([]);
  const { data, error, loading } = useQuery(FranchisesQuery, { fetchPolicy: "network-only" });

  useEffect(() => {
    if (data?.customers) {
      const options = [];
      data.customers.forEach((c) => {
        const opt = getFranchiseOption(c.name, c.id);
        options.push(opt);
      });
      options.unshift({
        label: "+ Create new franchise",
        value: null,
        id: null,
        actionType: "create",
      });
      options.unshift({
        label: "× No franchise",
        value: "No franchise",
        id: undefined,
        actionType: "disconnect",
      });

      setFranchises(options);
    }
  }, [data]);

  const getFranchiseOption = (displayName, id) => {
    return {
      label: displayName,
      value: displayName,
      id: id,
      actionType: "connect",
    };
  };
  return { franchises, error, loading };
};
