import { useEffect, useState } from "react";
import { useMutation, useQuery } from "@apollo/client";
import {
  Row,
  Col,
  Typography,
  Table,
  Form,
  InputNumber,
  Button,
  Space,
  Divider,
  PageHeader,
} from "antd";
import moment from "moment";
import { PageContainer } from "../components/atoms/PageContainer";
import {
  ProductRelatedLineItemsQuery,
  UpdateLineItemsMutation,
} from "../graphql/lineItems.graphql";
import { useParams } from "react-router-dom";

const columns = [
  { title: "Order", dataIndex: "orderName", key: "orderName" },
  {
    title: "Customer",
    dataIndex: "customerName",
    key: "customerName",
  },
  { title: "Unit", dataIndex: "packagingUnit", key: "packagingUnit" },
  {
    title: "Quantity",
    dataIndex: "quantity",
    key: "quantity",
    render: (_, { quantity, newQuantity }) => {
      return (
        <Space>
          {quantity} {newQuantity > 0 && `-> ${newQuantity}`}
        </Space>
      );
    },
  },
  {
    title: "New",
    dataIndex: "new",
    key: "new",
    render: (_, record) => (
      <Form.Item name={["lineItems", record.id]}>
        <InputNumber />
      </Form.Item>
    ),
  },
];

export const ProductAllocation = () => {
  const params = useParams();
  const [product, setProduct] = useState({});
  const [lineItems, setLineItems] = useState([]);
  const [total, setTotal] = useState(0);
  const [totalDisplay, setTotalDisplay] = useState("");
  const [remaining, setRemaining] = useState(0);

  const [form] = Form.useForm();

  const { data } = useQuery(ProductRelatedLineItemsQuery, {
    variables: {
      productId: params.id,
      orderDateFrom: moment().startOf("day").toISOString(),
      orderDateTo: moment().endOf("day").toISOString(),
    },
    fetchPolicy: "network-only",
  });

  const [updateLineItems] = useMutation(UpdateLineItemsMutation);

  useEffect(() => {
    if (data && data.product) {
      setProduct(data.product);
      const mappedLineItems = data.product.lineItems.map((item) => {
        return {
          id: item.id,
          orderName: item.order.name,
          defaultUnit: data.product.defaultUnit,
          conversionRate: +data.product.unitConversionRate,
          customerName: item.order.customer.name,
          packagingUnit: item.packagingUnit,
          quantity: +item.quantity,
          newQuantity: +item.quantity,
          quantityAsDefaultUnit:
            item.packagingUnit === data.product.defaultUnit
              ? item.quantity
              : +item.quantity * data.product.unitConversionRate,
        };
      });

      setTotal(data.product.inventoryQuantity);
      setTotalDisplay(data.product.displayQuantity);
      setLineItems(mappedLineItems);
    }
  }, [data]);

  useEffect(() => {
    if (lineItems.length > 0) {
      console.log("lineItems: ", lineItems);
      const totalAllocated =
        lineItems.reduce((prev, current) => {
          if (current.defaultUnit !== current.packagingUnit) {
            return prev + current.newQuantity * current.conversionRate;
          }
          return prev + current.newQuantity;
        }, 0) || 0;

      setRemaining(total - totalAllocated);
    }
  }, [lineItems, total]);

  const hanldeSubmit = () => {
    const { lineItems } = form.getFieldsValue();
    const payload = Object.keys(lineItems).map((key) => {
      return {
        where: { id: key },
        data: { quantity: lineItems[key].toString() },
      };
    });

    updateLineItems({ variables: { data: payload } });
  };

  const updateNewQuantity = (id, newQuantity) => {
    const itemIndex = lineItems.findIndex((line) => id === line.id);
    const item = lineItems[itemIndex];
    const newItem = { ...item, newQuantity };
    const tempItems = [...lineItems];
    tempItems.splice(itemIndex, 1, newItem);
    setLineItems(tempItems);
  };

  const handleFieldsChange = (changedFields) => {
    changedFields.forEach((field) => {
      updateNewQuantity(field.name[1], field.value);
    });
  };

  return (
    <PageContainer>
      <PageHeader
        title={`${product.name}, orders for ${moment().format("YYYY-MM-DD")}`}
        extra={[
          <Button key="submit" type="primary" onClick={hanldeSubmit}>
            Submit
          </Button>,
        ]}
      />
      <Row>
        <Col span={16}>
          <Space>
            <Typography.Title type={remaining >= 0 ? "success" : "danger"} level={4}>
              Balance: {remaining}
            </Typography.Title>
            <Divider type="vertical" />

            <Typography.Title level={4}>
              Onhand: {Math.round(total)} | {Math.round(totalDisplay)}
            </Typography.Title>
            <Divider type="vertical" />
            <Typography.Title level={4}>Unit: {product.defaultUnit}</Typography.Title>
          </Space>
        </Col>
      </Row>

      <Form form={form} onFieldsChange={handleFieldsChange}>
        <Table columns={columns} dataSource={lineItems} rowKey="id" pagination={false} />
      </Form>
    </PageContainer>
  );
};
