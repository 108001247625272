import { View } from "@react-pdf/renderer";
import { Typography, HorizontalLine } from "../utilities";
import { formatMoney } from "../../../utils";

export const InvoicesTable = ({ orders, columns, name, total }) => {
  return (
    <>
      {name && (
        <>
          <HorizontalLine sx={{ margin: "8px 0", border: "1px dashed darkgrey" }} />
          <Typography type="header" sx={{ fontSize: 14, marginBottom: "4px" }}>
            {name}
          </Typography>
        </>
      )}

      {/* table head */}
      <View
        style={{
          display: "flex",
          flexDirection: "row",
          flexWrap: "nowrap",
          columnGap: "4px",
          padding: "4px 0",
          justifyContent: "space-between",
        }}
      >
        {columns.map((th) => {
          const columnWidth = `${(th.span / 24) * 100}%`;
          return (
            <Typography
              key={th.key}
              sx={{
                fontWeight: "bold",
                width: columnWidth,
                textAlign: th.align,
              }}
            >
              {th.title}
            </Typography>
          );
        })}
      </View>
      <HorizontalLine sx={{ border: "0.5px solid #000" }} />

      {/* table body  */}

      {orders
        .filter((o) => o.status === "confirmed" && o.paymentStatus !== "paid")
        .map((item, index) => {
          return (
            <View
              key={item.id}
              style={{
                display: "flex",
                flexDirection: "row",
                flexWrap: "nowrap",
                columnGap: "4px",
                padding: "4px 0",
                borderBottom: "0.35px solid lightgrey",
                justifyContent: "space-between",
              }}
            >
              {columns.map((th) => {
                const columnWidth = `${(th.span / 24) * 100}%`;
                const value = item[th.dataKey];
                return (
                  <Typography
                    key={th.key}
                    sx={{
                      width: columnWidth,
                      textAlign: th.align,
                    }}
                  >
                    {th.render ? th.render(value, item) : value}
                  </Typography>
                );
              })}
            </View>
          );
        })}

      <HorizontalLine />

      <View
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "flex-end",
          padding: "10px 0",
        }}
      >
        <Typography type="header" sx={{ fontSize: 12, marginRight: 12 }}>
          {name} Total:
        </Typography>
        <Typography type="header" sx={{ fontSize: 12 }}>
          {formatMoney(total)}
        </Typography>
      </View>
    </>
  );
};
