export const PAYMENT_OPTIONS = [
  { label: "Cash", value: "cash" },
  { label: "EFT", value: "eft" },
  { label: "Credit Card", value: "card" },
  { label: "Shopify", value: "shopify" },
  { label: "POS", value: "pos" },
  { label: "Other", value: "other" },
];

export const TEMRS_OPTIONS = [
  {
    label: "Cash",
    value: "Cash",
  },
  {
    label: "7 days",
    value: "7 days",
  },
  { label: "30 days", value: "30 days" },
];

export const CUSTOMER_SORT_OPTIONS = [
  {
    value: "lastOrderDesc",
    label: "Last Order (Oldest to newest)",
  },
  {
    value: "lastOrderAsc",
    label: "Last Order (Newest to oldest)",
  },
  {
    value: "orderCountDesc",
    label: "Order Count (Greatest to least)",
  },
  {
    value: "orderCountAsc",
    label: "Order Count (Least to greatest)",
  },
];

export const STATEMENT_SORT_OPTIONS = [
  {
    value: "lastPurchaseDesc",
    label: "Last Purchase (Oldest to newest)",
  },
  {
    value: "lastPurchaseAsc",
    label: "Last Purchase (Newest to oldest)",
  },
  {
    value: "lastPaymentDesc",
    label: "Last Payment (Oldest to newest)",
  },
  {
    value: "lastPaymentAsc",
    label: "Last Payment (Newest to oldest)",
  },
  {
    value: "oldestOutstandingOrder",
    label: "Oldest Outstanding Order",
  },
];
