import { useState, useEffect } from "react";
import { useMutation, useQuery } from "@apollo/client";
import {
  Button,
  Tabs,
  Row,
  Col,
  Form,
  Typography,
  InputNumber,
  notification,
  Card,
  Input,
} from "antd";
import { useNavigate } from "react-router-dom";

import { CategoriesWithProductsForBulkEditV2 } from "@/graphql/categories.graphql";
import { UpdateProductVariantsMutation } from "@/graphql/products.graphql";
import { AffixedPageHeader } from "@/components/atoms/AffixedPageHeader";

const { TabPane } = Tabs;

export const StockTake = () => {
  const [cats, setCats] = useState([]);
  const { data, loading: fetchLoading } = useQuery(CategoriesWithProductsForBulkEditV2, {
    fetchPolicy: "cache-first",
  });
  const [updateVariants, { loading }] = useMutation(UpdateProductVariantsMutation);
  const [form] = Form.useForm();
  const navigate = useNavigate();
  const [changedIds, setChangedIds] = useState(new Set());

  useEffect(() => {
    if (data?.categories) {
      setCats(data.categories);
      const temp = {};
      data.categories.forEach((cat) => {
        cat.products.forEach((product) => {
          product.productVariants.forEach((variant) => {
            temp[variant.id] = {
              ...variant,
              defaultMargin: (Number(variant.defaultMargin) * 100).toFixed(2),
              product: { [product.id]: { category: cat.id } },
            };
          });
        });
      });

      form.setFieldsValue(temp);
    }
  }, [data, form]);

  const handleFieldsChange = (changedFields) => {
    const first = changedFields[0];
    const actualField = first.name[1];
    const id = first.name[0];
    let base = 0;
    let margin = 0;

    if (actualField === "sellingPrice") {
      base = form.getFieldValue([id, "basePrice"]);
      return;
    }
    if (actualField === "basePrice") {
      base = first.value;
    } else {
      base = form.getFieldValue([id, "basePrice"]) || 0;
    }

    if (actualField === "defaultMargin") {
      margin = first.value;
    } else {
      margin = form.getFieldValue([id, "defaultMargin"]) || 0;
    }
    const selling = (parseFloat(base) * (1 + parseFloat(margin) / 100)).toFixed(2);
    form.setFields([{ name: [id, "sellingPrice"], value: selling }]);
    // changedIds is new Set () here. Set is used here to store unique values of any type
    setChangedIds(changedIds.add(id));
  };

  const handleSave = async () => {
    const values = form.getFieldsValue();

    const payload = Array.from(changedIds).map((changedId) => {
      return {
        where: { id: changedId },
        data: { ...values[changedId] },
      };
    });

    const res = await updateVariants({
      variables: { data: payload },
      refetchQueries: [{ query: CategoriesWithProductsForBulkEditV2 }],
    });
    if (res.data) {
      notification.success({ message: `${payload.length} units updated.` });
      navigate(-1);
    }
  };

  return (
    <>
      <AffixedPageHeader
        title="Edit Products V2"
        extra={[
          <Input.Search key="2" placeholder="Search products..." />,
          <Button key="1" type="primary" onClick={handleSave} loading={loading}>
            Save
          </Button>,
        ]}
      />
      <Card loading={fetchLoading}>
        <Form form={form} onFieldsChange={handleFieldsChange}>
          <Tabs defaultActiveKey={0}>
            {cats.map((cat, index) => {
              return (
                <TabPane tab={cat.name} key={index}>
                  <Row
                    gutter={8}
                    style={{
                      padding: "4px 8px",
                      borderBottom: "0.5px solid lightgrey",
                    }}
                  >
                    <Col span={8}>
                      <Typography.Text strong>Product</Typography.Text>
                    </Col>
                    <Col span={4}>
                      <Typography.Text strong>Unit</Typography.Text>
                    </Col>
                    <Col span={4}>
                      <Typography.Text strong>On hand</Typography.Text>
                    </Col>
                  </Row>
                  {cat.products.map((product) => {
                    return (
                      <div
                        style={{
                          padding: "4px",
                          borderTop: "1px solid grey",
                        }}
                      >
                        <Row key={product.id}>
                          <Typography.Title level={5}>{product.name}</Typography.Title>
                        </Row>
                        {product.productVariants.map((variant) => {
                          return (
                            <Row key={variant.id}>
                              <Col span={8}>
                                <Typography.Text type="secondary">{product.name}</Typography.Text>
                              </Col>
                              <Col span={4}>
                                <Typography.Text>{variant.name}</Typography.Text>
                              </Col>
                              <Col span={4} style={{ border: "0.35px solid lightgrey" }}>
                                <Form.Item name={[variant.id, "quantity"]} noStyle>
                                  <InputNumber
                                    precision={2}
                                    style={{ width: "100%" }}
                                    bordered={false}
                                    stringMode={true}
                                  />
                                </Form.Item>
                              </Col>
                            </Row>
                          );
                        })}
                      </div>
                    );
                  })}
                </TabPane>
              );
            })}
          </Tabs>
        </Form>
      </Card>
    </>
  );
};
