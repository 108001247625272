import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useQuery, useMutation } from "@apollo/client";
import moment from "moment";
import { Button, notification, PageHeader, Modal, Form } from "antd";
import _ from "lodash";

import { CreditNoteForm } from "../components/CreditNoteForm";
import {
  OrdersAsInvoicesQuery,
  SingleOrderAsCreditNoteQuery,
  UpdateOrderAndLineItemsMutation,
} from "../graphql/orders.graphql";
import { PageContainer } from "../components/atoms/PageContainer";
import UnsavedReminder from "../components/atoms/UnsavedReminder";
import { useCallbackPrompt } from "../hooks/useCallbackPrompt";
import { convertToNegativeTotal } from "../utils";

export const EditCreditNote = () => {
  const [customer, setCustomer] = useState({ name: "" });
  const [reminderOpen, setReminderOpen] = useState(false);
  const [initalIds, setInitalIds] = useState([]);
  const [form] = Form.useForm();
  const params = useParams();
  const navigate = useNavigate();

  // unsavedReminder
  const [showModal, setShowModal] = useState(false);
  const [showPrompt, confirmNavigation, cancelNavigation] = useCallbackPrompt(true);
  const [isSaved, setIsSaved] = useState(false);

  const { data } = useQuery(SingleOrderAsCreditNoteQuery, {
    variables: { id: params.id },
    fetchPolicy: "network-only",
  });

  const [mutate, { loading }] = useMutation(UpdateOrderAndLineItemsMutation);

  useEffect(() => {
    if (data && data.order) {
      setInitalIds(
        data.order.lineItems.map((item) => {
          return { id: item.id };
        })
      );

      const formValue = {
        ...data.order,
        orderDate: moment(data.order.orderDate),
        totalPrice: {
          total: convertToNegativeTotal(data.order.total),
          totalWithoutGst: convertToNegativeTotal(data.order.totalWithoutGst),
        },
      };
      form.setFieldsValue(formValue);
      console.log("formValue: ", formValue);
      setCustomer(data.order.customer);
    }
  }, [data, form]);

  useEffect(() => {
    window.addEventListener("beforeunload", (event) => {
      event.preventDefault();
      event.returnValue = "";
    });

    return () => {
      window.removeEventListener("beforeunload", (event) => {
        event.preventDefault();
        event.returnValue = "";
      });
    };
  });

  useEffect(() => {
    if (isSaved) {
      setShowModal(false);
    } else setShowModal(showPrompt);
  }, [showPrompt, isSaved]);

  const handleSave = async () => {
    setIsSaved(true);
    const values = form.getFieldsValue();
    const { lineItems: unfilteredItems, totalPrice, orderDate } = values;
    const result = unfilteredItems.reduce(
      (prev, current, currentIndex) => {
        if (!current.name) return prev;

        const id = current.id;
        delete current.id;
        delete current.__typename;
        if (id) {
          prev.updatedItems.push({
            where: { id: id },
            data: { ...current, sortingIndex: currentIndex, isOpenItem: true },
          });
          prev.deletedIds.splice(
            prev.deletedIds.findIndex((item) => item.id === id),
            1
          );
        } else {
          prev.newItems.push({ ...current, sortingIndex: currentIndex, isOpenItem: true });
        }
        return prev;
      },
      {
        deletedIds: _.cloneDeep(initalIds),
        updatedItems: [],
        newItems: [],
      }
    );

    const res = await mutate({
      variables: {
        id: params.id,
        data: {
          total: convertToNegativeTotal(totalPrice.total),
          totalWithoutGst: convertToNegativeTotal(totalPrice.totalWithoutGst),
          orderDate,
          lineItems: { create: result.newItems },
        },
        deletedIds: result.deletedIds,
        lineItemsUpdateData: result.updatedItems,
      },
      update: (cache, mutationResult) => {
        cache.evict(cache.identify(mutationResult.data.updateOrder));
      },
      refetchQueries: [{ query: OrdersAsInvoicesQuery }],
    });

    if (res.data) {
      notification["success"]({
        message: `Credit note #${data.order.name} successfully updated`,
      });
      navigate(`/credit-notes/${params.id}`);
      confirmNavigation();
    }
  };

  return (
    <PageContainer>
      <UnsavedReminder
        showModal={showModal}
        confirmNavigation={confirmNavigation}
        cancelNavigation={cancelNavigation}
      />
      <Modal
        visible={reminderOpen}
        onOk={() => navigate(-1)}
        onCancel={() => setReminderOpen(false)}
      >
        Unsaved changes, are you sure you want to go back?
      </Modal>
      <PageHeader
        title={`Credit Note for ${customer?.name}`}
        subTitle={`#${data?.order.name}`}
        extra={[
          <Button type="primary" key="save" onClick={handleSave}>
            Save
          </Button>,
        ]}
      />

      <CreditNoteForm customer={customer} setCustomer={setCustomer} form={form} loading={loading} />
    </PageContainer>
  );
};
