import { Tag } from "antd";

export const statusMapper = {
  draft: { color: "magenta", label: "Draft" },
  pending: { color: "gold", label: "Pending" },
  confirmed: { color: "success", label: "Confirmed" },
  cancelled: { color: "", label: "Cancelled" },
  packed: { color: "success", label: "Packed" },
  unpacked: { color: "magenta", label: "Unpacked" },
  replace: { color: "blue", label: "Replace" },
  packing: { color: "gold", label: "Packing" },
  missing: { color: "warning", label: "Missing" },
  done: { color: "success", label: "Done" },
  pack: { color: "", label: "Pack" },
  na: { color: "error", label: "N/A" },
  unpaid: { color: "magenta", label: "Unpaid" },
  paid: { color: "success", label: "Paid" },
  "partially paid": { color: "", label: "Partially paid" },
  "invoice not sent": { color: "", label: "Not sent" },
  "invoice sent": { color: "success", label: "Sent" },
  active: { color: "success", label: "Active" },
  inactive: { color: "", label: "Inactive" },
  expired: { color: "", label: "Expired" },
};

export const StatusTag = ({ status }) => {
  const { color, label } = statusMapper[status] || { color: "", label: status };
  return (
    <Tag color={color} style={{ margin: 0 }}>
      {label}
    </Tag>
  );
};
