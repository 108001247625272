import { useState, useEffect } from "react";
import { Table, Space, Typography, Input } from "antd";
import _ from "lodash";

import { MoneyCell, CustomerCell } from "../tablecells";
import { StatusTag } from "../tags/StatusTag";
import { PaymentSubmitForm } from "../PaymentSubmitForm";

export const PaymentAllocation = ({
  activeCustomer,
  items,
  setItems,
  rowSelection,
  selectedOrder,
}) => {
  const [sumBalanceDue, setSumBalanceDue] = useState(0);
  const [sumDiscount, setSumDiscount] = useState(0);
  const [sumAmountApplied, setSumAmountApplied] = useState(0);

  const columns = [
    {
      title: "Invoice No.",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "Invoice Date",
      dataIndex: "orderDate",
      key: "orderDate",
      render: (text) => {
        return new Date(text).toLocaleDateString("en-au");
      },
    },
    {
      title: "Customer",
      dataIndex: "customer",
      key: "customer",
      render: (_, record) => <CustomerCell customer={record.customer || {}} order={record} />,
    },
    {
      title: "Payment Status",
      dataIndex: "paymentStatus",
      key: "paymentStatus",
      render: (text) => <StatusTag status={text} />,
    },
    {
      title: "Invoice Total",
      dataIndex: "total",
      key: "total",
      ...MoneyCell,
    },
    {
      title: "Amount Paid",
      dataIndex: "balancePaid",
      key: "balancePaid",
      ...MoneyCell,
    },
    {
      title: "Outstanding Balance",
      dataIndex: "balanceDue",
      key: "balanceDue",
      ...MoneyCell,
    },
    {
      title: "Discount",
      dataIndex: "discount",
      key: "discount",
      render: (text, record) => (
        <Input
          type="number"
          min={0}
          onChange={(e) => {
            record.discount = e.target.value;
            record.amountApplied = record.balanceDue - e.target.value;

            let tempData = _.cloneDeep(items);
            tempData.splice(record.index, 1, {
              ...record,
              discount: e.target.value,
              amountApplied: String(record.balanceDue - e.target.value),
            });
            setItems(tempData);
          }}
        />
      ),
    },
    {
      title: "Amount applied",
      dataIndex: "amountApplied",
      key: "amountApplied",
      render: (text, record) => (
        <Input
          // type="number"
          min={0}
          value={record.amountApplied}
          onChange={(e) => {
            let tempData = _.cloneDeep(items);
            tempData.splice(record.index, 1, {
              ...record,
              amountApplied: String(e.target.value),
            });
            setItems(tempData);
          }}
        />
      ),
    },
  ];

  useEffect(() => {
    const sum = (propName) => {
      return _.sumBy(selectedOrder, (order) => {
        return Number(items[order.index][propName]);
      }).toFixed(2);
    };
    if (items) {
      if (rowSelection) {
        setSumDiscount(sum("discount"));
        setSumAmountApplied(sum("amountApplied"));
        setSumBalanceDue(sum("balanceDue"));
      }
    }
  }, [items, rowSelection, selectedOrder]);

  return (
    <>
      <PaymentSubmitForm
        activeCustomer={activeCustomer}
        selectedOrder={selectedOrder}
        dataSource={items}
        discountAmount={sumDiscount}
        totalApplied={sumAmountApplied}
      />
      <Typography.Title level={5} style={{ marginLeft: "16px", marginTop: "10px" }}>
        Showing {items.length} Payment Records
      </Typography.Title>
      <Table
        dataSource={items}
        columns={columns}
        rowSelection={{ ...rowSelection }}
        rowKey="id"
        size="large"
        pagination={false}
      />
      <Space
        style={{
          marginTop: "15px",
          display: "flex",
          justifyContent: "flex-end",
          padding: "16px",
        }}
      >
        <Space>
          <Typography.Text strong>Outstanding Balance:</Typography.Text>
          <Typography.Text>$ {sumBalanceDue}</Typography.Text>
          <Typography.Text strong style={{ marginLeft: "40px" }}>
            Discount:
          </Typography.Text>
          <Typography.Text>$ {sumDiscount}</Typography.Text>
          <Typography.Text strong style={{ marginLeft: "40px" }}>
            Total Amount applied:
          </Typography.Text>
          <Typography.Text>$ {sumAmountApplied}</Typography.Text>
        </Space>
      </Space>
    </>
  );
};
