import moment from "moment-timezone";
export const getTodayTomorrowTag = (orderDate) => {
  const datetime = moment(orderDate);
  const dateStr = datetime.format("DD/MM/YYYY");
  if (datetime.isSame(moment(), "day")) return { dateStr, tag: { text: "Today", color: "" } };
  if (datetime.isSame(moment().add({ days: 1 }), "day"))
    return { dateStr, tag: { text: "Tomorrow", color: "blue" } };

  return { dateStr, tag: null };
};
