import { gql } from "@apollo/client";

export const ProductsQuery = gql`
  query ProductsQuery($filters: ProductWhereInput, $take: Int, $skip: Int) {
    products(take: $take, skip: $skip, orderBy: { name_EN: asc }, where: $filters) {
      id
      productCode
      name
      name_EN
      productType
      options
      defaultUnit
      alternativeUnit
      displayUnit
      displayQuantity
      productVariants(where: { status: { equals: active } }, orderBy: { name: asc }) {
        id
        name
        name_EN
        name_ZH
        fullName
        fullName_EN
        fullName_ZH
        basePrice
        quantity
        defaultMargin
      }
      category {
        id
        name
      }
      updatedAt
      createdAt
    }
    productsCount(where: $filters)
  }
`;

export const AllProductsOldAndNewQuery = gql`
  query AllProductsOldAndNewQuery {
    products(orderBy: { name_EN: asc }) {
      id
      productCode
      name
      name_EN
      productVariants(orderBy: { name: asc }) {
        id
        name
        basePrice
        quantity
        defaultMargin
        displayName_EN
      }
      category {
        id
        name
      }
      updatedAt
      createdAt
    }
  }
`;

export const ProductsBaseQuery = gql`
  query ProductsBaseQuery {
    products(where: { version: { equals: 2 } }, orderBy: { name_EN: asc }) {
      id
      productCode
      name
      name_EN
      productVariants(where: { status: { equals: active } }, orderBy: { name_EN: asc }) {
        id
        name
        basePrice
        sellingPrice
        quantity
        defaultMargin
        displayName_EN
      }
      category {
        id
        name
      }
      updatedAt
      createdAt
    }
  }
`;

export const SingleProductQuery = gql`
  query SingleProductQuery($id: ID!) {
    product(where: { id: $id }) {
      name
      name_ZH
      name_EN
      productVariants(orderBy: { isDefaultUnit: desc }, where: { status: { equals: active } }) {
        id
        isDefaultUnit
        name
        name_EN
        name_ZH
        convertToDefaultUnit
      }
      category {
        id
        name
      }
    }
  }
`;

export const updateProductMutation = gql`
  mutation updateProductMutation($id: ID!, $data: ProductUpdateInput!) {
    updateProduct(where: { id: $id }, data: $data) {
      id
      name
    }
  }
`;

export const deleteProductVariantsMutation = gql`
  mutation deleteProductVariantsMutation($where: [ProductVariantWhereUniqueInput!]!) {
    deleteProductVariants(where: $where) {
      id
      name
    }
  }
`;

export const updateProductVariantsMutation = gql`
  mutation updateProductVariantsMutation($data: [ProductVariantUpdateArgs!]!) {
    updateProductVariants(data: $data) {
      id
      fullName
    }
  }
`;

export const UpdateProductsMutation = gql`
  mutation UpdateProductsMutation($data: [ProductUpdateArgs!]!) {
    updateProducts(data: $data) {
      id
      name
      category {
        name
      }
    }
  }
`;

export const CreateProductMutation = gql`
  mutation CreateProductMutation($data: ProductCreateInput!) {
    createProduct(data: $data) {
      id
      name
    }
  }
`;

export const UpdateProductVariantsMutation = gql`
  mutation UpdateProductVariantsMutation($data: [ProductVariantUpdateArgs!]!) {
    updateProductVariants(data: $data) {
      id
    }
  }
`;

export const SearchProductsQueryString = gql`
  query SearchProductsQueryString($q: String) {
    products(where: { name: { contains: $q, mode: insensitive }, status: { equals: active } }) {
      name
      id
    }
  }
`;
