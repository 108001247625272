import { useState, useEffect } from "react";
import { useQuery } from "@apollo/client";
import { PageHeader, Button, Table } from "antd";
import { Link } from "react-router-dom";
import { PageContainer } from "../components/atoms/PageContainer";
import { ROUTES } from "../constants";
import { PaymentBatchesQuery } from "../graphql/paymentBatches.graphql";
import { PageContentContainer } from "../components/atoms/PageContentContainer";
import { ResourceToolbar } from "../components/ResourceToolbar";
import moment from "moment";
import { formatMoney } from "../utils";

const pageSize = 50;

export const Payments = () => {
  const [currentPage, setCurrentPage] = useState(1);
  const [filters, setFilters] = useState({
    amount: { not: { equals: "0.00" } },
  });
  const { data, loading, refetch, networkStatus } = useQuery(PaymentBatchesQuery, {
    variables: { take: pageSize, skip: (currentPage - 1) * pageSize, where: filters },
    fetchPolicy: "cache-and-network",
    notifyOnNetworkStatusChange: true,
  });

  const [paymentBatches, setPaymentBatches] = useState([]);

  const columns = [
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "Payment Type",
      dataIndex: "paymentType",
      key: "paymentType",
    },
    {
      title: "Date",
      dataIndex: "receivedDate",
      key: "receivedDate",
      render: (receivedDate) => moment(receivedDate).format("DD/MM/YYYY"),
    },
    {
      title: "Customer",
      dataIndex: ["customer", "name"],
      key: "customer",
    },
    {
      title: "Notes",
      dataIndex: "notes",
      key: "notes",
    },
    {
      title: "Amount",
      dataIndex: "amount",
      key: "amount",
      align: "right",
      render: formatMoney,
    },
    {
      title: "Action",
      dataIndex: "action",
      key: "action",
      align: "right",
      render: (_, record) => (
        <Link to={`/payments/${record.id}`}>
          <Button>Edit</Button>
        </Link>
      ),
    },
  ];

  useEffect(() => {
    if (data?.paymentBatches) {
      setPaymentBatches(data.paymentBatches);
    }
  }, [data, loading]);

  const handleRefresh = () => {
    console.log("Refreshing...");
    refetch();
  };

  const handleSearch = (value) => {
    setFilters((f) => ({
      ...f,
      customer: { name: { contains: value, mode: "insensitive" } },
    }));
  };

  return (
    <PageContainer>
      <PageHeader
        ghost={true}
        title="Payments"
        subTitle={`Showing ${data?.paymentBatchesCount} payments`}
        extra={[
          <Button key="1" type="primary">
            <Link to={ROUTES.paymentsNew}>New payment</Link>
          </Button>,
        ]}
      />
      <PageContentContainer>
        <ResourceToolbar search={{ onSearch: handleSearch }} onRefresh={handleRefresh} />

        <Table
          dataSource={paymentBatches}
          columns={columns}
          rowKey="id"
          size="small"
          loading={loading || networkStatus === "refetch"}
          pagination={{
            total: data?.paymentBatchesCount,
            pageSize: pageSize,
            showSizeChanger: false,
            onChange: (currentPage) => {
              setCurrentPage(currentPage);
            },
          }}
        />
      </PageContentContainer>
    </PageContainer>
  );
};
