import { Card, Divider, Typography, Row } from "antd";
import { useEffect, useState } from "react";
import { useAllCustomersOptions } from "../../hooks/useAllCustomersOptions";
import { ResourceSearchComplete } from "../ResourceSearchComplete";
import { CustomerSelectCard } from "../CustomerSelectCard";
export const CustomerSelection = ({ onCustomerSelect }) => {
  const { options } = useAllCustomersOptions();
  const [frequent, setFrequent] = useState([]);

  useEffect(() => {
    const frequentCustomers = options.filter((item) => item.isFrequent === true);
    setFrequent(frequentCustomers);
  }, [options]);

  return (
    <Card title="Select customer">
      <ResourceSearchComplete
        options={options}
        placeholder="Search customer"
        inputWidth={"100%"}
        onSelect={onCustomerSelect}
        width="100%"
      />
      <Divider />
      <Typography.Title level={5}>Frequently used</Typography.Title>
      <Row gutter={[16, 8]}>
        {frequent.map((customer) => {
          return (
            <CustomerSelectCard key={customer.id} customer={customer} onSelect={onCustomerSelect} />
          );
        })}
      </Row>
    </Card>
  );
};
