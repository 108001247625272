import { useState, useEffect } from "react";
import { useMutation, useQuery } from "@apollo/client";
import { Button, Tabs, Row, Col, Form, Typography, notification, Select, Card } from "antd";
import { useNavigate } from "react-router-dom";

import { CategoriesWithProductsForBulkEditV2 } from "@/graphql/categories.graphql";
import { UpdateProductsMutation } from "@/graphql/products.graphql";
import { AffixedPageHeader } from "@/components/atoms/AffixedPageHeader";
import { useCategories } from "@/hooks/useCategories";

const { TabPane } = Tabs;

export const ChangeCategories = () => {
  const [cats, setCats] = useState([]);
  const { data, loading: fetchLoading } = useQuery(CategoriesWithProductsForBulkEditV2, {
    fetchPolicy: "cache-first",
  });
  const [updateProducts, { loading }] = useMutation(UpdateProductsMutation);
  const [form] = Form.useForm();
  const navigate = useNavigate();
  const [changedIds, setChangedIds] = useState(new Set());
  const { options: categoryOptions } = useCategories();

  useEffect(() => {
    if (data?.categories) {
      setCats(data.categories);
      const temp = {};
      data.categories.forEach((cat) => {
        cat.products.forEach((product) => {
          temp[product.id] = {
            ...product,
            category: { connect: { id: cat.id } },
          };
        });
      });

      form.setFieldsValue(temp);
    }
  }, [data, form]);

  const handleFieldsChange = (changedFields) => {
    const first = changedFields[0];
    const id = first.name[0];
    // changedIds is new Set () here. Set is used here to store unique values of any type
    setChangedIds(changedIds.add(id));
  };

  const handleSave = async () => {
    const values = form.getFieldsValue();

    const payload = Array.from(changedIds).map((changedId) => {
      return {
        where: { id: changedId },
        data: { ...values[changedId] },
      };
    });
    console.log("payload: ", payload);

    const res = await updateProducts({
      variables: { data: payload },
      refetchQueries: [{ query: CategoriesWithProductsForBulkEditV2 }],
    });

    if (res.data) {
      notification.success({ message: `${payload.length} products updated.` });
      navigate(-1);
    }
  };

  return (
    <>
      <AffixedPageHeader
        title="Edit Products V2"
        extra={[
          <Button key="1" type="primary" onClick={handleSave} loading={loading}>
            Save
          </Button>,
        ]}
      />
      <Card loading={fetchLoading}>
        <Form form={form} onFieldsChange={handleFieldsChange}>
          <Tabs defaultActiveKey={0}>
            {cats.map((cat, index) => {
              return (
                <TabPane tab={cat.name} key={index}>
                  <Row
                    gutter={8}
                    style={{
                      padding: "4px 8px",
                      borderBottom: "0.5px solid lightgrey",
                    }}
                  >
                    <Col span={8}>
                      <Typography.Text strong>Product</Typography.Text>
                    </Col>
                    <Col span={6}>
                      <Typography.Text strong>Category</Typography.Text>
                    </Col>
                  </Row>
                  {cat.products.map((product) => {
                    return (
                      <div
                        style={{
                          padding: "4px",
                          borderTop: "1px solid grey",
                        }}
                      >
                        <Row
                          key={product.id}
                          style={{ background: changedIds[product.id] ? "#eaeaea" : "none" }}
                        >
                          <Col span={8}>
                            <Typography.Text style={{ marginBottom: 0 }}>
                              {product.name}
                            </Typography.Text>
                          </Col>
                          <Col span={4} style={{ border: "0.35px solid lightgrey" }}>
                            <Form.Item name={[product.id, "category", "connect", "id"]} noStyle>
                              <Select
                                style={{ width: "100%" }}
                                bordered={false}
                                options={categoryOptions}
                              />
                            </Form.Item>
                          </Col>
                        </Row>
                      </div>
                    );
                  })}
                </TabPane>
              );
            })}
          </Tabs>
        </Form>
      </Card>
    </>
  );
};
