import { Link } from "react-router-dom";
import { LinkOutlined } from "@ant-design/icons";
import { Space, Typography } from "antd";

export const CustomerCell = ({ customer, order, isCreditNote = false }) => {
  const { id, name } = customer;
  return id ? (
    <Space direction="vertical">
      <Space>
        <Typography.Title level={4} style={{ marginBottom: 0 }}>
          <Link to={isCreditNote ? `/credit-notes/${order.id}` : `/orders/${order.id}`}>
            {name}
          </Link>
        </Typography.Title>
        <Link to={`/customers/${id}`}>
          <LinkOutlined />
        </Link>
      </Space>
      {order.organisation && <span>{order.organisation?.name}</span>}
    </Space>
  ) : (
    ""
  );
};
