import { useLazyQuery } from "@apollo/client";
import { Card, Modal, Divider, Typography, Button, Space } from "antd";
import { useEffect, useState } from "react";

import { StatusTag } from "../tags/StatusTag";
import { LineItemsTable } from "../LineItemsTable";

import { SingleOrderQuery } from "../../graphql/orders.graphql";
import { OrderTotalSummary } from "../OrderTotalSummary";

import styled from "styled-components";
import { MoveOrderToTheOtherRound } from "./MoveOrderToTheOtherRound";
import { SearchOutlined } from "@ant-design/icons";

const Title = styled.h4`
  font-weight: bold;
  margin: 0;
`;

export const OrderDisplayModal = ({ order, trucks, truckName, setTrucks, setTempData }) => {
  const [visible, setVisible] = useState(false);
  const [getSingleOrderByOrderID, { data }] = useLazyQuery(SingleOrderQuery, {
    fetchPolicy: "network-only",
  });
  const [singleOrder, setSingleOrder] = useState({});

  useEffect(() => {
    if (data) {
      setSingleOrder(data?.order);
    }
  }, [data]);

  const handleModal = () => {
    setVisible(true);
    getSingleOrderByOrderID({ variables: { id: order.id } });
  };
  return (
    <>
      <Title onClick={handleModal}>{order?.content.customer.name}</Title>
      <Space>
        <Typography.Text type="secondary" onClick={handleModal}>
          #{order?.content.name}
        </Typography.Text>
        <div>
          <Button type="text" icon={<SearchOutlined />} onClick={handleModal} />
          <MoveOrderToTheOtherRound
            trucks={trucks}
            truckName={truckName}
            order={order}
            setTrucks={setTrucks}
            setTempData={setTempData}
          />
        </div>
      </Space>
      <StatusTag status={order?.content.fulfillmentStatus} />
      <Modal
        title={` #${order?.content.name} · ${order?.content.customer.name}`}
        centered
        visible={visible}
        footer={null}
        onCancel={() => setVisible(false)}
        width={1000}
        bodyStyle={{ height: 800 }}
      >
        <Card style={{ width: "100%", height: "100%" }}>
          <LineItemsTable items={singleOrder.lineItems} />
          <Divider />
          <OrderTotalSummary
            total={singleOrder.total}
            totalWithoutGst={singleOrder.totalWithoutGst}
            gstAmount={singleOrder.gstAmount}
            discountAmount={singleOrder.discountAmount}
          />
        </Card>
      </Modal>
    </>
  );
};
