import { Affix, PageHeader } from "antd";
import { useState } from "react";

export const AffixedPageHeader = ({ title, extra }) => {
  const [headerbg, setHeaderbg] = useState("none");
  return (
    <Affix offsetTop={56} onChange={(affixed) => setHeaderbg(affixed ? "#fff" : "none")}>
      <PageHeader
        title={title}
        style={{ background: headerbg, paddingLeft: 24, paddingRight: 24 }}
        extra={extra}
      />
    </Affix>
  );
};
