import React from "react";
import styled from "styled-components";
import { DraggableCustomerProps } from ".";
import { DraggableCustomerCard } from "./DraggableCustomerCard";

import { Droppable } from "react-beautiful-dnd";

const DroppableContainer = styled.div<{ direction?: string; showBorder?: boolean }>`
  display: flex;
  flex-direction: ${(props) => (props.direction === "vertical" ? "column" : "row")};
  column-gap: 4px;
  row-gap: 4px;
  min-height: ${(props) => (props.direction === "vertical" ? "300px" : "116px")};
  padding: 4px;
  border: ${(props) => (props.showBorder ? "1px solid lightgrey" : "none")};
  overflow: auto;
`;

export type DroppableListProps = {
  direction?: "horizontal" | "vertical";
  data: DraggableCustomerProps[];
  droppableId: string;
  isDropDisabled?: boolean;
  header?: React.ReactNode;
  draggingOverHighlight?: boolean;
  showBorder?: boolean;
};

export const DroppableTruckColumn = ({
  direction = "vertical",
  data,
  droppableId,
  isDropDisabled,
  header,
  draggingOverHighlight = true,
  showBorder = true,
  ...restProps
}: DroppableListProps) => {
  return (
    <Droppable droppableId={droppableId} direction={direction} {...restProps}>
      {(provided, snapshot) => {
        return (
          <DroppableContainer
            direction={direction}
            showBorder={showBorder}
            ref={provided.innerRef}
            {...provided.droppableProps}
          >
            {header && header}
            {data &&
              data.map((customer: DraggableCustomerProps, index: number) => {
                return (
                  <DraggableCustomerCard customer={customer} index={index} key={customer.id} />
                );
              })}
            {provided.placeholder}
          </DroppableContainer>
        );
      }}
    </Droppable>
  );
};
