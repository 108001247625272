import { useContext, useEffect, useState } from "react";
import { Popover, InputNumber, Form, Input, Space, Tooltip } from "antd";
import { LockOutlined } from "@ant-design/icons";
import { OrderFormContext } from "../../../contexts/OrderFormContext";
import styled from "styled-components";

const UnitPriceFieldWrapper = styled.div`
  display: flex;
  align-items: center;
`;

const AddonBefore = ({ usingQuote, existingBasePrice, margin }) => {
  return usingQuote ? (
    <LockOutlined style={{ color: "red" }} />
  ) : (
    <Tooltip title={`Base: ${existingBasePrice}, Markup: ${margin.toFixed(2)}`}>
      {usingQuote}
      <span
        style={{
          color: existingBasePrice ? "black" : "red",
          border: "1px solid #d9d9d9",
          background: margin ? "none" : "yellow",
          padding: "4px 8px",
        }}
      >
        $
      </span>
    </Tooltip>
  );
};
export const UnitPriceFieldV2 = ({ index, shouldUpdate = true }) => {
  const [visible, setVisible] = useState(false);
  const { form } = useContext(OrderFormContext);
  const [margin, setMargin] = useState(0);
  const [usingQuote, setUsingQuote] = useState(false);
  const [existingBasePrice, setExistingBasePrice] = useState(null);

  useEffect(() => {
    setExistingBasePrice(parseFloat(form.getFieldValue(["lineItems", index, "unitCost"])));
    setMargin(parseFloat(form.getFieldValue(["lineItems", index, "margin"])) || 0);
    setUsingQuote(form.getFieldValue(["lineItems", index, "isQuotationPrice"]));
  }, [form, index]);

  const handleChange = (value) => {
    const markup = (parseFloat(value) - existingBasePrice) / existingBasePrice;
    setMargin(markup);
    form.setFields([{ name: ["lineItems", index, "margin"], value: markup }]);
  };

  const handleVisibleChange = (expectedVisibility) => {
    if (!expectedVisibility) return setVisible(expectedVisibility);
    if (expectedVisibility && !existingBasePrice) {
      setVisible(expectedVisibility);
    }
    return;
  };

  const handleFocus = (e) => {
    e.target.select();
  };

  return (
    <Popover
      content={
        <Space>
          <span>Cost</span>
          <Form.Item name={[index, "unitCost"]} shouldUpdate={shouldUpdate} noStyle>
            <InputNumber min={0} precision={2} stringMode={true} onFocus={handleFocus} />
          </Form.Item>
        </Space>
      }
      trigger="click"
      visible={visible}
      onVisibleChange={handleVisibleChange}
      placement="bottom"
    >
      <UnitPriceFieldWrapper>
        <AddonBefore
          usingQuote={usingQuote}
          existingBasePrice={existingBasePrice}
          margin={margin}
        />
        <Form.Item name={[index, "unitPrice"]} shouldUpdate={shouldUpdate} noStyle>
          <InputNumber
            precision={2}
            style={{ textAlign: "right" }}
            onFocus={handleFocus}
            onChange={handleChange}
            // addonBefore={addonBefore}
          />
        </Form.Item>
      </UnitPriceFieldWrapper>
      <Form.Item name={[index, "margin"]} hidden>
        <Input />
      </Form.Item>
      <Form.Item name={[index, "usingQuote"]} hidden>
        <Input />
      </Form.Item>
    </Popover>
  );
};
