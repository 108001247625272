import { useState } from "react";
import { Modal, Button, Typography } from "antd";

export const DeleteWithModalConfirm = ({
  onConfirm,
  label = "Delete",
  modalTitle = "Are you sure?",
  modalMessage = "You are about to delete this item",
}) => {
  const [visible, setVisile] = useState(false);

  const handleDeleteClck = () => {
    setVisile(true);
  };

  const handleCancel = () => {
    setVisile(false);
  };
  return (
    <>
      <Modal
        visible={visible}
        title={modalTitle}
        onCancel={handleCancel}
        onOk={onConfirm}
      >
        <Typography.Text>{modalMessage}</Typography.Text>
      </Modal>
      <Button onClick={handleDeleteClck} danger>
        {label}
      </Button>
    </>
  );
};
