import { gql } from "@apollo/client";

export const QuotationsQuery = gql`
  query QuotationsQuery($take: Int, $skip: Int, $where: QuotationWhereInput) {
    quotations(take: $take, skip: $skip, where: $where) {
      id
      name
      sequence
      status
      start
      end
      createdAt
      isCancelled
      lineItems {
        id
        name
        product {
          name
        }
        productVariant {
          name
        }
        unitPrice
      }
      lineItemsCount
      customers {
        id
        name
      }
    }
    quotationsCount(where: $where)
  }
`;

export const SingleCustomerForQuotationQuery = gql`
  query SingleCustomerForQuotationQuery($id: ID!) {
    customer(where: { id: $id }) {
      id
      name
      email
      phone
      billingAddress
      deliveryAddress
      notes
      accountBalance
      paymentTerms
      paymentMethod
      firstName
      lastName
      fixedMargin
      franchise {
        id
        name
      }
    }
  }
`;

export const SingleQuotationQuery = gql`
  query SingleQuotationQuery($id: ID!) {
    quotation(where: { id: $id }) {
      id
      name
      sequence
      status
      start
      end
      isCancelled
      lineItemsCount
      createdAt
      updatedAt
      confirmedAt
      lineItems(orderBy: { sortingIndex: asc }) {
        id
        name
        unitPrice
        quantity
        packagingUnit
        sortingIndex
        isOpenItem
        productVariant {
          id
          name
          name_EN
        }
        product {
          id
          name
          name_EN
        }
      }
      customers {
        id
        name
        email
        phone
        billingAddress
        fixedMargin
        deliveryAddress
        notes
        franchise {
          id
          name
        }
      }
    }
  }
`;

export const CreateQuotationMutation = gql`
  mutation create_quotation($quotation: QuotationCreateInput!) {
    createQuotation(data: $quotation) {
      id
      name
      customers {
        name
      }
    }
  }
`;

export const UpdateQuotationAndLineItemsMutation = gql`
  mutation UpdateQuotationAndLineItemsMutation(
    $id: ID!
    $data: QuotationUpdateInput!
    $deletedIds: [LineItemWhereUniqueInput!]!
    $lineItemsUpdateData: [LineItemUpdateArgs!]!
  ) {
    updateQuotation(where: { id: $id }, data: $data) {
      id
    }
    deleteLineItems(where: $deletedIds) {
      id
      name
    }
    updateLineItems(data: $lineItemsUpdateData) {
      id
      name
    }
  }
`;

export const SoftDeleteQuotationMutation = gql`
  mutation SoftDeleteQuotationMutation($id: ID!) {
    updateQuotation(where: { id: $id }, data: { status: cancelled, isCancelled: true }) {
      id
      status
    }
  }
`;

export const UpdateQuotationStatusMutation = gql`
  mutation UpdateQuotationStatusMutation($id: ID!, $status: QuotationStatusType!) {
    updateQuotation(where: { id: $id }, data: { status: $status }) {
      id
      status
    }
  }
`;

// export const QuotationsBaseQuery = gql`
//   query QuotationsBaseQuery($filters: QuotationWhereInput!) {
//     quotations(where: $filters) {
//       id
//       name
//       start
//       end
//       status
//       customers {
//         id
//         name
//       }
//       lineItems {
//         id
//         name
//         unitPrice
//       }
//     }
//   }
// `;
