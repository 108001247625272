import { Space, Avatar, Typography, Card, Col, Tag } from "antd";
import { UserOutlined, ShopOutlined } from "@ant-design/icons";

export const CustomerSelectCard = ({ customer, onSelect }) => {
  const { id, value: name, address, franchise, salesperson } = customer;

  return (
    <Col span={6}>
      <Card onClick={() => onSelect(name, { id })} hoverable>
        <Space direction="vertical">
          <Space direction="vertical">
            <Space>
              {franchise && <Tag color="blue">{franchise}</Tag>}
              {salesperson && (
                <Space>
                  <Tag>
                    <UserOutlined /> {salesperson}
                  </Tag>
                </Space>
              )}
            </Space>
            <Space>
              <Avatar>{name.charAt(0).toUpperCase()}</Avatar>
              <Typography.Title style={{ margin: 0 }} level={5}>
                {name}
              </Typography.Title>
            </Space>
          </Space>
          <Space>
            <ShopOutlined />
            <Typography.Text>{address}</Typography.Text>
          </Space>
        </Space>
      </Card>
    </Col>
  );
};
