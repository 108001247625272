import { Form, AutoComplete } from "antd";
import { SAMPLE_COMMENTS_OPTIONS } from "../../../constants";

export const CommentsFieldV2 = ({ index }) => {
  return (
    <>
      <Form.Item name={[index, "notes"]} style={{ marginBottom: 5 }}>
        <AutoComplete
          bordered={false}
          options={SAMPLE_COMMENTS_OPTIONS}
          style={{ paddingLeft: 0 }}
          filterOption={(inputValue, option) =>
            option.value.toUpperCase().indexOf(inputValue.toUpperCase()) !== -1
          }
          placeholder="Add comments"
        />
      </Form.Item>
    </>
  );
};
