import { createContext, useEffect, useState } from "react";
import { useLazyQuery } from "@apollo/client";
import { SingleCustomerContextForOrderQuery } from "../graphql/customers.graphql";
import moment from "moment-timezone";

const initialContextValues = {
  customer: null,
  fetchCustomer: () => {},
};

const defaultQuotationFilters = {
  status: { equals: "confirmed" },
  start: { lte: moment().toISOString() },
  end: { gte: moment().toISOString() },
};

const CustomerContext = createContext(initialContextValues);

const CustomerProvider = ({ children }) => {
  const [fetchCustomer, { data }] = useLazyQuery(SingleCustomerContextForOrderQuery, {
    fetchPolicy: "network-only", // Used for first execution
  });
  const [customer, setCustomer] = useState(null);

  useEffect(() => {
    if (data && data.customer) {
      setCustomer(data.customer);
    }
  }, [data]);

  const resetCustomer = () => setCustomer(null);

  return (
    <CustomerContext.Provider value={{ customer, fetchCustomer, resetCustomer }}>
      {children}
    </CustomerContext.Provider>
  );
};

export { CustomerProvider, CustomerContext };
