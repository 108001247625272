import { useState, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { useQuery, useMutation } from "@apollo/client";
import moment from "moment";
import { Badge, Button, notification, PageHeader, Spin, Modal } from "antd";
import _ from "lodash";

import UnsavedReminder from "@/components/atoms/UnsavedReminder";
import { useCallbackPrompt } from "@/hooks/useCallbackPrompt";
import { PageContainer } from "@/components/atoms/PageContainer";
import { QuotationForm } from "@/components/QuotationForm";
import { prepareQuotationFormValues } from "@/utils/prepareQuotationFormValues";

import {
  SingleQuotationQuery,
  UpdateQuotationAndLineItemsMutation,
} from "@/graphql/quotations.graphql";

export const EditQuotation = () => {
  const [initalIds, setInitalIds] = useState([]);
  const [quotationFormValue, setQuotationFormValue] = useState({});
  const [customer, setCustomer] = useState({});
  const [reminderOpen, setReminderOpen] = useState(false);
  const params = useParams();
  const navigate = useNavigate();

  // unsavedReminder
  const [showModal, setShowModal] = useState(false);
  const [showPrompt, confirmNavigation, cancelNavigation] = useCallbackPrompt(true);
  const [isSaved, setIsSaved] = useState(false);

  const { data } = useQuery(SingleQuotationQuery, {
    variables: { id: params.id },
    fetchPolicy: "cache-first",
  });
  const [mutate, { loading }] = useMutation(UpdateQuotationAndLineItemsMutation);

  useEffect(() => {
    if (data && data.quotation) {
      setInitalIds(
        data.quotation.lineItems.map((item) => {
          return { id: item.id };
        })
      );
      const dateRange = [];
      dateRange.push(moment(data.quotation.start), moment(data.quotation.end));
      const formValue = {
        ...data.quotation,
        dateRange: dateRange,
      };
      setQuotationFormValue(formValue);

      console.log("data.quotation.customers: ", data.quotation.customers);
      setCustomer(data.quotation.customers[0]);
    }
  }, [data]);

  useEffect(() => {
    if (isSaved) {
      setShowModal(false);
    } else setShowModal(showPrompt);
  }, [showPrompt, isSaved]);

  const handleSave = async (values) => {
    setIsSaved(true);
    const tempData = prepareQuotationFormValues(values, customer);
    const result = tempData.lineItems.reduce(
      (prev, current) => {
        const id = current.id;
        delete current.id;
        if (id) {
          prev.updatedItems.push({ where: { id: id }, data: current });
          prev.deletedIds.splice(
            prev.deletedIds.findIndex((item) => item.id === id),
            1
          );
        } else {
          prev.newItems.push(current);
        }
        return prev;
      },
      {
        deletedIds: _.cloneDeep(initalIds),
        updatedItems: [],
        newItems: [],
      }
    );
    const res = await mutate({
      variables: {
        id: params.id,
        data: { ...tempData, lineItems: { create: result.newItems } },
        deletedIds: result.deletedIds,
        lineItemsUpdateData: result.updatedItems,
      },
      refetchQueries: [{ query: SingleQuotationQuery, variables: { id: params.id } }],
    });
    if (res.data) {
      notification["success"]({
        message: `Quotation #${data.quotation.name} updated.`,
      });
      navigate(`/quotations/${params.id}`);
      confirmNavigation();
    }
  };

  return (
    <PageContainer>
      <UnsavedReminder
        showModal={showModal}
        confirmNavigation={confirmNavigation}
        cancelNavigation={cancelNavigation}
      />
      <Modal
        visible={reminderOpen}
        onOk={() => navigate(-1)}
        onCancel={() => setReminderOpen(false)}
      >
        Unsaved changes, are you sure you want to go back?
      </Modal>
      <PageHeader
        title={`${customer?.name}`}
        subTitle={`#${data?.quotation?.name}`}
        extra={[
          <Badge key="packer001" dot={true} status="success">
            <Button size="small">Connected</Button>
          </Badge>,
        ]}
      />
      {loading ? (
        <Spin />
      ) : (
        <QuotationForm
          onSave={handleSave}
          customer={customer}
          setCustomer={setCustomer}
          quotation={quotationFormValue}
        />
      )}
    </PageContainer>
  );
};
