import { useEffect, useState } from "react";
import styled from "styled-components";
import { Input, Space, Button, DatePicker, AutoComplete } from "antd";
import { SyncOutlined } from "@ant-design/icons";
import moment from "moment";

import { useQuery } from "@apollo/client";
import { ProductsBaseQuery } from "../graphql/products.graphql";

const ToolbarWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  padding: 16px 12px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.06);
  margin-bottom: 8px;
`;

export const ResourceToolbar = ({ search, dateRange, onRefresh, isAutoComplete = false }) => {
  const { data } = useQuery(ProductsBaseQuery, { fetchPolicy: "cache-first" });
  const [products, setProducts] = useState([]);
  useEffect(() => {
    if (data?.products) {
      setProducts(data.products.map((product) => ({ label: product.name, value: product.name })));
    }
  }, [data]);
  const handleSelect = (_, option) => {
    search.onSearch(option.label);
  };

  return (
    <ToolbarWrapper>
      <Space>
        {isAutoComplete ? (
          <AutoComplete
            options={products}
            onSelect={handleSelect}
            filterOption={(inputValue, option) =>
              option.value.toUpperCase().indexOf(inputValue.toUpperCase()) !== -1
            }
            style={{ width: 320 }}
            dropdownMatchSelectWidth={420}
          >
            <Input.Search
              placeholder="Search product name"
              enterButton
              onSearch={search?.onSearch}
              allowClear
            />
          </AutoComplete>
        ) : (
          <Input.Search
            placeholder="Search name or code"
            defaultValue={search?.value || ""}
            enterButton
            allowClear
            onSearch={search?.onSearch}
            style={{ width: 320 }}
          />
        )}

        {dateRange && (
          <DatePicker.RangePicker
            defaultValue={dateRange.range || [moment(), null]}
            allowEmpty={[true, true]}
            ranges={{
              Today: [moment(), moment()],
              Tomorrow: [moment().add({ days: 1 }), moment().add({ days: 1 })],
              "This Month": [moment().startOf("month"), moment().endOf("month")],
            }}
            onChange={dateRange.onRangeChange}
          />
        )}
      </Space>
      {onRefresh && (
        <Space>
          <Space size={0}>
            <Button icon={<SyncOutlined />} onClick={onRefresh}>
              Refresh
            </Button>
          </Space>
        </Space>
      )}
    </ToolbarWrapper>
  );
};
