import { DeleteOutlined } from "@ant-design/icons";
import { Button } from "antd";
import { useContext } from "react";
import { OrderFormContext } from "../../../contexts/OrderFormContext";
import { FormListContext } from "../FormListContext";

export const RemoveButton = ({ index }) => {
  const { remove, setItemsCount } = useContext(FormListContext);
  const { form } = useContext(OrderFormContext);
  return (
    <Button
      danger
      shape="circle"
      size="small"
      icon={<DeleteOutlined />}
      onClick={() => {
        remove(index);
        if (form.getFieldValue(["lineItems", index, "name"])) {
          setItemsCount((count) => count - 1);
        }
      }}
    />
  );
};
