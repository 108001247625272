import { useContext } from "react";
import { Form, AutoComplete } from "antd";
import { ProductsContext } from "../../../contexts/ProductsContext";
import { OrderFormContext } from "../../../contexts/OrderFormContext";
import { getUnitPrice } from "../../../utils";
import { FormListContext } from "../FormListContext";

export const ProductNameSearchV2 = ({ index }) => {
  const { variants } = useContext(ProductsContext);
  const { form } = useContext(OrderFormContext);
  const { add, setItemsCount } = useContext(FormListContext);

  const handleProductSelect = (_, option) => {
    const selectedVariant = option.variant;
    const selectedProduct = option.variant.product;

    const unitPrice = getUnitPrice({ productVariant: selectedVariant, pickingStatus: "pack" });

    const lineItem = {
      name: selectedProduct.name,
      packagingUnit: selectedVariant.name,
      productVariantId: selectedVariant.id,
      productVariantName: selectedVariant.name,
      product: selectedProduct,
      unitPrice: unitPrice,
      margin: selectedVariant.defaultMargin,
      quotedPrice: null,
      quantity: "1",
      pickingStatus: "pack",
      unitCost: selectedVariant.basePrice,
      isOpenItem: false,
    };

    form.setFields([{ name: ["lineItems", index], value: lineItem }]);
    add();
    setItemsCount((count) => count + 1);
  };
  return (
    <Form.Item name={[index, "name"]} noStyle valuePropName="value">
      <AutoComplete
        options={variants}
        bordered={false}
        onSelect={handleProductSelect}
        filterOption={(inputValue, option) =>
          option.value.toUpperCase().indexOf(inputValue.toUpperCase()) !== -1
        }
        placeholder="Type to search product..."
        style={{ width: 320 }}
        allowClear
      />
    </Form.Item>
  );
};
