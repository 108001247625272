import { VendorsMap } from "@/constants";
import { Space } from "antd";

export const VendorInfo = ({ vendor }) => {
  if (!vendor) return null;
  return (
    <Space align="center">
      <img
        style={{
          height: "32px",
          width: "auto",
          objectFit: "cover",
        }}
        alt="logo"
        src={VendorsMap[vendor].imgSrc}
      />
      <h3>{VendorsMap[vendor].label}</h3>
    </Space>
  );
};
