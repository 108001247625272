import { Page, View, Document, Font } from "@react-pdf/renderer";

import { Typography, PageNumber, Stack, HorizontalLine } from "./utilities";

Font.register({
  family: "Microsoft YaHei",
  src: "/fonts/chinese.msyh.ttf",
});

let columns = [
  {
    title: "Category",
    key: "category",
    dataKey: "category",
    span: 6,
    align: "left",
  },

  {
    title: "Product",
    key: "product",
    dataKey: "displayName_EN",
    span: 8,
    align: "left",
  },
  {
    title: "Unit",
    key: "unit",
    dataKey: "unitName",
    span: 4,
    align: "left",
  },
  {
    title: "Sold",
    dataKey: "sold",
    key: "sold",
    span: 3,
    align: "right",
    render: (value) => value ?? <Typography sx={{ color: "lightgrey" }}>N/A</Typography>,
  },
  {
    title: "On hand",
    dataKey: "quantity",
    key: "quantity",
    span: 3,
    align: "right",
    render: (value) => value ?? <Typography sx={{ color: "lightgrey" }}>N/A</Typography>,
  },
];

export const SalesReportPDF = ({ data, reportType, dateString }) => {
  if (reportType === "soldOnly") {
    columns.splice(4, 1);
    columns = columns.map((item) => {
      return { ...item, span: 6 };
    });
  }

  return (
    <Document>
      <Page
        size="A4"
        style={{
          paddingTop: 24,
          paddingBottom: 32,
          paddingHorizontal: 24,
          backgroundColor: "#fff",
          fontFamily: "Microsoft YaHei",
        }}
      >
        {/* pdf title */}
        <Stack
          sx={{
            width: "100%",
            alignItems: "flex-end",
            marginBottom: 24,
          }}
        >
          <Typography type="header" sx={{ fontSize: "16px", textAlign: "right" }}>
            Sales Report
          </Typography>
          <Typography sx={{ textAlign: "right" }}>Report date: {dateString}</Typography>
        </Stack>
        {/* pdf body */}
        <Stack sx={{ width: "100%" }}>
          {Object.entries(data).map((item, index) => {
            return (
              <Stack key={index} sx={{ width: "100%", marginBottom: "30px" }}>
                <HorizontalLine sx={{ marginBottom: 8 }} />

                <Typography type="header" sx={{ fontSize: 10 }}>
                  {item[0]}
                </Typography>
                {/* table head */}
                <View
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    flexWrap: "nowrap",
                    columnGap: "4px",
                    borderBottom: "0.5px solid black",
                  }}
                >
                  {columns.map((th) => {
                    const columnWidth = `${(th.span / 24) * 100}%`;
                    return (
                      <Typography
                        key={th.key}
                        sx={{
                          fontWeight: "700",
                          textAlign: th.align,
                          width: columnWidth,
                          margin: "5px 4px",
                        }}
                      >
                        {th.title}
                      </Typography>
                    );
                  })}
                </View>
                {/* table rows */}
                {item[1].map((p, index) => {
                  return (
                    <View
                      key={index}
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        flexWrap: "nowrap",
                        columnGap: "4px",
                        padding: "4px",
                        borderBottom: "0.35px solid lightgrey",
                      }}
                    >
                      {columns.map((th) => {
                        const columnWidth = `${(th.span / 24) * 100}%`;
                        const value = p[th.dataKey];
                        return (
                          <Typography
                            key={th.key}
                            sx={{
                              width: columnWidth,
                              margin: "0 4px",
                              textAlign: th.align,
                              fontFamily: "Microsoft YaHei",
                            }}
                          >
                            {th.render ? th.render(value, item, index) : value}
                          </Typography>
                        );
                      })}
                    </View>
                  );
                })}
              </Stack>
            );
          })}
        </Stack>
        <PageNumber />
      </Page>
    </Document>
  );
};
