import { Page, View, Document, Font } from "@react-pdf/renderer";
import moment from "moment";

import { Typography, HorizontalLine, PageNumber, Row, Stack } from "./utilities";
import { formatMoney } from "../../utils/index";
const DATE_FORMAT = "DD/MM/YYYY";
const today = moment().format(DATE_FORMAT);

Font.register({
  family: "Microsoft YaHei",
  src: "/fonts/chinese.msyh.ttf",
});

const tableColumns = [
  {
    title: "#",
    dataKey: "customerCode",
    span: 1,
    align: "center",
  },
  { title: "Customer", dataKey: "name", key: "name", span: 3, align: "left" },
  {
    title: "Terms",
    dataKey: "paymentTerms",
    span: 2,
    align: "left",
  },
  {
    title: "Oldest outstanding invoice",
    dataKey: "oldestPurchase",
    span: 3,
    align: "left",
    render: (_, record) => {
      const firstPurchase = record.orders[0];

      return moment(firstPurchase?.orderDate).format("DD/MM/YYYY");
    },
  },
  {
    title: "Latest purchase",
    dataKey: "lastPurchase",
    span: 3,
    align: "left",
    render: (_, record) => {
      const latestPurchase = record.orders[record.orders.length - 1];
      return moment(latestPurchase?.orderDate).format("DD/MM/YYYY");
    },
  },
  {
    title: "Lastest payment",
    dataKey: "lastPayment",
    span: 3,
    align: "left",
    render: (_, record) => {
      if (record.paymentBatches?.length > 0) {
        const payment = record.paymentBatches[0];
        return moment(payment?.receivedDate).format("DD/MM/YYYY");
      } else {
        return "";
      }
    },
  },
  {
    title: "Salesperson",
    dataKey: "salesperson",
    span: 2,
    align: "left",
    render: (_, record) => {
      return record.salesperson?.name || "";
    },
  },
  {
    title: "Franchise",
    dataKey: "franchise",
    span: 3,
    align: "left",
    render: (_, record) => {
      return record.franchise?.name || "";
    },
  },
  {
    title: "Vendor",
    dataKey: "invoiceTemplate",
    span: 3,
    align: "left",
  },
  {
    title: "Account balance",
    dataKey: "accountBalance",
    span: 4,
    align: "left",
    render: (_, record) => {
      return `$${record.accountBalance}`;
    },
  },
];

export const StatementSummaryPDF = ({ data, total }) => {
  return (
    <Document>
      <Page
        size="A4"
        style={{
          paddingTop: 32,
          paddingBottom: 32,
          paddingHorizontal: 32,
          backgroundColor: "#fff",
          fontFamily: "Microsoft YaHei",
        }}
        orientation="landscape"
      >
        <Row>
          <Stack
            sx={{
              width: "100%",
              alignItems: "flex-end",
              marginBottom: "50px",
            }}
          >
            <Typography type="header" sx={{ fontSize: "24px", textAlign: "right" }}>
              Summary
            </Typography>
            <Typography sx={{ textAlign: "right" }}>as of {today}</Typography>
          </Stack>
        </Row>
        {/* table header  */}
        <View
          style={{
            display: "flex",
            flexDirection: "row",
            flexWrap: "nowrap",
            columnGap: "4px",
            marginBottom: "16px",
          }}
        >
          {tableColumns.map((th, index) => {
            const columnWidth = `${(th.span / 24) * 100}%`;
            return (
              <Typography
                key={index}
                sx={{
                  fontWeight: "bold",
                  width: columnWidth,
                  textAlign: th.align,
                  margin: "0 4px",
                }}
              >
                {th.title}
              </Typography>
            );
          })}
        </View>
        <HorizontalLine />
        {/* table body  */}
        {data.map((customer) => {
          return (
            <View
              key={customer.id}
              style={{
                display: "flex",
                flexDirection: "row",
                flexWrap: "nowrap",
                columnGap: "4px",
                padding: "4px",
                borderBottom: "0.35px solid lightgrey",
              }}
            >
              {tableColumns.map((th, index) => {
                const columnWidth = `${(th.span / 24) * 100}%`;
                const value = customer[th.dataKey];
                return (
                  <Typography
                    key={index}
                    sx={{
                      width: columnWidth,
                      margin: "0 4px",
                      textAlign: th.align,
                    }}
                  >
                    {th.render ? th.render(value, customer) : value}
                  </Typography>
                );
              })}
            </View>
          );
        })}
        <HorizontalLine />
        <View
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "flex-end",
            padding: "10px 0",
          }}
        >
          <Typography type="header" sx={{ marginRight: "16px" }}>
            TOTAL:
          </Typography>
          <Typography type="header">{formatMoney(total)}</Typography>
        </View>
        <HorizontalLine />
        <PageNumber />
      </Page>
    </Document>
  );
};
