import { LockOutlined } from "@ant-design/icons";
import { InputNumber, Input, Form } from "antd";

export const MoneyFieldV2 = ({
  name,
  index,
  precision = 2,
  shouldUpdate = true,
  addonBefore = null,
}) => {
  const handleFocus = (e) => e.target.select();

  // const usingQuote = form.getFieldsValue()?.lineItems[index]?.usingQuote;
  const usingQuote = false;
  return (
    <>
      <Form.Item name={name} shouldUpdate={shouldUpdate} noStyle>
        <InputNumber
          precision={precision}
          stringMode
          onFocus={handleFocus}
          addonBefore={usingQuote ? <LockOutlined style={{ color: "red" }} /> : addonBefore}
        />
      </Form.Item>
      <Form.Item name={[index, "usingQuote"]} hidden>
        <Input />
      </Form.Item>
    </>
  );
};
