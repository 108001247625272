import { useState } from "react";
import PropTypes from "prop-types";
import { Layout } from "antd";
import { TopHeader } from "./header";
import { Sidebar } from "./sidebar";

export const DashboardLayout = ({ children }) => {
  const [collapsed, setCollapsed] = useState(false);

  return (
    <Layout style={{ minHeight: "100vh", background: "#fff" }}>
      <Sidebar collapsed={collapsed} setCollapsed={setCollapsed} />
      <Layout style={{ paddingLeft: collapsed ? "64px" : "156px" }}>
        <TopHeader />
        <Layout.Content
          style={{
            width: "100%",
            height: " 100%",
            padding: "4.5rem 24px 24px",
            background: "#eef2f6",
          }}
        >
          {children}
        </Layout.Content>
      </Layout>
    </Layout>
  );
};

DashboardLayout.propTypes = {
  children: PropTypes.any,
};
