import { useState, useEffect } from "react";
import { useLazyQuery } from "@apollo/client";

import {
  Form,
  Space,
  Row,
  Col,
  Button,
  Card,
  Typography,
  DatePicker,
  Drawer,
  InputNumber,
} from "antd";
import { ExportOutlined } from "@ant-design/icons";

import { CustomerDetailCard } from "../CustomerDetailCard";
import moment from "moment";
import { calcTotalPrice } from "../../utils";

import { SingleCustomerForOrderQuery } from "../../graphql/customers.graphql";

import { CustomerSelection } from "../CustomerSelection";

import { PageContainer } from "../atoms/PageContainer";
import { CreditNoteFormLineItemsTable } from "../formFields/CreditNoteFormLineItemsTable";

const initialValues = {
  orderDate: moment(),
  totalPrice: {
    totalWithoutGst: 0,
    gstAmount: 0,
    gstPercentage: 0,
    total: 0,
    grossProfit: 0,
  },
  settings: {
    isFixedMargin: false,
  },
  lineItems: Array(5).fill(undefined),
};

export const CreditNoteForm = ({ customer = {}, setCustomer, form }) => {
  const [selectedCustomer, setSelectedCustomer] = useState(customer);
  const [customerDrawerOpen, setCustomerDrawerOpen] = useState(false);

  const [fetchCustomer, { data: newCustomer }] = useLazyQuery(SingleCustomerForOrderQuery, {
    fetchPolicy: "network-only",
  });

  useEffect(() => {
    if (customer) {
      setSelectedCustomer(customer);
      form.setFields([
        { name: "deliveryAddress", value: customer.deliveryAddress },
        { name: "notes", value: customer.notes },
      ]);
    }
    if (newCustomer?.customer && setCustomer) {
      setSelectedCustomer(newCustomer?.customer);
      setCustomerDrawerOpen(false);
      setCustomer(newCustomer?.customer);
      form.setFields([
        {
          name: "deliveryAddress",
          value: newCustomer?.customer.deliveryAddress,
        },
        { name: "notes", value: newCustomer?.customer.notes },
      ]);
    }
  }, [customer, setCustomer, newCustomer, form]);

  const handleFieldsChange = (e) => {
    const [listName, index, fieldName] = e[0].name;
    if (listName === "lineItems") {
      if (["unitPrice", "quantity"].includes(fieldName)) {
        const lineItems = form.getFieldValue().lineItems;
        const { quantity, unitPrice } = lineItems[index];
        const lineTotal = (parseFloat(quantity) * parseFloat(unitPrice)).toFixed(2);

        form.setFields([
          { name: "totalPrice", value: calcTotalPrice(lineItems, 0, false) },
          { name: ["lineItems", index, "lineTotal"], value: lineTotal },
        ]);
      }
    }
  };

  const handleCustomerDrawerClose = () => {
    setCustomerDrawerOpen(false);
  };

  const handleCustomerChange = (_, { id }) => {
    fetchCustomer({ variables: { id: id } });
  };

  return (
    <>
      <Drawer
        title="Basic Drawer"
        placement="right"
        width="50%"
        onClose={handleCustomerDrawerClose}
        visible={customerDrawerOpen}
      >
        <CustomerSelection onCustomerSelect={handleCustomerChange} />
      </Drawer>
      <Form
        layout="vertical"
        form={form}
        onFieldsChange={handleFieldsChange}
        initialValues={initialValues}
      >
        <Row gutter={16}>
          <Col span={18} style={{ marginTop: "-15px" }}>
            <PageContainer>
              <Card
                title="Items"
                actions={[
                  <Space>
                    <Typography.Text>Total</Typography.Text>
                    <Form.Item name={["totalPrice", "total"]} shouldUpdate noStyle>
                      <InputNumber readOnly bordered={false} prefix="$" precision={2} />
                    </Form.Item>
                    <Form.Item hidden name={["totalPrice", "totalWithoutGst"]} shouldUpdate noStyle>
                      <InputNumber readOnly bordered={false} prefix="$" precision={2} />
                    </Form.Item>
                  </Space>,
                ]}
              >
                <CreditNoteFormLineItemsTable />
              </Card>
            </PageContainer>
          </Col>
          {/* cutomer section */}
          <Col span={6}>
            <CustomerDetailCard
              customer={selectedCustomer}
              extra={<Button onClick={() => setCustomerDrawerOpen(true)}>Change</Button>}
              footer={
                <Button type="link">
                  <Space>
                    <a target="_blank" rel="noreferrer" href={`/customers/${customer.id}/history`}>
                      View orders history
                    </a>
                    <ExportOutlined />
                  </Space>
                </Button>
              }
            />
            <Card bodyStyle={{ paddingTop: "5px" }}>
              <Space direction="horizontal">
                <Form.Item label="Date" name="orderDate">
                  <DatePicker style={{ width: 150 }} />
                </Form.Item>
              </Space>
            </Card>
          </Col>
        </Row>
      </Form>
    </>
  );
};
