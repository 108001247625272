import { Form, Input, Table, Button, InputNumber } from "antd";
import { MinusOutlined, PlusOutlined } from "@ant-design/icons";

export const CreditNoteFormLineItemsTable = () => {
  const handleFocus = (e) => {
    e.target.select();
  };

  return (
    <Form.List name="lineItems">
      {(fields, { add, remove, move }) => {
        const lineItemsTableColumns = [
          {
            title: "#",
            key: "index",
            align: "center",
            width: "1%",
            render: (value, record, index) => index + 1,
          },
          {
            title: "Name",
            dataIndex: "name",
            key: "name",
            align: "left",
            width: "30%",
            render: (value, record, index) => (
              <Form.Item name={[index, "name"]} noStyle>
                <Input bordered={false} style={{ width: "100%", paddingLeft: "0" }} />
              </Form.Item>
            ),
          },
          {
            title: "Qty",
            dataIndex: "quantity",
            key: "quantity",
            align: "right",
            width: "10%",
            render: (value, record, index) => (
              <Form.Item name={[index, "quantity"]} noStyle>
                <InputNumber
                  className="money-input"
                  controls={false}
                  precision={2}
                  stringMode={true}
                  onFocus={handleFocus}
                  bordered={false}
                  keyboard={false}
                  style={{ width: "100%", paddingRight: "8px" }}
                />
              </Form.Item>
            ),
          },
          {
            title: "Price",
            dataIndex: "price",
            key: "price",
            align: "right",
            width: "10%",
            render: (value, record, index) => (
              <Form.Item name={[index, "unitPrice"]} noStyle>
                <InputNumber
                  className="money-input"
                  controls={false}
                  precision={2}
                  stringMode={true}
                  onFocus={handleFocus}
                  bordered={false}
                  prefix="$"
                  keyboard={false}
                  style={{ width: "100%", paddingRight: "8px" }}
                />
              </Form.Item>
            ),
          },
          {
            title: "Total",
            width: "10%",
            key: "lineTotal",
            align: "right",
            render: (value, record, index) => (
              <Form.Item name={[index, "lineTotal"]} noStyle>
                <InputNumber
                  className="money-input"
                  controls={false}
                  precision={2}
                  stringMode={true}
                  onFocus={handleFocus}
                  bordered={false}
                  prefix="$"
                  keyboard={false}
                  style={{ width: "100%", paddingRight: "8px" }}
                />
              </Form.Item>
            ),
          },
          {
            title: "...",
            key: "remove",
            width: "2%",
            render: (value, record, index) => (
              <Button
                onClick={() => remove(index)}
                shape="circle"
                danger
                type="dashed"
                size="small"
                icon={<MinusOutlined />}
              />
            ),
          },
        ];
        return (
          <Table
            bordered
            columns={lineItemsTableColumns}
            dataSource={fields}
            footer={() => (
              <Button
                block
                icon={<PlusOutlined />}
                size="large"
                type="dashed"
                onClick={() => add()}
              >
                Add
              </Button>
            )}
            size="small"
            pagination={false}
          />
        );
      }}
    </Form.List>
  );
};
