const convertNumberToFixedString = (number, precision = 2) => {
  return (+number).toFixed(precision);
};

export const getUnitPrice = ({ productVariant, pickingStatus = "pack" }) => {
  if (!productVariant) return "0.00";
  if (pickingStatus === "na" || pickingStatus === "replace") return "0.00";

  return (
    Number(productVariant.sellingPrice) ||
    convertNumberToFixedString(
      +productVariant.basePrice * (1 + (+productVariant.defaultMargin || 0))
    ) ||
    "0.00"
  );
};

export const getUnitCost = (item) => {
  if (item?.pickingStatus === "na" || item?.pickingStatus === "replace") return 0;
  return (+item?.unitCost || +item?.unitPrice || 0) * (+item?.quantity || 1);
};

export const getTotalCost = (lineItems = []) => {
  let totalCost = lineItems.reduce((total, item) => total + getUnitCost(item), 0);
  return totalCost.toFixed(2);
};

export const getOrderTotal = (lineItems = [], gstPercentage = 0, roundDown = true) => {
  // Calculate total without GST
  let lineItemsTotal = lineItems
    .reduce((total, item) => total + (+item?.unitPrice || 0) * +(item?.quantity || 0), 0)
    .toFixed(2);

  // Calculate total cost
  let totalCost = getTotalCost(lineItems);

  // Calculate GST amount
  let gstAmount = (+lineItemsTotal * gstPercentage).toFixed(2);

  // Calculate total after applying GST
  let totalBeforeDiscount = (+lineItemsTotal + +gstAmount).toFixed(2);

  // Calculate the discount
  const discountedTotal = roundDown
    ? Math.floor(+totalBeforeDiscount * 10) / 10
    : +totalBeforeDiscount;
  const discountAmount = (+totalBeforeDiscount - discountedTotal).toFixed(2);

  // Calculate gross profit
  const grossProfit = +discountedTotal ? (+discountedTotal - totalCost) / +discountedTotal : null;

  return {
    total: discountedTotal.toFixed(2),
    totalWithoutGst: totalBeforeDiscount,
    totalBasePrice: totalCost,
    discountAmount: discountAmount,
    grossProfit: typeof grossProfit === "number" ? grossProfit.toFixed(4) : null,
  };
};
