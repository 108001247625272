import { useEffect, useState } from "react";
import { useLazyQuery, useMutation } from "@apollo/client";
import { useNavigate } from "react-router-dom";

import { notification, PageHeader } from "antd";
import { OrderForm } from "@/components/OrderForm";
import { prepareOrderFormValues } from "@/utils";
import { CustomerSelection } from "@/components/CustomerSelection";

import { SingleCustomerForOrderQuery } from "@/graphql/customers.graphql";
import { OrdersQuery, CreateOrderMutation } from "@/graphql/orders.graphql";

// import for achiving the function of UnsavedReminder
import UnsavedReminder from "../components/atoms/UnsavedReminder";
import { useCallbackPrompt } from "../hooks/useCallbackPrompt";

export const NewOrder = () => {
  const [customer, setCustomer] = useState({});
  const navigate = useNavigate();

  // unsavedReminder
  const [showModal, setShowModal] = useState(false);
  const [showPrompt, confirmNavigation, cancelNavigation] = useCallbackPrompt(true);
  const [isSaved, setIsSaved] = useState(false);

  const [fetchCustomer, { data }] = useLazyQuery(SingleCustomerForOrderQuery);

  const [createOrder] = useMutation(CreateOrderMutation, {
    refetchQueries: [{ query: OrdersQuery }],
  });

  const handleCustomerSelect = (_, option) => {
    fetchCustomer({ variables: { id: option.id } });
  };

  useEffect(() => {
    if (isSaved) {
      setShowModal(false);
    } else setShowModal(showPrompt);
  }, [showPrompt, isSaved]);

  const handleSave = async (values) => {
    setIsSaved(true);
    const data = prepareOrderFormValues(values, customer, "create");
    console.log("data: ", data.orderDate.format());

    const res = await createOrder({ variables: { order: data } });

    if (res.data) {
      notification["success"]({
        message: `Order for ${res.data.createOrder.customer.name} successfully created`,
      });

      navigate("/orders");
      confirmNavigation();
    }
  };

  useEffect(() => {
    if (data) {
      setCustomer(data.customer);
    }
  }, [data]);

  return (
    <>
      <PageHeader title={`New order · ${customer?.name}`} />
      <UnsavedReminder
        showModal={showModal}
        confirmNavigation={confirmNavigation}
        cancelNavigation={cancelNavigation}
      />
      {customer.id ? (
        <OrderForm customer={customer} setCustomer={setCustomer} onSave={handleSave} />
      ) : (
        <CustomerSelection onCustomerSelect={handleCustomerSelect} />
      )}
    </>
  );
};
