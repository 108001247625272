import _ from "lodash";

export function displayQuantity(reqQuantity, product) {
  console.log("product: ", product);
  const { defaultUnit, alternativeUnit, displayUnit, unitConversionRate } =
    product;
  if (displayUnit === "alternative" && unitConversionRate) {
    const rate = Number(unitConversionRate);
    const altQtyInt = Math.floor(Number(reqQuantity) / rate);
    const remainder = Number(reqQuantity) % rate;
    const remainderText =
      remainder === 0 ? "" : ` and ${remainder} ${defaultUnit}`;
    return `${altQtyInt} ${alternativeUnit}${remainderText}`;
  }

  return `${reqQuantity} ${defaultUnit}`;
}

export function getProductsBreakdown(lineItems) {
  return _.chain(lineItems)
    .groupBy("product.id")
    .map(value => {
      const res = value.reduce(
        (total, current) => {
          const { product, productVariant, packagingUnit, quantity } = current;
          if (productVariant.isDefaultUnit) {
            total.requiredDefaultQuantity += quantity;
          } else if (productVariant.converToDefaultUnit) {
            total.requiredDefaultQuantity +=
              quantity * productVariant.converToDefaultUnit;
          } else {
            total.requiredAlternativeQuantity += quantity;
          }
          return { ...total, packagingUnit, product, productVariant };
        },
        {
          requiredDefaultQuantity: 0,
          requiredAlternativeQuantity: 0,
          productVariant: {},
          product: {},
        }
      );
      return {
        productName: res.product.name,
        requiredQuantity: res.requiredDefaultQuantity,
        requiredDisplayQuantity: `${res.requiredDefaultQuantity}  ${res.packagingUnit}`,
        inventoryQuantity: +res.product.inventoryQuantity,
        product: res.product,
      };
    })
    .value();
}
