import moment from "moment-timezone";
export const createExpensesObject = (values, type) => {
  let expensesList = values.expenses;
  const expenseDate = values.spendAt ? values.spendAt.toISOString() : moment().toISOString();
  const expenseType = values.expenseType || type;
  expensesList = expensesList.filter((eachExpense) => eachExpense.name !== undefined);
  return expensesList.map((eachExpense) => {
    return { ...eachExpense, spendAt: expenseDate, type: expenseType };
  });
};
