import PropTypes from "prop-types";
import { useAuth } from "../hooks/useAuth";
import { Navigate, useLocation, useNavigate } from "react-router-dom";
import { FullScreenWrapper } from "../components/atoms/FullScreenWrapper";
import { Spin } from "antd";
import { Unauthorised } from "@/pages/Unauthorised";

const orgName = process.env.REACT_APP_ORG_NAME;

export const AuthGuard = ({ children }) => {
  const { authenticated, loading, user } = useAuth();
  const location = useLocation();

  if (loading)
    return (
      <FullScreenWrapper>
        <Spin />
      </FullScreenWrapper>
    );

  if (!authenticated) {
    return <Navigate to="/login" state={{ from: location }} replace={true} />;
  } else {
  }

  if (user && user.organisations) {
    if (user.organisations[0]) {
      if (user.organisations[0].name !== orgName) {
        return <Navigate to="/unauthorised" state={{ from: location }} replace={true} />;
      }
    }
  }

  return children;
};

AuthGuard.propTypes = {
  children: PropTypes.node,
};
