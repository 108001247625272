import { Page, View, Document, Font } from "@react-pdf/renderer";
import moment from "moment";
import { HorizontalLine, Typography, PageNumber, Row, Stack } from "./utilities";
import { VendorHeader } from "./VendorHeader";

Font.register({
  family: "Microsoft YaHei",
  src: "/fonts/chinese.msyh.ttf",
});

export const QuotationPDF = ({ quotation, language }) => {
  if (quotation.id === undefined) return null;
  const { name, email, phone, notes, billingAddress, deliveryAddress } = quotation.customers[0];

  const tableColumns = [
    {
      title: "#",
      dataKey: "index",
      key: "index",
      span: 0.25,
      align: "left",
      render: (_, record, index) => {
        return record && `${index + 1}`;
      },
    },
    {
      title: "Description",
      dataKey: "name",
      key: "name",
      span: 5,
      align: "left",
      render: (_, record) => {
        let name = record.name;
        if (!record.isOpenItem) {
          name = language === "single" ? record.product.name_EN : record.product.name;
        }
        return name;
      },
    },
    {
      title: "Unit",
      dataKey: "packagingUnit",
      key: "packagingUnit",
      span: 2,
      align: "left",
      render: (_, record) => {
        if (record.isOpenItem) return record.packagingUnit ?? "";
        return language === "single" ? record.productVariant.name_EN : record.productVariant.name;
      },
    },
    {
      title: "Quantity",
      dataKey: "quantity",
      key: "quantity",
      span: 1,
      align: "left",
    },
    {
      title: "Unit Price",
      dataKey: "unitPrice",
      key: "unitPrice",
      span: 1.75,
      align: "right",
      render: (value) => `$${value}`,
    },
  ];
  return (
    <Document>
      <Page
        size="A4"
        style={{
          paddingTop: 32,
          paddingBottom: 40,
          paddingHorizontal: 32,
          backgroundColor: "#fff",
        }}
      >
        <Row>
          <VendorHeader />
          <Stack
            sx={{
              width: "50%",
              alignItems: "flex-end",
            }}
          >
            <Typography type="header" sx={{ fontSize: "24px", textAlign: "right" }}>
              {quotation?.status === "draft"
                ? `DRAFT #${quotation?.name}`
                : `Quote #${quotation?.name}`}
            </Typography>
            <Typography sx={{ textAlign: "right" }}>
              Start Date: {moment(quotation.start).format("DD/MM/YYYY")}
            </Typography>
            <Typography sx={{ textAlign: "right" }}>
              Due date: {moment(quotation.end).format("DD/MM/YYYY")}
            </Typography>
          </Stack>
        </Row>

        <Row sx={{ marginTop: 24, marginBottom: 24 }}>
          <Stack
            sx={{
              width: "50%",
              borderRadius: "5px",
              border: "1px solid #000",
              padding: "8px",
              marginRight: "8px",
            }}
          >
            <Typography>To customer: </Typography>
            <Typography type="header" sx={{ fontFamily: "Microsoft YaHei" }}>
              {name}
            </Typography>
            <Typography>Billing Add: {billingAddress}</Typography>
            <Typography>Email: {email}</Typography>
            <Typography>Phone: {phone}</Typography>
          </Stack>

          <Stack
            sx={{
              borderRadius: "5px",
              border: "1px solid #000",
              width: "50%",
              padding: "8px",
            }}
          >
            <Typography>Delivery Address</Typography>
            <Typography type="header">{deliveryAddress}</Typography>
            <Typography>Note: {notes}</Typography>
          </Stack>
        </Row>

        {/* table header  */}

        <View
          style={{
            display: "flex",
            flexDirection: "row",
            flexWrap: "nowrap",
            columnGap: "4px",
          }}
        >
          {tableColumns.map((th) => {
            const columnWidth = `${(th.span / 12) * 100}%`;
            return (
              <Typography
                key={th.key}
                sx={{
                  fontWeight: "bold",
                  width: columnWidth,
                  textAlign: th.align,
                  margin: "0 4px",
                }}
              >
                {th.title}
              </Typography>
            );
          })}
        </View>
        <HorizontalLine />

        {/* table body  */}

        {quotation?.lineItems &&
          quotation.lineItems.map((item, index) => {
            return (
              <View
                key={item.id}
                style={{
                  display: "flex",
                  flexDirection: "row",
                  flexWrap: "nowrap",
                  columnGap: "4px",
                  padding: "4px",
                  borderBottom: "0.35px solid lightgrey",
                }}
              >
                {tableColumns.map((th) => {
                  const columnWidth = `${(th.span / 12) * 100}%`;
                  const value = item[th.dataKey];
                  const strike =
                    (th.dataKey === "unitPrice" || th.dataKey === "lineTotal") &&
                    (item.pickingStatus === "na" || item.pickingStatus === "replace");
                  const textValue = th.render ? th.render(value, item, index) : value;

                  return (
                    <Typography
                      key={th.key}
                      sx={{
                        width: columnWidth,
                        margin: "0 4px",
                        textAlign: th.align,
                        fontFamily: "Microsoft YaHei",
                        textDectoration: strike ? "line-through" : "none",
                      }}
                    >
                      {textValue}
                    </Typography>
                  );
                })}
              </View>
            );
          })}

        <HorizontalLine />

        <PageNumber />
      </Page>
    </Document>
  );
};
