import { gql } from "@apollo/client";

export const CategoriesQuery = gql`
  query CategoriesQuery {
    categories(orderBy: { name: asc }) {
      id
      name
    }
  }
`;

export const CategoriesWithProductsForBulkEdit = gql`
  query CategoriesWithProductsForBulkEdit {
    categories(orderBy: { name: asc }) {
      id
      name
      products(orderBy: { name: asc }, where: { status: { equals: active } }) {
        id
        name
        productVariants(where: { status: { equals: active } }) {
          id
          name
          fullName
          basePrice
          defaultMargin
          sellingPrice
          quantity
        }
      }
    }
  }
`;

export const CategoriesWithProductsForBulkEditV2 = gql`
  query CategoriesWithProductsForBulkEditV2 {
    categories(orderBy: { name: asc }) {
      id
      name
      products(orderBy: { name_EN: asc }, where: { version: { equals: 2 } }) {
        id
        name
        commonNotes
        productVariants(orderBy: { name_EN: asc }, where: { status: { equals: active } }) {
          id
          name
          fullName
          basePrice
          defaultMargin
          sellingPrice
          quantity
          status
        }
      }
    }
  }
`;
