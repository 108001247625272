import { useEffect, useState } from "react";
import { Popover, Button, Space, Form, Input } from "antd";
import { EditOutlined } from "@ant-design/icons";
import _ from "lodash";

import { useSetDeliveryRun } from "../../hooks/useSetDeliveryRun";

export const TruckDetailsPopover = ({
  trucks,
  setTrucks,
  truckName,
  emptyDeliveryRun,
  setEmptyDeliveryRun,
}) => {
  const [form] = Form.useForm();
  const [clicked, setClicked] = useState(false);
  const [values, setValues] = useState([]);
  const { doSetDeliveryRun } = useSetDeliveryRun(emptyDeliveryRun);

  useEffect(() => {
    setValues([
      {
        name: "driverName",
        value: trucks[truckName]?.driverName,
      },
      {
        name: "plateNumber",
        value: trucks[truckName]?.plateNumber,
      },
    ]);
  }, [trucks, truckName]);

  const handleClick = () => {
    setClicked(true);
    form.setFields(values);
  };

  const handleReset = () => {
    form.setFields(values);
    setClicked(false);
  };

  const handleSave = async () => {
    setClicked(false);
    const updatedData = form.getFieldsValue();
    let copiedTrucks = _.cloneDeep(trucks);
    copiedTrucks[truckName] = { ...copiedTrucks[truckName], ...updatedData };
    delete copiedTrucks.Unallocated;

    const res = await doSetDeliveryRun({ runsheet: Object.values(copiedTrucks) });
    setEmptyDeliveryRun(false);
    if (res.data.updateDeliveryRun) {
      copiedTrucks = _.cloneDeep(trucks);
      copiedTrucks[truckName] = { ...copiedTrucks[truckName], ...updatedData };
      setTrucks(copiedTrucks);
    }
  };

  const handleClickedChange = (visible) => {
    setClicked(visible);
  };

  return (
    <Popover
      title={
        <Space style={{ display: "flex", justifyContent: "space-between" }}>
          <Button onClick={handleReset}>Reset</Button>
          <Button type="primary" onClick={handleSave}>
            Save
          </Button>
        </Space>
      }
      trigger={["click"]}
      visible={clicked}
      onVisibleChange={handleClickedChange}
      content={
        <Form form={form} layout="vertical">
          <Form.Item name="driverName" label="Driver Name">
            <Input />
          </Form.Item>
          <Form.Item name="plateNumber" label="Plate Number">
            <Input />
          </Form.Item>
        </Form>
      }
    >
      <Button onClick={handleClick} type="text" size="small" icon={<EditOutlined />} />
    </Popover>
  );
};
