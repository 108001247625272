import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { Droppable, Draggable } from "react-beautiful-dnd";
import { CarFilled } from "@ant-design/icons";
import { Space, Tag } from "antd";

import { TruckDetailsPopover } from "./TruckDetailsPopover";
import { OrderDisplayModal } from "./OrderDisplayModal";
import { PrintByTruck } from "../../components/Logistic/PrintByTruck";
import { groupBy } from "lodash";

const ListContainer = styled.div`
  border: 1px solid lightgrey;
  border-radius: 2px;
  min-height: 40%;
  display: flex;
  flex-direction: column;
  padding: 0 3px;
`;

const ListHeader = styled.div`
  padding: 8px;
  margin-bottom: 1px solid lightgrey;
  background: #eaeaea;
  display: flex;
  flex-direction: column;
  row-gap: 2px;
  margin-bottom: 4px;
`;

const TurckTitle = styled.h4`
  font-weight: bold;
  margin-bottom: 0;
`;

const TruckInfo = styled.span`
  font-size: 12px;
  color: #222222;
  margin-bottom: 0;
`;

const OrderListContainer = styled.div`
  transition: background-color 0.2s ease;
  flex-grow: 1;
  min-height: 100px;
  max-height: calc(100vh - 320px);
  overflow-y: scroll;
`;

const OrderContainer = styled.div`
  border: 1px solid lightgrey;
  border-radius: 2px;
  padding: 4px;
  margin-bottom: 4px;
`;

const colors = {
  unpacked: "magenta",
  packed: "green",
  packing: "cyan",
  missing: "orange",
};

const getOrderStyle = (isDragging, fulfillmentStatus, draggableStyle) => {
  const ffColor = colors[fulfillmentStatus];
  return {
    // change background colour if dragging
    background: isDragging ? "lightgreen" : "#fff",
    border: `2px solid ${ffColor}`,
    ...draggableStyle,
  };
};

export const TruckColumn = ({
  trucks,
  setTrucks,
  truckName,
  orderList,
  emptyDeliveryRun,
  setEmptyDeliveryRun,
  orders,
  setTempData,
  date,
}) => {
  const [statusBreakdown, setStatusBreakdown] = useState([
    { label: "Packed", key: "packed", value: 0 },
    { label: "Missing", key: "missing", value: 0 },
    { label: "Unpacked", key: "unpacked", value: 0 },
  ]);
  useEffect(() => {
    const breakdown = groupBy(orderList, (item) => {
      return item.content.fulfillmentStatus;
    });

    setStatusBreakdown((previousStatus) =>
      previousStatus.map((status) => ({
        ...status,
        value: breakdown[status.key]?.length || 0,
      }))
    );
  }, [orderList]);
  return (
    <ListContainer>
      <ListHeader>
        <Space>
          <CarFilled />
          <TurckTitle>
            {truckName} ({orderList.length})
          </TurckTitle>
          <PrintByTruck
            trucks={trucks}
            truckName={truckName}
            orders={orders}
            date={date}
            totalCountOfOrders={orderList.length}
          />
        </Space>
        <Space>
          <TruckInfo>{trucks[truckName].driverName}</TruckInfo>
          <TruckInfo>{trucks[truckName].plateNumber}</TruckInfo>
          <TruckDetailsPopover
            trucks={trucks}
            setTrucks={setTrucks}
            truckName={truckName}
            emptyDeliveryRun={emptyDeliveryRun}
            setEmptyDeliveryRun={setEmptyDeliveryRun}
          />
        </Space>
        <Space size="small">
          {statusBreakdown.map((status) => {
            return <Tag color={colors[status.key]}>{status.value}</Tag>;
          })}
        </Space>
      </ListHeader>

      <Droppable droppableId={trucks[truckName].truckName}>
        {(provided) => {
          return (
            <OrderListContainer ref={provided.innerRef} {...provided.droppableProps}>
              {orderList.map((order, index) => (
                <Draggable draggableId={order?.id} index={index} key={order?.id}>
                  {(provided, snapshot) => {
                    return (
                      <OrderContainer
                        ref={provided.innerRef}
                        {...provided.draggableProps}
                        {...provided.dragHandleProps}
                        style={getOrderStyle(
                          snapshot.isDragging,
                          order?.content.fulfillmentStatus,
                          provided.draggableProps.style
                        )}
                      >
                        <OrderDisplayModal
                          order={order}
                          trucks={trucks}
                          truckName={truckName}
                          setTrucks={setTrucks}
                          setTempData={setTempData}
                        />
                      </OrderContainer>
                    );
                  }}
                </Draggable>
              ))}
              {provided.placeholder}
            </OrderListContainer>
          );
        }}
      </Droppable>
    </ListContainer>
  );
};
