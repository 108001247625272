import moment from "moment-timezone";
import { Tag } from "antd";
export const DateTag = ({ date }) => {
  if (!date) return null;
  const momentDT = date._isAMomentObject ? date : moment(date);
  if (momentDT.isSame(moment(), "day")) return <Tag>Today</Tag>;
  if (momentDT.isSame(moment().add({ days: 1 }), "day")) return <Tag color="blue">Tomorrow</Tag>;

  return null;
};
