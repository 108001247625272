export const getDateField = (dateField, start, end) => {
  const startOfDay = start ? start.startOf("day").toISOString() : "";
  const endOfDay = end ? end.endOf("day").toISOString() : "";
  if (!start) {
    return { [dateField]: { lte: endOfDay } };
  } else if (!end) {
    return { [dateField]: { gte: startOfDay } };
  } else {
    return {
      [dateField]: {
        gte: startOfDay,
        lte: endOfDay,
      },
    };
  }
};
