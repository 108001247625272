import { useQuery } from "@apollo/client";
import { useState, useEffect } from "react";
import { SalespeopleQuery } from "../graphql/users.graphql";

export const useSalespeople = () => {
  const [salespeople, setSalespeople] = useState([]);
  const { data, error, loading } = useQuery(SalespeopleQuery);

  useEffect(() => {
    if (data?.users) {
      const temp = data.users.map((item) => {
        return { label: item.name, value: item.id };
      });
      // temp.unshift({ label: temp.item[0].name, value: temp.item[0].id });
      temp.unshift({ label: "None", value: null });
      setSalespeople(temp);
    }
  }, [data]);

  return { salespeople, error, loading };
};
