import { AutoComplete, Form, Input } from "antd";
import { useContext } from "react";
import { OrderFormContext } from "../../../contexts/OrderFormContext";

export const VariantsReadonlyFieldV2 = ({ index }) => {
  return (
    <>
      <Form.Item name={[index, "productVariantName"]} noStyle>
        <Input
          disabled
          bordered={false}
          style={{ background: "none", color: "#111111", cursor: "auto", padding: 0 }}
        />
      </Form.Item>
      <Form.Item hidden name={[index, "productVariantId"]}>
        <Input />
      </Form.Item>
    </>
  );
};

export const VariantsSelectV2 = ({ index }) => {
  const { form } = useContext(OrderFormContext);
  const variants = form.getFieldValue(["lineItems", index, "product", "productVariants"]);

  const options = variants?.length
    ? [
        ...variants.map((variant) => {
          return {
            label: variant.name,
            value: variant.name,
            id: variant.id,
          };
        }),
      ]
    : null;

  const handleVariantSelect = (_, option) => {
    form.setFields([{ name: ["lineItems", index, "productVariantId"], value: option.id }]);
  };
  return (
    <>
      <Form.Item name={[index, "productVariantName"]} style={{ marginBottom: 5 }}>
        <AutoComplete
          bordered={false}
          options={options}
          style={{ paddingLeft: 0 }}
          onSelect={handleVariantSelect}
        />
      </Form.Item>
      <Form.Item hidden name={[index, "productVariantId"]}>
        <Input />
      </Form.Item>
    </>
  );
};
