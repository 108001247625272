import { useLazyQuery } from "@apollo/client";
import { Button, Table, Tooltip, Typography } from "antd";
import { useCallback, useEffect, useState } from "react";
import { OrdersOfAFranchiseQuery } from "../graphql/orders.graphql";
import moment from "moment-timezone";
import sortBy from "lodash/sortBy";

export const PricingAnalysis = () => {
  const [ordersForThreeDays, setOrdersForThreeDays] = useState({});
  const [fetchOrders, { data: orders, loading }] = useLazyQuery(OrdersOfAFranchiseQuery);
  const [data, setData] = useState([]);

  const columns = [
    {
      title: "#",
      key: "productVariant",
      dataIndex: "productVariant",
    },
    {
      title: "Customer",
      key: "customer",
      dataIndex: "customerName",
    },
    {
      title: moment().subtract(2, "days").format("YYYY-MM-DD"),
      key: moment().subtract(2, "days").format("YYYY-MM-DD"),
      render: (_, record) => {
        const key = moment().subtract(2, "days").format("YYYY-MM-DD");
        return <Tooltip title={record[key]?.orderName}>{record[key]?.unitPrice}</Tooltip>;
      },
    },
    {
      title: moment().subtract(1, "days").format("YYYY-MM-DD"),
      key: moment().subtract(1, "days").format("YYYY-MM-DD"),
      render: (_, record) => {
        const key = moment().subtract(1, "days").format("YYYY-MM-DD");
        return <Tooltip title={record[key]?.orderName}>{record[key]?.unitPrice}</Tooltip>;
      },
    },
    {
      title: moment().format("YYYY-MM-DD"),
      key: moment().format("YYYY-MM-DD"),
      render: (_, record) => {
        const key = moment().format("YYYY-MM-DD");
        return <Tooltip title={record[key]?.orderName}>{record[key]?.unitPrice}</Tooltip>;
      },
    },
  ];

  const handleFetch = () => {
    fetchOrders({
      variables: {
        where: {
          orderDate: {
            lte: moment().endOf("day"),
            gte: moment().subtract(2, "days").startOf("day"),
          },
          isCreditNote: { equals: false },
          status: { equals: "confirmed" },
          customer: {
            franchise: {
              id: { equals: "cl9v867yn10022510mz3laskq6r0" },
            },
          },
        },
      },
      fetchPolicy: "network-only",
    });
  };

  useEffect(() => {
    if (orders) {
      // console.log("orders: ", orders);
      setOrdersForThreeDays(orders.orders);
    }
  }, [orders]);

  const breakdownData = useCallback(() => {
    const outputData = [];

    // Create a map to store order values by date, productVariant, and customerName
    const dataMap = new Map();

    // Iterate through the orders and populate the data map
    for (const order of ordersForThreeDays) {
      const orderDate = moment(order.orderDate).format("YYYY-MM-DD");
      const customerName = order.customer.name;

      for (const lineItem of order.lineItems) {
        const productVariantId = lineItem.productVariant.id;
        const productVariantName = `${lineItem.product?.name} / ${lineItem.productVariant?.name}`;
        const unitPrice = parseFloat(lineItem.unitPrice);

        if (!dataMap.has(productVariantId)) {
          dataMap.set(productVariantId, new Map());
        }

        const productVariantMap = dataMap.get(productVariantId);

        if (!productVariantMap.has(customerName)) {
          productVariantMap.set(customerName, {
            id: productVariantId,
            productVariant: productVariantName,
            customerName,
          });
        }

        productVariantMap.get(customerName)[orderDate] = { orderName: order.name, unitPrice };
      }
    }

    for (const productVariantMap of dataMap.values()) {
      let index = 0;
      for (const item of productVariantMap.values()) {
        outputData.push({ ...item, index });
        index++;
      }
    }
    setData(sortBy(outputData, "productVariant"));
    // setData(outputData);
  }, [ordersForThreeDays]);

  return (
    <div>
      <Typography.Title level={4}>Monkey King, Thai Riffic, Doodee</Typography.Title>
      <Button onClick={handleFetch} loading={loading}>
        Fetch
      </Button>
      <Button onClick={breakdownData} loading={loading}>
        Show data
      </Button>
      <Table
        columns={columns}
        dataSource={data}
        bordered
        pagination={false}
        size="small"
        rowClassName={(record) => `price-analysis-row-${record.index}-${record.id}`}
      />
    </div>
  );
};
