import { Page, Document, Font } from "@react-pdf/renderer";
import { Row, Typography, PageNumber, HorizontalLine } from "./utilities";
import moment from "moment-timezone";
import { formatMoney } from "@/utils";

Font.register({
  family: "Microsoft YaHei",
  src: `${process.env.PUBLIC_URL}/fonts/chinese.msyh.ttf`,
});

const RunsheetPage = ({
  driverName,
  platenumber,
  name,
  orders,
  allOrders,
  date,
  totalCountOfOrders,
}) => {
  return (
    <Page
      size="A4"
      style={{
        paddingTop: 32,
        paddingBottom: 48,
        paddingHorizontal: 32,
        backgroundColor: "#fff",
        fontFamily: "Microsoft YaHei",
      }}
    >
      <Typography type="header" sx={{ fontSize: "24px", textAlign: "right" }}>
        {date ?? moment().format("YYYY-MM-DD")} {name}
      </Typography>

      <Typography type="header" sx={{ fontSize: "24px", textAlign: "right" }}>
        {driverName}, Truck: {platenumber}, Total orders: {totalCountOfOrders}
      </Typography>
      <HorizontalLine />
      {orders.length ? (
        orders.map((orderId, idx) => {
          const currentOrder = allOrders[orderId]?.content;
          if (!currentOrder || currentOrder.status === "cancelled") return null;

          return (
            <Row sx={{ borderBottom: "1px solid black" }} key={`${orderId}-${idx}`}>
              <Typography
                type="header"
                sx={{ padding: "2px 4px", width: "20%", borderLeft: "1px solid black" }}
              >
                {currentOrder.name}
              </Typography>
              <Typography
                type="header"
                sx={{
                  padding: "2px 4px",
                  width: "40%",
                  borderLeft: "1px solid black",
                  borderRight: "1px solid black",
                  fontFamily: "Microsoft YaHei",
                }}
              >
                {currentOrder.customer.name}
              </Typography>
              <Typography
                type="header"
                sx={{
                  padding: "2px 4px",
                  width: "40%",
                  borderRight: "1px solid black",
                  textAlign: "right",
                }}
              >
                {formatMoney(currentOrder.total)}
              </Typography>
            </Row>
          );
        })
      ) : (
        <Typography>No orders</Typography>
      )}

      <Typography
        type="header"
        sx={{
          width: "100%",
          fontFamily: "Microsoft YaHei",
          textAlign: "right",
        }}
      >
        Total:{" "}
        {formatMoney(
          orders.reduce((prev, current) => {
            return prev + +(allOrders[current]?.content.total || 0);
          }, 0)
        )}
      </Typography>

      <PageNumber />
    </Page>
  );
};

export const RunsheetStatementPDF = ({ trucks = [], allOrders = {}, date, totalCountOfOrders }) => {
  return (
    <Document>
      {trucks?.length &&
        trucks.map((truck, idx) => {
          return (
            <RunsheetPage
              key={idx}
              driverName={truck?.driverName}
              platenumber={truck?.plateNumber}
              name={truck?.name}
              orders={truck?.orderIds || []}
              allOrders={allOrders}
              date={date}
              totalCountOfOrders={totalCountOfOrders}
            />
          );
        })}
    </Document>
  );
};
