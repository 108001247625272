import { gql } from "@apollo/client";

// export const HistoryPriceQuery = gql`
//   query HistoryPriceQuery($productVariantId: ID!, $customerId: ID!) {
//     lineItems(
//       where: {
//         productVariant: { id: { equals: $productVariantId } }
//         order: { status: { equals: "confirmed" }, customer: { id: { equals: $customerId } } }
//       }
//       take: 1
//       orderBy: { createdAt: desc }
//     ) {
//       id
//       name
//       unitPrice
//       updatedAt
//     }
//   }
// `;

export const HistoryPriceQuery = gql`
  query HistoryPriceQuery($productId: ID!, $customerWhere: CustomerWhereInput) {
    product(where: { id: $productId }) {
      id
      name
      productVariants {
        id
        name
        lineItems(
          where: { order: { status: { equals: "confirmed" }, customer: $customerWhere } }
          take: 1
          orderBy: { updatedAt: desc }
        ) {
          id
          name
          packagingUnit
          productVariant {
            name
            name_EN
          }
          unitPrice
          lineTotal
          updatedAt
          order {
            customer {
              name
            }
            confirmedAt
            name
          }
        }
      }
    }
  }
`;

export const BachedHistoryPriceQuery = gql`
  query BachedHistoryPriceQuery($productIds: [ID!]!, $customerWhere: CustomerWhereInput) {
    products(where: { id: { in: $productIds } }) {
      id
      name
      productVariants {
        id
        name
        lineItems(
          where: { order: { status: { equals: "confirmed" }, customer: $customerWhere } }
          take: 1
          orderBy: { updatedAt: desc }
        ) {
          id
          name
          packagingUnit
          productVariant {
            name
            name_EN
          }
          unitPrice
          lineTotal
          updatedAt
          order {
            customer {
              name
            }
            confirmedAt
            name
          }
        }
      }
    }
  }
`;

// export const HistoryPriceQuery = gql`
//   query HistoryPriceQuery($where: LineItemWhereInput!) {
//     lineItems(where: $where, take: 1, orderBy: { updatedAt: desc }) {
//       id
//       name
//       unitPrice
//       updatedAt
//       order {
//         name
//         orderDate
//         customer {
//           name
//         }
//       }
//     }
//   }
// `;

export const TodaysLineItemsQuery = gql`
  query TodaysLineItemsQuery($where: LineItemWhereInput!) {
    lineItems(where: $where) {
      id
      name
      quantity
      notes
      productVariant {
        id
        name
      }
      order {
        name
      }
    }
  }
`;

export const FranchiseHistoryPriceQuery = gql`
  query HistoryPriceQuery($productVariantId: ID!, $franchiseId: ID!) {
    lineItems(
      where: {
        productVariant: { id: { equals: $productVariantId } }
        order: {
          status: { equals: "confirmed" }
          customer: { franchise: { id: { equals: $franchiseId } } }
        }
      }
      take: 1
      orderBy: { createdAt: desc }
    ) {
      id
      name
      unitPrice
      updatedAt
    }
  }
`;

export const SearchLineItemsQuery = gql`
  query SearchLineItemsQuery($searchTerm: String!, $id: ID!) {
    lineItems(
      where: {
        AND: [
          { name: { contains: $searchTerm, mode: insensitive } }
          { order: { customer: { id: { equals: $id } } } }
        ]
      }
      take: 60
      skip: 0
    ) {
      id
      name
      quantity
      unitPrice
      packagingUnit
      order {
        orderDate
        total
        sequence
        name
        customer {
          id
          name
        }
      }
    }
  }
`;

export const LineItemsPageQuery = gql`
  query LineItemsPageQuery($filters: LineItemWhereInput!, $take: Int, $skip: Int) {
    lineItems(where: $filters, take: $take, skip: $skip, orderBy: { name: desc }) {
      id
      name
      notes
      quantity
      unitPrice
      pickingStatus
      packagingUnit
      productVariant {
        name
      }
      order {
        id
        status
        fulfillmentStatus
        orderDate
        total
        sequence
        name
        customer {
          id
          name
        }
      }
    }
    lineItemsCount(where: $filters)
  }
`;

export const ProductRelatedLineItemsQuery = gql`
  query ProductRelatedLineItemsQuery(
    $productId: ID!
    $orderDateFrom: DateTime!
    $orderDateTo: DateTime!
  ) {
    product(where: { id: $productId }) {
      id
      name
      name_EN
      productType
      defaultUnit
      alternativeUnit
      displayUnit
      displayQuantity
      inventoryQuantity
      alternativeUnit
      unitConversionRate
      lineItems(where: { order: { orderDate: { gte: $orderDateFrom, lte: $orderDateTo } } }) {
        id
        order {
          name
          customer {
            name
          }
        }
        quantity
        packagingUnit
      }
    }
  }
`;

export const UpdateLineItemsMutation = gql`
  mutation UpdateLineItemsMutation($data: [LineItemUpdateArgs!]!) {
    updateLineItems(data: $data) {
      id
    }
  }
`;
