import { useMutation } from "@apollo/client";
import {
  SoftDeleteOrderMutation,
  OrdersQuery,
  UpdateOrderPickingSlipPrintedAtMutation,
} from "../../../graphql/orders.graphql";
import { Link } from "react-router-dom";
import { Menu, Space, Button, Dropdown } from "antd";
import { EditOutlined, PrinterOutlined } from "@ant-design/icons";
import { PickingSlipPDF } from "../../pdf/PickingSlipPDF";
import { usePDF } from "@react-pdf/renderer";
import { useCallback, useEffect, useState } from "react";
import moment from "moment-timezone";

export const OrdersActionsMenu = ({ order }) => {
  const [softDeleteOrder] = useMutation(SoftDeleteOrderMutation, {
    refetchQueries: [
      {
        query: OrdersQuery,
      },
    ],
  });
  const handleMenuClick = async ({ key }) => {
    if (key === "delete") {
      const res = await softDeleteOrder({ variables: { id: order.id } });
      console.log("order cancelled", res);
    }
  };
  return (
    <>
      <Menu onClick={handleMenuClick}>
        <Menu.Item key="edit-v2">
          <Link to={`/orders/v2/${order.id}/edit`}>Edit order</Link>
        </Menu.Item>
        <Menu.Divider />
        <Menu.Item key="delete" danger>
          Delete
        </Menu.Item>
      </Menu>
    </>
  );
};

export const OrdersActionsCell = ({ order }) => {
  const [ready, setReady] = useState(false);
  const [orderData, setOrderData] = useState({});
  // const [fetchOrder, { data, loading }] = useLazyQuery(SingleOrderQuery);
  const [updatePrintedAt, { data, loading }] = useMutation(UpdateOrderPickingSlipPrintedAtMutation);
  const [psPdf, updatePsPdf] = usePDF({
    document: <PickingSlipPDF order={orderData} language="multi" />,
  });

  useEffect(() => {
    if (data?.updateOrder) {
      setOrderData(data.updateOrder);
    }
  }, [data]);

  useEffect(() => {
    if (orderData.id) {
      updatePsPdf();
      setReady(true);
    }
    //eslint-disable-next-line
  }, [orderData]);

  useEffect(() => {
    if (ready && !psPdf.error && !psPdf.loading) {
      window.open(psPdf.url, "_blank").focus();
      setReady(false);
    }
  }, [ready, psPdf]);

  const handlePrintPickingSlip = useCallback(() => {
    // fetchOrder({ variables: { id: order?.id } });
    updatePrintedAt({ variables: { id: order?.id, pickingSlipPrintedAt: moment().utc() } });
  }, [order, updatePrintedAt]);

  return (
    <Space>
      <Dropdown.Button trigger={["click"]} overlay={<OrdersActionsMenu order={order} />}>
        <Link to={`/orders/v2/${order.id}/edit`}>
          Edit <EditOutlined />
        </Link>
      </Dropdown.Button>

      <Button
        loading={loading}
        onClick={handlePrintPickingSlip}
        style={{ background: order.pickingSlipPrintedAt ? "#afefaf" : "transparent" }}
      >
        Picking Slip <PrinterOutlined />
      </Button>
    </Space>
  );
};
