import { Page, View, Document, Font } from "@react-pdf/renderer";
import { BankDetailsEverfresh, BankDetailsGlory, BankdetailsOnStatement } from "./Bankdetails";
import { Typography, HorizontalLine, PageNumber, Row, Stack } from "./utilities";
import { VendorHeader, VendorHeaderEverfresh, VendorHeaderGlory } from "./VendorHeader";
import { calcTotalSales, formatMoney } from "../../utils/index";
import moment from "moment";
import { InvoicesTable } from "./components/InvoicesTable";

const DATE_FORMAT = "DD/MM/YYYY";
const today = moment().format(DATE_FORMAT);

Font.register({
  family: "Microsoft YaHei",
  src: "/fonts/chinese.msyh.ttf",
});

const tableColumns = [
  {
    title: "Invoice NO.",
    dataKey: "name",
    key: "name",
    span: 3,
    align: "left",
  },
  {
    title: "Invoice Date",
    dataKey: "orderDate",
    key: "orderDate",
    span: 3,
    align: "center",
    render: (value, record) => moment(value).format(DATE_FORMAT),
  },

  {
    title: "Transaction",
    dataKey: "transaction",
    key: "transaction",
    span: 3,
    align: "center",
    render: (_, record) => (record.isCreditNote ? "Credit Note" : "Invoice"),
  },
  {
    title: "Invoice Total",
    dataKey: "total",
    key: "total",
    span: 3,
    align: "right",
    render: (value, record) => `$${value}`,
  },
  {
    title: "Paid",
    dataKey: "balancePaid",
    key: "balancePaid",
    span: 3,
    align: "right",
    render: (value, record) => (record.balancePaid ? `$${record.balancePaid}` : "-"),
  },
  {
    title: "Due Date",
    dataKey: "orderDate",
    key: "dueDate",
    span: 4,
    align: "center",
    render: (value, record) => moment(value).format(DATE_FORMAT),
  },
  {
    title: "Outstanding Amount",
    dataKey: "balanceDue",
    key: "balanceDue",
    span: 4,
    align: "right",
    render: (value) => `$${value}`,
  },
];

const getHeader = (template) => {
  if (template === "EVER_FRESH") return <VendorHeaderEverfresh />;

  if (template === "GLORY_FRESH") return <VendorHeaderGlory />;

  return <VendorHeader />;
};

const getBankDetailsFooter = (template, balance) => {
  if (template === "EVER_FRESH") return <BankDetailsEverfresh purpose="statement" />;

  if (template === "GLORY_FRESH") return <BankDetailsGlory purpose="statement" />;

  return <BankdetailsOnStatement balance={balance} />;
};

export const CustomerStatementPDF = ({ data }) => {
  const calculateTotalAmount = () => {
    if (data.isFranchise) {
      return data.branches.reduce((prev, current) => {
        return prev + calcTotalSales(current.orders);
      }, 0);
    } else {
      return calcTotalSales(data.orders);
    }
  };

  const totalAmount = calculateTotalAmount();

  return (
    <Document>
      <Page
        size="A4"
        style={{
          paddingTop: 32,
          paddingBottom: 32,
          paddingHorizontal: 32,
          backgroundColor: "#fff",
        }}
      >
        <Row>
          {getHeader(data.invoiceTemplate)}
          <Stack
            sx={{
              width: "50%",
              alignItems: "flex-end",
            }}
          >
            <Typography type="header" sx={{ fontSize: "24px", textAlign: "right" }}>
              Account Statement
            </Typography>
            <Typography sx={{ textAlign: "right" }}>as of {today}</Typography>
          </Stack>
        </Row>

        <Row sx={{ marginTop: 24, marginBottom: 24 }}>
          <Stack
            sx={{
              width: "50%",
              borderRadius: "5px",
              border: "1px solid #000",
              padding: "8px",
              marginRight: "8px",
            }}
          >
            <Typography>To customer: </Typography>
            <Typography type="header" sx={{ fontFamily: "Microsoft YaHei" }}>
              {data.name}
            </Typography>
            <Typography>Billing Add: {data.deliveryAddress}</Typography>
            <Typography>Email: {data.email}</Typography>
            <Typography>Phone: {data.phone}</Typography>
          </Stack>

          <Stack
            sx={{
              borderRadius: "5px",
              border: "1px solid #000",
              width: "50%",
              padding: "8px",
            }}
          >
            <Typography>Account details</Typography>
            <Typography sx={{ marginTop: "8px" }}>Customer #: {data?.customerCode}</Typography>
            <Typography>Payment terms: cod</Typography>
          </Stack>
        </Row>

        {data.isFranchise
          ? data.branches.map((branch) => {
              if (!branch.orders.length) return null;
              const total = calcTotalSales(branch.orders);
              return (
                <InvoicesTable
                  orders={branch.orders}
                  columns={tableColumns}
                  name={branch.name}
                  total={total}
                />
              );
            })
          : data.orders && (
              <InvoicesTable orders={data.orders} columns={tableColumns} total={totalAmount} />
            )}

        {data.isFranchise && (
          <>
            <HorizontalLine />

            <View
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "flex-end",
                padding: "10px 0",
              }}
            >
              <Typography type="header" sx={{ marginRight: 12 }}>
                Franchise Total:
              </Typography>
              <Typography type="header">
                {data.branches &&
                  formatMoney(
                    data.branches.reduce((prev, current) => {
                      return prev + calcTotalSales(current.orders);
                    }, 0)
                  )}
              </Typography>
            </View>
          </>
        )}

        <HorizontalLine sx={{ margin: "8px 0" }} />

        {getBankDetailsFooter(data.invoiceTemplate, formatMoney(totalAmount))}

        <PageNumber />
      </Page>
    </Document>
  );
};
