import axios from "axios";
import React, { useState, useEffect } from "react";
import { Space, Typography, Modal, Form, Button, notification, Select } from "antd";
import { MailOutlined } from "@ant-design/icons";
import { useMutation } from "@apollo/client";

// import { updateCustomerEmailMutation } from "../../graphql/customers.graphql";
import { UpdateInvoiceStatusMutation } from "../../graphql/orders.graphql";
import { convertBlobToBase64 } from "../../utils";

const { Option } = Select;
const emails = [];

const arr = [];
for (let i = 0; i < arr.length; i++) {
  emails.push(<Option key={arr[i]}>{arr[i]}</Option>);
}

export const SendEmailModal = ({
  size,
  buttonTitle,
  modalTitle,
  content,
  customer,
  blob,
  order,
  loading,
}) => {
  const [form] = Form.useForm();
  const [isModalVisible, setIsModalVisible] = useState(false);
  // const [isCheckboxChecked, setIsCheckboxChecked] = useState(false);
  const [disabled, setDisabled] = useState(true);
  const [updatedEmails, setUpdatedEmails] = useState([customer?.email]);
  const [updateInvoiceStatus] = useMutation(UpdateInvoiceStatusMutation);
  // const [updateCustomerEmail] = useMutation(updateCustomerEmailMutation);

  useEffect(() => {
    if (customer.email) {
      setDisabled(false);
    }
  }, [customer]);

  const showModal = () => {
    setIsModalVisible(true);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  // const toggleCheckbox = () => {
  //   setIsCheckboxChecked(!isCheckboxChecked);
  // };

  const selectedChange = (value) => {
    if (!value.length) {
      setDisabled(true);
    } else {
      setDisabled(false);
    }
    setUpdatedEmails(value);
  };

  const handleSend = async () => {
    // const updatedEmail = form.getFieldsValue().email || customer.email;
    // if (isCheckboxChecked) {
    //   await updateCustomerEmail({
    //     variables: { id: customer.id, email: updatedEmails },
    //   });
    // }

    setIsModalVisible(false);
    const endpoint = process.env.REACT_APP_FC_EMAIL_ENDPOINT;
    const blobToString = await convertBlobToBase64(blob);
    const pdfString = blobToString.split(";base64,")[1];

    try {
      const res = await axios.post(endpoint, {
        receiver: {
          name: customer.name,
          email: updatedEmails,
          customerCode: customer.customerCode,
          senderProfile: customer.invoiceTemplate,
        },
        order: { name: order?.name, id: order?.id },
        pdfString: pdfString,
        pdfType: content,
      });
      if (res.status === 200) {
        if (content === "invoice") {
          updateInvoiceStatus({ variables: { id: order.id, invoiceStatus: "invoice sent" } });
        }
        notification.success({
          message: res.data.result,
        });
      }
    } catch (error) {
      notification.warning({
        message: error.response.data.result,
      });
    }
  };

  return (
    <Space>
      <Button size={size} icon={<MailOutlined />} onClick={showModal} loading={loading}>
        {buttonTitle}
      </Button>
      <Modal
        visible={isModalVisible}
        title={<Typography.Title levle={5}>{modalTitle}</Typography.Title>}
        onCancel={handleCancel}
        onOk={handleSend}
        footer={[
          <Button key="cancel" onClick={handleCancel}>
            Cancel
          </Button>,
          <Button
            disabled={disabled}
            id={customer.id + "_email"}
            key="send"
            type="primary"
            onClick={handleSend}
          >
            Send
          </Button>,
        ]}
      >
        <Space direction="vertical">
          {`About to email ${content} to ${customer.name}`}
          <Typography.Title level={5}>Email address</Typography.Title>
          <Form form={form}>
            <Form.Item>
              <Select
                id="selected_emails"
                mode="tags"
                style={{
                  width: "100%",
                }}
                placeholder="Select..."
                defaultValue={customer?.email || undefined}
                onChange={selectedChange}
              >
                {emails}
              </Select>
            </Form.Item>
            {/* <Form.Item name="checkbox">
              <Checkbox checked={isCheckboxChecked} onChange={toggleCheckbox}>
                Update customer's email
              </Checkbox>
            </Form.Item> */}
          </Form>
        </Space>
      </Modal>
      {/* 用隐藏按钮 获取  Modal中 Send的 id */}
      {/* TODO 待优化 */}
      {/* <Button
        hidden
        id={customer.id + "_email"}
        key="send"
        type="primary"
        onClick={handleSend}
      ></Button> */}
    </Space>
  );
};
