import { Page, View, Document, Font } from "@react-pdf/renderer";
import { HorizontalLine, PageNumber, Row, Typography } from "./utilities";

Font.register({
  family: "Microsoft YaHei",
  src: "/fonts/chinese.msyh.ttf",
});

export const OrdersReportPDF = ({ date, orders, grandTotal }) => {
  const tableColumns = [
    {
      title: "#",
      dataKey: "#",
      key: "#",
      span: 1,
      align: "left",
      render: (_, record, index) => {
        return record && `${index + 1}`;
      },
    },
    {
      title: "Customer",
      dataKey: "customer",
      key: "customer",
      span: 4,
      align: "left",
      render: (_, record) => {
        return record.customer?.name || "";
      },
    },

    {
      title: "Order number",
      dataKey: "name",
      key: "name",
      span: 2,
      align: "left",
    },
    {
      title: "No. of Items",
      dataKey: "lineItemsCount",
      key: "lineItemsCount",
      span: 2,
      align: "left",
    },

    {
      title: "Total",
      dataKey: "total",
      key: "total",
      span: 3,
      align: "right",
      render: (value, record) => `$${value}`,
    },
  ];
  return (
    <Document>
      <Page
        size="A4"
        style={{
          paddingTop: 32,
          paddingBottom: 32,
          paddingHorizontal: 32,
          backgroundColor: "#fff",
        }}
      >
        <Typography type="header">Orders report: {date}</Typography>
        <Typography>Total: {orders.length} orders</Typography>

        {/* table header  */}

        <View
          style={{
            display: "flex",
            flexDirection: "row",
            flexWrap: "nowrap",
            columnGap: "4px",
            marginTop: 15,
          }}
        >
          {tableColumns.map((th) => {
            const columnWidth = `${(th.span / 12) * 100}%`;
            return (
              <Typography
                key={th.key}
                sx={{
                  fontWeight: "bold",
                  width: columnWidth,
                  textAlign: th.align,
                  margin: "0 4px",
                }}
              >
                {th.title}
              </Typography>
            );
          })}
        </View>
        <HorizontalLine />

        {orders &&
          orders.map((item, index) => {
            return (
              <View
                key={item.id}
                style={{
                  display: "flex",
                  flexDirection: "row",
                  flexWrap: "nowrap",
                  columnGap: "4px",
                  padding: "4px",
                  borderBottom: "0.35px solid lightgrey",
                }}
              >
                {tableColumns.map((th) => {
                  const columnWidth = `${(th.span / 12) * 100}%`;
                  const value = item[th.dataKey];
                  return (
                    <Typography
                      key={th.key}
                      sx={{
                        width: columnWidth,
                        margin: "0 4px",
                        textAlign: th.align,
                        fontFamily: "Microsoft YaHei",
                      }}
                    >
                      {th.render ? th.render(value, item, index) : value}
                    </Typography>
                  );
                })}
              </View>
            );
          })}

        <HorizontalLine />
        <Row sx={{ justifyContent: "flex-end", marginTop: 8 }}>
          <Typography type="header" sx={{ fontSize: 16 }}>
            $ {grandTotal}
          </Typography>
        </Row>

        <PageNumber />
      </Page>
    </Document>
  );
};
