import React, { useEffect, useReducer } from "react";
import styled from "styled-components";

import { DragDropContext } from "react-beautiful-dnd";
import type { DropResult, DraggableLocation } from "react-beautiful-dnd";
import { RUNSHEET_DEFAULT_VALUE } from "../../constants";
import { Button, Space, Spin, Typography, notification } from "antd";
import { useMutation } from "@apollo/client";
import { BulkUpdateCustomersRunsMutation } from "../../graphql/customers.graphql";
import { DroppableTruckColumn } from "./DroppableTruckColumn";
import { groupBy } from "lodash";
import { FullScreenWrapper } from "../atoms/FullScreenWrapper";

export type DraggableCustomerProps = {
  name: string;
  franchise?: {
    name: string;
  };
  id: string;
  defaultRun?: string;
  status?: string;
};

export type DraggableCustomerCardProps = {
  customer: DraggableCustomerProps;
  index: number;
};

type DeliveryRunSetupBoardProps = {
  customers: DraggableCustomerProps[];
  loading?: boolean;
};

const BoardContainer = styled.div`
  display: grid;
  width: 100%;
  column-gap: 4px;
  row-gap: 8px;
  grid-template-columns: repeat(10, 1fr);
`;

const ColumnHeader = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 0 4px;
  border-bottom: 2px solid darkgrey;
`;

type TruckProps = {
  truckName: string;
  driverName?: string;
  plateNumber?: string;
  orderIds?: string[];
  customers: DraggableCustomerProps[];
  loading?: boolean;
  success?: boolean;
};

type DeliveryRunState = {
  trucks: TruckProps[];
  unallocatedCustomers: DraggableCustomerProps[];
};

type DeliveryRunAction = {
  type: string;
  source?: DraggableLocation;
  destination?: DraggableLocation | null | undefined;
  customers?: DraggableCustomerProps[];
};

const initDeliveryRunState = (customers: DraggableCustomerProps[]): DeliveryRunState => {
  const grouped = groupBy(customers, "defaultRunName");

  return {
    trucks: RUNSHEET_DEFAULT_VALUE.map((truck) => ({
      ...truck,
      customers: grouped[truck.truckName] || [],
    })),
    unallocatedCustomers: grouped[""],
  };
};

const deliverRunReducer = (state: DeliveryRunState, action: DeliveryRunAction) => {
  const { source, destination, customers } = action;

  if (action.type === "init" && customers) {
    return initDeliveryRunState(customers);
  }

  if (action.type === "drag") {
    if (!destination) return state;
    if (destination.droppableId === "customers-pool") return state;

    const trucksClone = [...state.trucks];

    if (source!.droppableId === "customers-pool") {
      const customersClone = [...state.unallocatedCustomers];
      const customer = { ...customersClone[source!.index] };

      customersClone.splice(source!.index, 1);

      const destTruckIndex = state.trucks.findIndex(
        (truck) => truck.truckName === destination.droppableId
      );
      trucksClone[destTruckIndex].customers.splice(destination.index, 0, customer);

      return {
        trucks: trucksClone,
        unallocatedCustomers: customersClone,
      };
    } else {
      const srcTruckIndex = state.trucks.findIndex(
        (truck) => truck.truckName === source!.droppableId
      );

      const customer = { ...trucksClone[srcTruckIndex].customers[source!.index] };
      const destTruckIndex = state.trucks.findIndex(
        (truck) => truck.truckName === destination.droppableId
      );

      trucksClone[destTruckIndex].customers.splice(destination.index, 0, customer);
      trucksClone[srcTruckIndex].customers.splice(source!.index, 1);
      return {
        ...state,
        trucks: trucksClone,
      };
    }
  }

  return state;
};

export const DeliveryRunSetupBoard = ({ customers, loading }: DeliveryRunSetupBoardProps) => {
  const [updateCustomers, { loading: updateLoading }] = useMutation(
    BulkUpdateCustomersRunsMutation
  );
  const [state, dispatch] = useReducer(deliverRunReducer, {
    trucks: [],
    unallocatedCustomers: [],
  } as DeliveryRunState);

  useEffect(() => {
    if (customers.length) {
      dispatch({ type: "init", customers });
    }
  }, [customers]);

  const onDragEnd = ({ destination, source }: DropResult) => {
    dispatch({ type: "drag", destination, source });
  };

  const handleSave = async () => {
    const results = await Promise.all(
      state.trucks.map(async (truck) => {
        if (truck.customers.length === 0) return;
        const payload = truck.customers.map((customer) => ({
          where: { id: customer.id },
          data: { defaultRunName: truck.truckName },
        }));
        return await updateCustomers({ variables: { data: payload } });
      })
    );
    if (results) {
      notification.success({ message: "Successfully updated." });
    }
  };

  return loading || updateLoading ? (
    <FullScreenWrapper>
      <Spin />
    </FullScreenWrapper>
  ) : (
    <DragDropContext onDragEnd={onDragEnd}>
      <div
        style={{
          position: "sticky",
          top: "64px",
          marginBottom: "8px",
          padding: "4px",
          background: "#fff",
        }}
      >
        <Space style={{ width: "100%", marginBottom: 8, justifyContent: "space-between" }}>
          <Typography.Title level={5}>
            Unallocated customers ({state.unallocatedCustomers?.length})
          </Typography.Title>
          <Button type="primary" onClick={handleSave}>
            Save
          </Button>
        </Space>
        <DroppableTruckColumn
          direction="horizontal"
          showBorder={false}
          data={state.unallocatedCustomers}
          droppableId="customers-pool"
          isDropDisabled={true}
          key="customers-pool"
        />
      </div>
      <BoardContainer>
        {state.trucks.map((truck: TruckProps) => {
          return (
            <DroppableTruckColumn
              data={truck.customers}
              droppableId={truck.truckName}
              key={truck.truckName}
              header={
                <ColumnHeader>
                  <Typography.Title level={5}>
                    {truck.truckName} ({truck.customers.length})
                  </Typography.Title>
                  <span>Edit</span>
                </ColumnHeader>
              }
            />
          );
        })}
      </BoardContainer>
    </DragDropContext>
  );
};
