export const getItemCost = (item) => {
  if (item?.isOpenItem)
    return item?.pickingStatus === "na" ? 0 : (+item?.unitPrice || 0) * (+item?.quantity || 1);
  return item?.pickingStatus === "na" ? 0 : (+item?.cost || 0) * (+item?.quantity || 1);
};

export const calTolBasePrice = (orderItems = []) => {
  let totalBasePrice = orderItems.reduce((tally, item) => tally + getItemCost(item), 0);
  return String(totalBasePrice);
};

export const calcTotalPrice = (orderItems = [], gstPercentage = 0, rounding = true) => {
  let totalWithoutGst = orderItems.reduce(
    (tally, item) => tally + (+item?.unitPrice || 0) * +(item?.quantity || 0),
    0
  );
  let totalBasePrice = calTolBasePrice(orderItems);
  let gstAmount = totalWithoutGst * gstPercentage;
  let total = totalWithoutGst + gstAmount;

  const discountedTotal = rounding ? Math.floor(+total * 10) / 10 : total;
  const discountAmount = total - discountedTotal;
  const grossProfit = totalBasePrice === "0" ? 0 : discountedTotal / +totalBasePrice - 1;

  return {
    totalWithoutGst: String(totalWithoutGst),
    gstAmount: String(gstAmount),
    total: String(discountedTotal),
    discountAmount: String(discountAmount),
    grossProfit: String(grossProfit),
  };
};
