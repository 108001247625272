import React, { useState, useEffect } from "react";

import { useQuery } from "@apollo/client";

import { CustomersForLogisticSetupQuery } from "../graphql/customers.graphql";
import { DeliveryRunSetupBoard } from "../components/DeliveryRunSetupBoard";

export const LogisticSetup = () => {
  const [customers, setCustomers] = useState([]);
  const { data: customerData, loading } = useQuery(CustomersForLogisticSetupQuery, {
    variables: { filters: { isFranchise: { equals: false } } },
    fetchPolicy: "network-only",
  });
  console.log("customerData: ", customerData);

  useEffect(() => {
    if (customerData?.customers) {
      setCustomers(customerData.customers);
    }
  }, [customerData]);

  return <DeliveryRunSetupBoard customers={customers} loading={loading} />;
};
