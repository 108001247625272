import { RoutesTree } from "./routes";
import { CustomerProvider } from "./contexts/CustomerContext";

function App() {
  return (
    <div className="App">
      <CustomerProvider>
        <RoutesTree />
      </CustomerProvider>
    </div>
  );
}

export default App;
