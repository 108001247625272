import { Link } from "react-router-dom";
import moment from "moment";
import { Card, Space, Typography, Avatar, Tag, Divider } from "antd";

export const QuotationInfoCard = ({ customers, quotation }) => {
  // const { id, name, email, phone } = customer;
  const { start, end, deliveryAddress, notes, createdAt, updatedAt, confirmedAt } = quotation;

  return (
    <>
      <Card title={`${customers.length} Customers`}>
        <Space direction="vertical">
          {customers.map((customer) => {
            const { id, name } = customer;
            return (
              <Space>
                <Avatar>{name && name.charAt(0).toUpperCase()}</Avatar>
                <Link to={`/customers/${id}`}>{name}</Link>
              </Space>
            );
          })}
        </Space>
      </Card>
      <Divider />
      <Card title="Addtional Info">
        <Space direction="vertical">
          <Typography.Text strong> Date</Typography.Text>
          <Space direction="horizontal">
            <Typography.Text>Start: {moment(start).format("DD/MM/YYYY")}</Typography.Text>
            <Typography.Text>End: {moment(end).format("DD/MM/YYYY")}</Typography.Text>
          </Space>
        </Space>
        <Divider />

        <Space direction="vertical">
          <Typography.Text strong>Shipping address</Typography.Text>
          <Typography.Text>{deliveryAddress}</Typography.Text>
        </Space>

        <Divider />

        <Space direction="vertical">
          <Typography.Text strong>Notes</Typography.Text>
          <Typography.Text>{notes}</Typography.Text>
        </Space>
      </Card>
      <Card title="Timestamps">
        <Space direction="vertical">
          <Space>
            <Typography.Text strong>Created at: </Typography.Text>
            <Typography.Text>{moment(createdAt).format("YYYY-MM-DD, HH:mm:ss")}</Typography.Text>
          </Space>
          <Space>
            <Typography.Text strong>Confirmed at: </Typography.Text>
            <Typography.Text>
              {confirmedAt ? moment(confirmedAt).format("YYYY-MM-DD, HH:mm:ss") : "not confirmed"}
            </Typography.Text>
          </Space>
          <Space>
            <Typography.Text strong>Updated at: </Typography.Text>
            <Typography.Text>{moment(updatedAt).format("YYYY-MM-DD, HH:mm:ss")}</Typography.Text>
          </Space>
        </Space>
      </Card>
    </>
  );
};
