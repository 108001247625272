import { useEffect, useState } from "react";
import { useQuery } from "@apollo/client";
import { PDFDownloadLink } from "@react-pdf/renderer";
import {
  Table,
  Button,
  PageHeader,
  Typography,
  notification,
  Statistic,
  Spin,
  Select,
  Space,
  Input,
} from "antd";
import { FileExcelOutlined, FilePdfOutlined, FileZipOutlined } from "@ant-design/icons";
import moment from "moment";

import { MoneyCell } from "../components/tablecells";
import { PageContainer } from "../components/atoms/PageContainer";
import { PageContentContainer } from "../components/atoms/PageContentContainer";
import {
  CustomersForAccountBalanceQuery,
  CustomersSendStatementsQuery,
} from "../graphql/customers.graphql";
import { StatementSummaryPDF } from "../components/pdf/StatementSummaryPDF";
import { getDateColor, exportExcel } from "../utils";
import { formatMoney } from "../utils/index";
import { StatementAction } from "../components/StatementAction/index.js";
import { STATEMENT_SORT_OPTIONS, VendorsMap } from "../constants";
import { ToolbarWrapper } from "../components/atoms/ToolbarWrapper";

const pageSize = 20;

const calcTotalBalance = (customers) =>
  customers.reduce((prev, current) => prev + parseFloat(current.accountBalance || "0"), 0);

export const Statements = () => {
  const [customers, setCustomers] = useState([]);
  const [customersCount, setCustomersCount] = useState(0);
  const [allCustomers, setAllCustomers] = useState([]);
  const [filters, setFilters] = useState({
    isFranchise: { equals: false },
    status: { equals: "active" },
    AND: [
      { OR: [{ accountBalance: { lt: "0" } }, { accountBalance: { gt: "0" } }] },
      {
        OR: [
          { name: { contains: "", mode: "insensitive" } },
          { customerCode: { contains: "", mode: "insensitive" } },
        ],
      },
    ],
  });
  const [currentPage, setCurrentPage] = useState(1);
  const [sumOfBalance, setSumOfBalance] = useState(0);
  const [sortType, setSortType] = useState("lastPurchaseDesc");

  const { data, loading } = useQuery(CustomersSendStatementsQuery, {
    variables: {
      take: pageSize,
      skip: (currentPage - 1) * pageSize,
      filters: filters,
      sortType: sortType,
    },
    fetchPolicy: "network-only",
  });

  const { data: allCustomersData, loading: allCustomersLoading } = useQuery(
    CustomersForAccountBalanceQuery,
    { fetchPolicy: "network-only" }
  );
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [selectedTotal, setSelectedTotal] = useState(0);

  useEffect(() => {
    if (data?.sortedCustomers) {
      setCustomers(data.sortedCustomers);
      setCustomersCount(data.customersCount);
    }
  }, [data]);

  useEffect(() => {
    if (allCustomersData?.customers) {
      setAllCustomers(allCustomersData.customers);
      setSumOfBalance(calcTotalBalance(allCustomersData.customers));
    }
  }, [allCustomersData]);

  const columns = [
    { title: "#", dataIndex: "customerCode", key: "customerCode" },
    { title: "Customer", dataIndex: "name", key: "name" },
    {
      title: "Terms",
      dataIndex: "paymentTerms",
      key: "paymentTerms",
    },
    {
      title: "Method",
      dataIndex: "statementMethod",
      key: "statemenMethod",
    },
    {
      title: "Email",
      dataIndex: "email",
      key: "email",
    },
    {
      title: "Mobile",
      dataIndex: "phone",
      key: "phone",
    },
    {
      title: "Oldest unpaid order",
      dataIndex: "oldestUnpaidOrder",
      key: "oldestUnpaidOrder",
      render: (_, record) => {
        if (record.orders.length > 0) {
          const firstPurchase = record.orders.find(
            (order) => !order.isCreditNote && order.paymentStatus !== "paid"
          );
          return firstPurchase ? (
            <Typography.Text type={getDateColor(firstPurchase?.orderDate)}>
              {moment(firstPurchase?.orderDate).format("DD/MM/YYYY")}
            </Typography.Text>
          ) : null;
        } else {
          return "";
        }
      },
      exportFunc: (record) => {
        if (record.orders.length > 0) {
          const firstPurchase = record.orders.find(
            (order) => !order.isCreditNote && order.paymentStatus !== "paid"
          );
          return firstPurchase ? moment(firstPurchase?.orderDate).format("DD/MM/YYYY") : null;
        } else {
          return "";
        }
      },
    },
    {
      title: "Latest unpaid order",
      dataIndex: "latestUnpaidOrder",
      key: "latestUnpaidOrder",
      render: (_, record) => {
        if (record.orders.length > 0) {
          const ordersOnly = record.orders.filter((o) => !o.isCreditNote);
          const latestUnpaidOrder = ordersOnly[ordersOnly.length - 1];
          return latestUnpaidOrder ? (
            <Typography.Text type={getDateColor(latestUnpaidOrder?.orderDate)}>
              {moment(latestUnpaidOrder?.orderDate).format("DD/MM/YYYY")}
            </Typography.Text>
          ) : null;
        } else {
          return "";
        }
      },
      exportFunc: (record) => {
        const latestPurchase = record.orders[record.orders.length - 1];
        return moment(latestPurchase?.orderDate).format("DD/MM/YYYY");
      },
    },
    {
      title: "Latest payment",
      dataIndex: "latestPayment",
      key: "latestPayment",
      render: (_, record) => {
        if (record.paymentBatches.length > 0) {
          return (
            <Typography.Text type={getDateColor(record.paymentBatches[0]?.receivedDate)}>
              {moment(record.paymentBatches[0]?.receivedDate).format("DD/MM/YYYY")}
            </Typography.Text>
          );
        } else {
          return "";
        }
      },
      exportFunc: (record) => {
        if (record.paymentBatches.length > 0) {
          return moment(record.paymentBatches[0]?.receivedDate).format("DD/MM/YYYY");
        } else {
          return "";
        }
      },
    },
    {
      title: "Salesperson",
      dataIndex: ["salesperson", "name"],
      key: "salesperson",
      exportFunc: (record) => {
        return record.salesperson?.name || "";
      },
    },

    {
      title: "Franchise",
      dataIndex: ["franchise", "name"],
      key: "franchise",
      exportFunc: (record) => {
        return record.franchise?.name || "";
      },
    },
    {
      title: "Vendor",
      dataIndex: "invoiceTemplate",
      key: "vendor",
      render: (value) => VendorsMap[value].label,
      exportFunc: (record) => {
        return VendorsMap[record.invoiceTemplate].label;
      },
    },
    {
      title: "Account balance",
      dataIndex: "accountBalance",
      key: "accountBalance",
      ...MoneyCell,
      exportFunc: (record) => {
        return `$${record.accountBalance}`;
      },
    },
    {
      title: "Action",
      dataIndex: "action",
      key: "action",
      excludeInExport: true,
      render: (_, record) => <StatementAction record={record} />,
    },
  ];

  const rowSelection = {
    checkStrictly: false,
    selectedRowKeys,
    onChange: (newSelectedRowKeys, selectedRows) => {
      setSelectedTotal(calcTotalBalance(selectedRows));
      setSelectedRowKeys(newSelectedRowKeys);
    },
  };

  const handleExcelExport = () => {
    const exportedCustomers = allCustomers.map((customer) => {
      const newCustomer = {};
      columns.forEach((col) => {
        if (col.excludeInExport) return;
        if (col.exportFunc) {
          newCustomer[col.title] = col.exportFunc(customer);
        } else {
          newCustomer[col.title] = customer[col.dataIndex];
        }
      });
      return newCustomer;
    });
    exportExcel(exportedCustomers, `Summary_as_of_${moment().format("DD/MM/YYYY")}.xlsx`);
  };

  const handleBatchDownload = () => {
    if (selectedRowKeys.length > 20) {
      notification.warning({ message: "Please select no more than 20 customers." });
      return;
    }
    selectedRowKeys.forEach((id, index) => {
      setTimeout(() => {
        document.getElementById(id).click();
      }, index * 500);
    });
  };

  const sumFooter = () => {
    return (
      <pre
        style={{
          marginTop: "1rem",
          marginBottom: "1rem",
          fontSize: "20px",
          fontWeight: "600",
          textAlign: "end",
        }}
      >
        {`Selected Total:${formatMoney(selectedTotal)}`}
      </pre>
    );
  };

  const handleSearch = (value) => {
    const nameFilter = {
      AND: [
        { OR: [{ accountBalance: { lt: "0" } }, { accountBalance: { gt: "0" } }] },
        {
          OR: [
            { name: { contains: value, mode: "insensitive" } },
            { customerCode: { contains: value, mode: "insensitive" } },
          ],
        },
      ],
    };
    setFilters((f) => ({
      ...f,
      ...nameFilter,
    }));
  };

  const handleVendorFilterChange = (value) => {
    if (value === "ALL") {
      const currentFilters = { ...filters };
      delete currentFilters.invoiceTemplate;
      setFilters(currentFilters);
    } else {
      setFilters((f) => ({ ...f, invoiceTemplate: { equals: value } }));
    }
  };

  const selectChange = (value) => {
    setSortType(value);
    setCurrentPage(1);
  };

  return (
    <PageContainer>
      <PageHeader
        title={`${moment().format("DD/MM/YYYY")} · Statements`}
        subTitle={`showing ${customers.length} of ${customersCount} customers`}
        extra={[
          <Select
            key="select"
            defaultValue={sortType}
            onChange={selectChange}
            options={STATEMENT_SORT_OPTIONS}
            style={{
              width: 250,
            }}
          />,
          allCustomersLoading ? (
            <Spin key="sum-spin" />
          ) : (
            <Statistic value={formatMoney(sumOfBalance)} key="sum" />
          ),
          <Button
            key="export-excel"
            type="primary"
            icon={<FileExcelOutlined />}
            onClick={handleExcelExport}
            loading={allCustomersLoading}
          >
            Export Excel
          </Button>,
          <PDFDownloadLink
            key="export-pdf"
            document={<StatementSummaryPDF data={allCustomers} total={sumOfBalance} />}
            fileName={`Summary_as_of_${moment().format("DD/MM/YYYY")}.pdf`}
          >
            {({ isPdfLoading, isPdfError }) => (
              <Button
                type="primary"
                disabled={isPdfError}
                icon={<FilePdfOutlined />}
                loading={isPdfLoading || allCustomersLoading}
              >
                Export PDF
              </Button>
            )}
          </PDFDownloadLink>,
          <Button
            key="batch-download"
            icon={<FileZipOutlined />}
            onClick={handleBatchDownload}
            disabled={selectedRowKeys.length === 0}
          >
            Batch Download
          </Button>,
        ]}
      />
      <PageContentContainer>
        <ToolbarWrapper>
          <Space>
            <Input.Search
              placeholder="Search name or code"
              enterButton
              allowClear
              onSearch={handleSearch}
              style={{ width: 320 }}
            />
            <Space>
              <label>Vendor:</label>
              <Select
                defaultValue="ALL"
                style={{ width: 120 }}
                onChange={handleVendorFilterChange}
                options={[
                  {
                    value: "ALL",
                    label: "All",
                  },
                  {
                    value: "NEWNORTH",
                    label: "Newnorth",
                  },
                  {
                    value: "GLORY_FRESH",
                    label: "Glory Fresh",
                  },
                ]}
              />
            </Space>
          </Space>
          <Space size={0}></Space>
        </ToolbarWrapper>{" "}
        <Table
          dataSource={customers}
          columns={columns}
          rowKey="id"
          size="medium"
          loading={loading}
          pagination={{
            current: currentPage,
            total: customersCount,
            pageSize: pageSize,
            showSizeChanger: false,
            onChange: (currentPageIndex) => {
              setCurrentPage(currentPageIndex);
            },
          }}
          footer={sumFooter}
          rowSelection={rowSelection}
        />
      </PageContentContainer>
    </PageContainer>
  );
};
