import { useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useQuery, useMutation } from "@apollo/client";
import { Button, PageHeader, Form } from "antd";
import moment from "moment";

import { PageContainer } from "../components/atoms/PageContainer";

import {
  SinglePaymentQuery,
  UpdatePaymentMutation,
} from "../graphql/payments.graphql";
import { PaymentForm } from "../components/PaymentForm";
import { PaymentBatchesQuery } from "../graphql/paymentBatches.graphql";

export const EditPayment = () => {
  const params = useParams();
  const [form] = Form.useForm();
  const navigate = useNavigate();

  const { data } = useQuery(SinglePaymentQuery, {
    variables: { id: params.id },
    fetchPolicy: "cache-and-network",
  });

  const [updatePayment] = useMutation(UpdatePaymentMutation, {
    refetchQueries: [{ query: PaymentBatchesQuery }],
    update: (cache, mutationResult) => {
      cache.evict(cache.identify(mutationResult.data.updatePaymentBatch));
    },
  });

  useEffect(() => {
    if (data && data.payment) {
      form.setFieldsValue({
        ...data.payment,
        createdAt: moment(data.payment.createdAt),
        order: data.payment.order?.name,
        orderId: data.payment.order?.id,
        customer: data.payment.customer?.name,
        customerId: data.payment.customer?.id,
      });
    }
  }, [data, form]);

  const handleSave = async () => {
    const values = form.getFieldsValue();
    const res = await updatePayment({
      variables: {
        id: params.id,
        data: {
          notes: values.notes,
          createdAt: values.createdAt.toISOString(),
          amount: String(values.amount),
          customer: { connect: { id: values.customerId } },
          order: { connect: { id: values.orderId } },
        },
      },
    });
    if (res) {
      navigate(-1);
    }
  };

  const handleDelete = async () => {
    const values = form.getFieldsValue();
    console.log("values: ", values);
    const res = await updatePayment({
      variables: {
        id: params.id,
        data: {
          amount: "0",
          customer: { connect: { id: values.customerId } },
          order: { connect: { id: values.orderId } },
        },
      },
    });
    if (res) {
      navigate(-1);
    }
  };

  return (
    <PageContainer>
      <PageHeader
        title={`Payment received for ${
          data?.payment?.customer?.name
        } on ${moment(data?.payment?.createdAt).format("DD/MM/YYYY")}`}
        onBack={() => navigate(-1)}
        extra={[
          <Button type="primary" key="save" onClick={handleSave}>
            Save
          </Button>,
          <Button danger key="delete" onClick={handleDelete}>
            Delete
          </Button>,
        ]}
      />
      <PaymentForm form={form} />
    </PageContainer>
  );
};
