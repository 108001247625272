import {
  Form,
  Col,
  Row,
  Card,
  Button,
  Input,
  AutoComplete,
  Tag,
  Switch,
  Select,
  Typography,
} from "antd";
import { MinusCircleFilled, PlusCircleFilled } from "@ant-design/icons";
import { useCategories } from "../../hooks/useCategories";

const unitsOptionsEN = [
  { label: "KG", value: "KG" },
  { label: "Each", value: "Each" },
  { label: "Bunch", value: "Bunch" },
  { label: "Dozen", value: "Dozen" },
  { label: "Box", value: "Box" },
  { label: "Pack", value: "Pack" },
  { label: "Bag", value: "Bag" },
  { label: "BigBag", value: "BigBag" },
  { label: "1.5k box", value: "1.5k box" },
];

const unitsOptionsZH = [
  { label: "公斤", value: "公斤" },
  { label: "个", value: "个" },
  { label: "把", value: "把" },
  { label: "打", value: "打" },
  { label: "箱", value: "箱" },
  { label: "盒", value: "盒" },
  { label: "包", value: "包" },
  { label: "大包", value: "大包" },
  { label: "1.5公斤箱", value: "1.5公斤箱" },
];

export const ProductForm = ({ form, setToDelete }) => {
  const { options } = useCategories();
  const handleFormBlur = () => {
    const values = form.getFieldsValue(["name_EN", "name_ZH"]);
    const fullname = [values.name_EN, values.name_ZH].join(" ");
    form.setFields([{ name: "name", value: fullname }]);
  };

  const handleUnitnameSelect = (value, index, name) => {
    const values = form.getFieldValue(["productVariants", index]);
    const name_EN = name === "name_EN" ? value : values.name_EN;
    const name_ZH = name === "name_ZH" ? value : values.name_ZH;
    const fullname = [name_EN, name_ZH].join(" ");
    form.setFields([
      {
        name: ["productVariants", index, "name"],
        value: fullname,
      },
    ]);
  };

  const handleRemove = (index, remove) => {
    const removedId = form.getFieldValue(["productVariants", index, "id"]);
    if (setToDelete) {
      setToDelete((toDelete) => {
        return [...toDelete, { id: removedId }];
      });
    }
    remove(index);
  };

  return (
    <Form
      form={form}
      onBlur={handleFormBlur}
      layout="vertical"
      initialValues={{ productVariants: [null] }}
    >
      <Row gutter={[32, 16]}>
        <Col span={16}>
          <Card title="Baisc information">
            <Row gutter={16}>
              <Col span={12}>
                <Form.Item
                  required
                  name="name_EN"
                  label={<Typography.Text strong>English name 英文名</Typography.Text>}
                >
                  <Input />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item
                  required
                  name="name_ZH"
                  label={<Typography.Text strong>Chinese name 中文名</Typography.Text>}
                >
                  <Input />
                </Form.Item>
              </Col>
            </Row>

            <Form.Item
              required
              name="name"
              label={
                <Typography.Text strong>
                  Full name (Combination of English name and Chinese name)
                </Typography.Text>
              }
            >
              <Input />
            </Form.Item>
          </Card>
        </Col>
        <Col span={8}>
          <Card>
            <Form.Item
              required
              name="category"
              label="Category"
              rules={[
                {
                  required: true,
                  message: "Please select a category",
                },
              ]}
            >
              <Select options={options} />
            </Form.Item>
          </Card>
        </Col>
        <Col span={16}>
          <Card title="Units">
            <Form.List name="productVariants">
              {(fields, { add, remove }) => {
                return (
                  <>
                    {fields.map(({ key, name, ...restField }, index) => {
                      return (
                        <Row gutter={8} key={key}>
                          <Col span={4}>
                            <Form.Item label={index === 0 ? "Default" : ""}>
                              {index === 0 ? (
                                <Tag color="success">Default</Tag>
                              ) : (
                                <Tag>Alternative</Tag>
                              )}
                            </Form.Item>
                          </Col>
                          <Col span={4}>
                            <Form.Item
                              {...restField}
                              name={[name, "name_EN"]}
                              label={index === 0 ? "EN name" : ""}
                            >
                              <AutoComplete
                                options={unitsOptionsEN}
                                onSelect={(value) => {
                                  handleUnitnameSelect(value, index, "name_EN");
                                }}
                              />
                            </Form.Item>
                          </Col>
                          <Col span={4}>
                            <Form.Item
                              {...restField}
                              name={[name, "name_ZH"]}
                              label={index === 0 ? "中文" : ""}
                            >
                              <AutoComplete
                                options={unitsOptionsZH}
                                onSelect={(value) => {
                                  handleUnitnameSelect(value, index, "name_ZH");
                                }}
                              />
                            </Form.Item>
                          </Col>
                          <Col span={4}>
                            <Form.Item
                              {...restField}
                              name={[name, "name"]}
                              label={index === 0 ? "Full" : ""}
                            >
                              <Input />
                            </Form.Item>
                          </Col>
                          <Col span={4}>
                            <Form.Item
                              {...restField}
                              name={[name, "convertToDefaultUnit"]}
                              label={index === 0 ? "Convert?" : ""}
                            >
                              {index > 0 && <Input />}
                            </Form.Item>
                          </Col>
                          <Col span={2}>
                            {index > 0 && (
                              <Button
                                shape="circle"
                                icon={<MinusCircleFilled />}
                                onClick={() => handleRemove(index, remove)}
                              />
                            )}
                            <Form.Item hidden name={[name, "isDeleted"]}>
                              <Switch valuePropName="checked" />
                            </Form.Item>
                            <Form.Item hidden name={[name, "id"]}>
                              <Input />
                            </Form.Item>
                          </Col>
                        </Row>
                      );
                    })}
                    <Button block type="dashed" icon={<PlusCircleFilled />} onClick={add}>
                      Add
                    </Button>
                  </>
                );
              }}
            </Form.List>
          </Card>
        </Col>
      </Row>
    </Form>
  );
};
