import React, { useState, useEffect } from "react";
import { useQuery, useLazyQuery } from "@apollo/client";
import moment from "moment";
import { Col, Row, Card, Divider, Input, Table, message } from "antd";
import { CustomerDetailCard } from "../components/CustomerDetailCard";
import { Link, useParams } from "react-router-dom";
import { CustomerOrderHistoryQuery } from "../graphql/customers.graphql";
import { SearchLineItemsQuery } from "../graphql/lineItems.graphql";

const lineItemColumns = [
  {
    title: "Name",
    dataIndex: "name",
    key: "name",
  },
  {
    title: "Unit",
    dataIndex: "packagingUnit",
    key: "packagingUnit",
  },
  {
    title: "Order#",
    dataIndex: ["order", "name"],
    key: "orderName",
  },
  {
    title: "Date",
    dataIndex: ["order", "orderDate"],
    key: "date",
    render: datetime => moment(datetime).format("DD/MM/YYYY"),
  },
  {
    title: "Quantity",
    dataIndex: "quantity",
    key: "quantity",
    render: quantity => (+quantity).toFixed(2),
  },
  {
    title: "Unit Price",
    dataIndex: "unitPrice",
    key: "unitPrice",
    align: "right",
    render: price => `$${price}`,
  },
];

const customerOrderColumns = [
  {
    title: "Order#",
    dataIndex: "name",
    key: "orderName",
    render: (orderName, record) => {
      return <Link to={`/orders/${record.id}`}>{orderName}</Link>;
    },
  },
  {
    title: "Date",
    dataIndex: "orderDate",
    key: "orderDate",
    render: datetime => moment(datetime).format("DD/MM/YYYY"),
  },
  {
    title: "Total",
    dataIndex: "total",
    key: "total",
    align: "right",
    render: totalPrice => `$${totalPrice}`,
  },

  {
    title: "Items",
    dataIndex: "lineItemsCount",
    key: "lineItemsCount",
    align: "right",
    render: lineItemsCount => `${lineItemsCount} items`,
  },
];

export const CustomerOrderHistorySearch = () => {
  const [lineItems, setLineItems] = useState([]);
  const [customerOrders, setCustomerOrders] = useState([]);
  const params = useParams();

  const {
    data: customerOrdersData,
    error: customerOrdersErr,
    loading: customerOrdersLoading,
  } = useQuery(CustomerOrderHistoryQuery, {
    variables: { id: params.id },
  });

  const [
    searchLineItems,
    {
      data: lineItemsData,
      error: lineItemsDataErr,
      loading: lineItemsDataLoading,
    },
  ] = useLazyQuery(SearchLineItemsQuery);

  const onSearch = value => {
    if (value.length !== 0) {
      searchLineItems({
        variables: { searchTerm: value, id: params.id },
      });
    }
  };

  useEffect(() => {
    setLineItems(lineItemsData?.lineItems);
    setCustomerOrders(customerOrdersData?.customer.orders);
  }, [customerOrdersData, lineItemsData]);

  useEffect(() => {
    if (customerOrdersErr) message.error("Failed to fetch customer orders.");
    if (lineItemsDataErr) message.error("Failed to fetch line items.");
  }, [customerOrdersErr, lineItemsDataErr]);

  return (
    <Row gutter={16}>
      <Col span={14}>
        <Card>
          <Input.Search
            placeholder="Search line item name"
            onSearch={onSearch}
            enterButton="Search"
            size="large"
            style={{ width: 500 }}
            allowClear
          />
          <Divider />
          <Table
            columns={lineItemColumns}
            dataSource={lineItems}
            loading={lineItemsDataLoading}
          />
        </Card>
      </Col>
      <Col span={10}>
        <CustomerDetailCard
          customer={customerOrdersData?.customer}
          footer={null}
        />
        <Divider>Last 5 Orders</Divider>
        <Table
          columns={customerOrderColumns}
          dataSource={customerOrders}
          loading={customerOrdersLoading}
          pagination={false}
        />
      </Col>
    </Row>
  );
};
