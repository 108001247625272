import { createContext } from "react";

const OrderFormContext = createContext({ customer: null, fetchCustomer: () => null, form: null });

const OrderFormProvider = ({
  isEditing,
  order,
  historyData,
  form,
  activeQuotation,
  children,
  historyDataLoading,
}) => {
  return (
    <OrderFormContext.Provider
      value={{ form, historyData, order, isEditing, activeQuotation, historyDataLoading }}
    >
      {children}
    </OrderFormContext.Provider>
  );
};

export { OrderFormProvider, OrderFormContext };
