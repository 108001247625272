export const prepareQuotationFormValues = (values, customer, operation = "update") => {
  const lineItems = values.lineItems.filter((item) => item.name !== undefined);

  const mappedItems = lineItems.map((item, index) => {
    if (!item.isOpenItem) {
      return {
        id: item.id,
        name: item.name,
        quantity: item.quantity,
        unitPrice: String(item.unitPrice),
        sortingIndex: index,
        // pickingStatus: item.pickingStatus,
        productVariant: { connect: { id: item.productVariant.id } },
        product: { connect: { id: item.product.id } },
        isOpenItem: false,
        // historyData: item.historyData,
      };
    } else {
      return {
        id: item.id,
        name: item.name,
        quantity: item.quantity,
        unitPrice: String(item.unitPrice),
        // sortingIndex: index,
        // pickingStatus: item.pickingStatus,
        isOpenItem: true,
      };
    }
  });

  let data;
  if (values.packer) {
    return;
  } else {
    delete values.packerNotes;
    data = {
      ...values,
      lineItems: operation === "update" ? mappedItems : { create: mappedItems },
      customers: { connect: { id: customer.id } },
      //   totalWithoutGst: values.totalPrice.totalWithoutGst,
      //   gstAmount: values.totalPrice.gstAmount,
      //   total: values.totalPrice.total,
      //   gstPercentage: values.totalPrice.gstPercentage || "0",
      //   discountAmount: values.totalPrice.discountAmount || "0",
      //   totalBasePrice: values.totalPrice.totalBasePrice || "0",
      //   grossProfit: values.totalPrice.grossProfit || "0",
    };
  }
  return data;
};

export const prepareQuotationFormValuesForRestApi = (values, customer) => {
  const lineItems = values.lineItems.filter((item) => item.name !== undefined);

  const mappedItems = lineItems.map((item, index) => {
    if (!item.isOpenItem) {
      return {
        id: item.id,
        name: item.name,
        quantity: item.quantity,
        unitPrice: String(item.unitPrice),
        sortingIndex: index,
        productVariant: { connect: { id: item.productVariant.id } },
        product: { connect: { id: item.product.id } },
        isOpenItem: false,
      };
    } else {
      return {
        id: item.id,
        name: item.name,
        quantity: item.quantity,
        unitPrice: String(item.unitPrice),
        isOpenItem: true,
      };
    }
  });

  const data = {
    ...values,
    lineItems: mappedItems,
    customers: customer.id,
  };

  return data;
};
