import { useState, useEffect } from "react";
import { useQuery } from "@apollo/client";
import { PageHeader, Descriptions, DatePicker, Col, Row } from "antd";
import moment from "moment";
import _ from "lodash";

import { PageContainer } from "../components/atoms/PageContainer";
import { getDateField } from "../utils";
import { OrdersQuery } from "../graphql/orders.graphql";
import { TableCard } from "../components/TableCard";
import { ChartCard } from "../components/ChartCard";
import { formatMoney } from "../utils";

const salespersonColumns = [
  {
    title: "Name",
    dataIndex: "name",
    dataKey: "name",
    key: "name",
    span: 6,
  },
  {
    title: "Total",
    dataIndex: "total",
    dataKey: "total",
    key: "total",
    align: "right",
    span: 6,
    render: (total) => formatMoney(total),
  },
];

export const Analytics = () => {
  const [salespersonData, setSalespersonData] = useState([]);
  const [dateData, setDateData] = useState([]);
  const [filters, setFilters] = useState({
    isCancelled: { equals: false },
    orderDate: { lte: moment().endOf("day").toISOString() },
  });
  const { data, loading } = useQuery(OrdersQuery, {
    variables: {
      where: filters,
    },
    fetchPolicy: "network-only",
    notifyOnNetworkStatusChange: true,
  });

  useEffect(() => {
    if (data?.orders) {
      resetSalespersonData(data.orders);
      resetDateData(data.orders);
    }
  }, [data, loading]);

  const resetSalespersonData = (orders) => {
    const tempData = _.chain(orders)
      .groupBy((order) => order.customer?.salesperson?.id)
      .mapValues((value) => {
        const total = value.reduce((pre, currentValue) => pre + Number(currentValue.total), 0);
        return {
          id: value[0].customer.salesperson?.id,
          name: value[0].customer.salesperson?.name,
          total: total,
        };
      })
      .value();
    setSalespersonData(_.sortBy(Object.values(tempData), "total").reverse());
  };

  const resetDateData = (orders) => {
    const tempData = _.chain(orders)
      .groupBy((order) => order.orderDate.slice(0, 10))
      .mapValues((value) => {
        const total = value.reduce((pre, currentValue) => pre + Number(currentValue.total), 0);
        const totalCost = value.reduce(
          (pre, currentValue) => pre + Number(currentValue.totalBasePrice),
          0
        );
        return { date: value[0].orderDate.slice(0, 10), total, totalCost, totalExpense: 60 };
      })
      .value();
    setDateData(Object.values(tempData).reverse());
  };

  const handleRangeChange = (range) => {
    if (!range) {
      const fil = { ...filters };
      delete fil.orderDate;
      setFilters(fil);
      return;
    }

    const [start, end] = range;
    setFilters((f) => ({
      ...f,
      ...getDateField("orderDate", start, end),
    }));
  };

  return (
    <PageContainer>
      <PageHeader ghost={true} title="Analytics">
        <Descriptions.Item label="compared to Jul XX, 2022">
          <DatePicker.RangePicker
            defaultValue={[null, moment().endOf("day")]}
            allowEmpty={[true, true]}
            ranges={{
              "Month to date": [moment().startOf("month"), moment().endOf("day")],
              "Last 7 days": [moment().subtract(6, "days").startOf("day"), moment().endOf("day")],
              "Last month": [
                moment().subtract(1, "months").startOf("month"),
                moment().subtract(1, "months").endOf("month"),
              ],
              "Last year": [
                moment().subtract(1, "year").startOf("year"),
                moment().subtract(1, "year").endOf("year"),
              ],
            }}
            onChange={handleRangeChange}
          />
        </Descriptions.Item>
      </PageHeader>
      <Row gutter={[16, 16]} style={{ padding: "15px" }}>
        <Col sm={24}>
          <ChartCard title="Sales by Date" data={dateData} />
        </Col>
        <Col sm={24} md={12}>
          <TableCard
            title="Sales by Salesperson"
            data={salespersonData}
            columns={salespersonColumns}
            loading={loading}
          />
        </Col>
        <Col sm={24} md={12}>
          <TableCard title="Top 5 products sold" />
        </Col>
      </Row>
    </PageContainer>
  );
};
