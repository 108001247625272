import { useLazyQuery, useQuery } from "@apollo/client";
import { Button, Col, Image, Modal, Row, Tag, Typography } from "antd";
import { useParams } from "react-router-dom";
import { DeliveryRunQuery } from "../../graphql/deliveryRun.graphql";
import { useEffect, useState } from "react";
import { OrdersByIdsQuery } from "../../graphql/orders.graphql";
import styled from "styled-components";

const OrderWrapper = styled.div`
  padding: 8px;
  border-bottom: 1px dashed lightgrey;
`;

const PageWrapper = styled.div`
  width: 100%;
  min-height: 100vh;
  padding: 20px;
  overflow-x: hidden;
`;

function convertToGoogleMapsFormat(address) {
  // Replace spaces with '+'
  return address.replace(/ /g, "+");
}

export const DeliveryRunOrdersList = () => {
  const params = useParams();
  const { data: deliveryRun } = useQuery(DeliveryRunQuery, { variables: { name: params.day } });
  const [orders, setOrders] = useState([]);
  const [currentRun, setCurrentRun] = useState({});
  const [modalOpen, setModalOpen] = useState(false);
  const [modalImageSrc, setModalImageSrc] = useState(false);
  const [selectedOrder, setSelectedOrder] = useState({});

  const [fetchOrders, { data: ordersData }] = useLazyQuery(OrdersByIdsQuery);

  useEffect(() => {
    if (deliveryRun) {
      const selectedRunSheet = deliveryRun.deliveryRun.runsheet.find(
        (sheet) => sheet.truckName === params.truck
      );
      console.log("selectedRunSheet: ", selectedRunSheet);
      const { orderIds, truckName, driverName, plateNumber } = selectedRunSheet;
      setCurrentRun({ truckName, driverName, plateNumber, orderIds });
      fetchOrders({ variables: { ids: orderIds } });
    }
  }, [deliveryRun, params, fetchOrders]);

  useEffect(() => {
    if (ordersData?.orders && currentRun?.orderIds) {
      console.log("currentRun?.orderIds: ", currentRun?.orderIds);
      const sortedOrders = currentRun?.orderIds.reduce((prev, id) => {
        const found = ordersData.orders.find((order) => order.id === id);
        if (found) {
          return [...prev, found];
        } else {
          return [...prev];
        }
      }, []);
      console.log("sortedOrders: ", sortedOrders);
      setOrders(sortedOrders);
    }
  }, [ordersData, currentRun]);

  const handleInstructions = (order) => {
    setModalOpen(true);
    setSelectedOrder(order);
  };

  return (
    <PageWrapper>
      <Typography.Title level={4}>
        Date: {params.day}, Truck: {currentRun.truckName}, Driver: {currentRun.driverName},
        Vechicle: {currentRun.plateNumber}
      </Typography.Title>
      <Image
        alt={selectedOrder.customer?.name}
        width={700}
        style={{ display: "none" }}
        src={`https://res.cloudinary.com/wohu-studio/image/upload/v1700782839/Screenshot_2023-11-24_at_10.29.32_yspzdf.png`}
        preview={{
          visible: modalOpen,
          src: "https://res.cloudinary.com/wohu-studio/image/upload/v1700782839/Screenshot_2023-11-24_at_10.29.32_yspzdf.png",
          onVisibleChange: (value) => {
            setModalOpen(value);
          },
        }}
      />
      {orders.map((order) => {
        return (
          <div key={order.id}>
            <OrderWrapper>
              <Row gutter={8}>
                <Col xs={24} md={16}>
                  <div>
                    <span>
                      #{order.name}{" "}
                      {order.instructions?.takeKey && <Tag color="warning"> Take Key</Tag>}
                    </span>
                    <h2 style={{ margin: 0 }}>{order.customer.name}</h2>
                    <span>{order.deliveryAddress}</span>
                  </div>
                </Col>
                <Col xs={24} md={8}>
                  <Row>
                    <Col xs={12} md={24}>
                      <Button block type="primary">
                        <a
                          href={`https://www.google.com/maps/dir/?api=1&origin=Your+Location&destination=${convertToGoogleMapsFormat(
                            order.deliveryAddress
                          )}`}
                          target="_blank"
                          rel="noreferrer"
                        >
                          Get directions
                        </a>
                      </Button>
                    </Col>
                    <Col xs={12} md={24}>
                      <Button disabled block onClick={() => handleInstructions(order)}>
                        Unload insturctions (Coming soon)
                      </Button>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </OrderWrapper>
          </div>
        );
      })}
    </PageWrapper>
  );
};
