import { Link } from "react-router-dom";
import { PageHeader, Button, Table, Dropdown, Typography } from "antd";
import { EditOutlined } from "@ant-design/icons";
import moment from "moment";
import { useState, useEffect } from "react";
import { useQuery } from "@apollo/client";

import { QuotationsQuery } from "../graphql/quotations.graphql";

import { ROUTES } from "../constants";
import { PageContainer } from "../components/atoms/PageContainer";
import { PageContentContainer } from "../components/atoms/PageContentContainer";
import { ResourceToolbar } from "../components/ResourceToolbar";
import { QuotationsActionsMenu } from "../components/tablecells/QuotationsActionsCell";
import { StatusTag } from "../components/tags/StatusTag";

const columns = [
  {
    title: "#",
    dataIndex: "name",
    key: "name",
    render: (name, record) => {
      return (
        <Typography.Title level={5} style={{ marginBottom: 0 }}>
          <Link to={`/quotations/${record.id}`}>{name}</Link>
        </Typography.Title>
      );
    },
  },
  {
    title: "Customer",
    dataIndex: "customer",
    key: "customer",
    render: (_, record) => {
      return record.customers.map((customer) => {
        return (
          <Typography.Title level={5} style={{ margin: 0 }}>
            {customer.name}
          </Typography.Title>
        );
      });
    },
  },
  {
    title: "No. of items",
    dataIndex: "lineItemsCount",
    key: "lineItemsCount",
    render: (count) => `${count} items`,
  },
  {
    title: "Summary",
    dataIndex: "summary",
    key: "summary",
    render: (_, record) => {
      return (
        <div>
          {record.lineItems?.slice(0, 5).map((item) => (
            <p style={{ margin: 0 }}>
              {item.product.name} - {item.productVariant.name}: ${item.unitPrice}
            </p>
          ))}
          {record.lineItems.length > 5 && <p>...</p>}
        </div>
      );
    },
  },
  {
    title: "Created",
    dataIndex: "createdAt",
    key: "createdAt",
    render: (createdAt) => moment(createdAt).format("DD/MM/YYYY"),
  },
  {
    title: "Status",
    dataIndex: "status",
    key: "status",
    render: (text) => <StatusTag status={text === "confirmed" ? "active" : "inactive"} />,
  },
  {
    title: "Actions",
    dataIndex: "actions",
    key: "actions",
    render: (_, record) => {
      return (
        <Dropdown.Button trigger={["click"]} overlay={<QuotationsActionsMenu quotation={record} />}>
          <Link to={`/quotations/${record.id}/edit`}>
            Edit <EditOutlined />
          </Link>
        </Dropdown.Button>
      );
    },
  },
];

const pageSize = 20;

export const QuotationsPage = () => {
  const [currentPage, setCurrentPage] = useState(1);
  const [quotations, setQuotations] = useState([]);
  const [filters, setFilters] = useState({
    isCancelled: { equals: false },
  });
  const { data, loading, refetch, networkStatus } = useQuery(QuotationsQuery, {
    variables: { take: pageSize, skip: (currentPage - 1) * pageSize, where: filters },
    fetchPolicy: "cache-and-network",
    notifyOnNetworkStatusChange: true,
  });

  useEffect(() => {
    if (data?.quotations) {
      setQuotations(data.quotations);
    }
  }, [data, loading]);

  const handleSearch = (value) => {
    const customerfilter = { name: { contains: value, mode: "insensitive" } };
    setFilters((f) => ({
      ...f,
      OR: [{ name: { contains: value, mode: "insensitive" } }, { customer: customerfilter }],
    }));
  };
  const handleRefresh = () => {
    refetch();
  };

  return (
    <PageContainer>
      <PageHeader
        ghost={true}
        title={moment().format("YYYY-MM-DD,dddd")}
        subTitle={`Showing ${quotations.length} quotations of ${data?.quotationsCount} `}
        extra={[
          <Link key="new-quotations" to={ROUTES.quotationsNew}>
            <Button type="primary">New Quote</Button>
          </Link>,
        ]}
      />
      <PageContentContainer>
        <ResourceToolbar search={{ onSearch: handleSearch }} onRefresh={handleRefresh} />
        <Table
          columns={columns}
          dataSource={quotations}
          rowKey="id"
          size="medium"
          loading={loading || networkStatus}
          pagination={{
            total: data?.quotationsCount,
            pageSize: pageSize,
            showSizeChanger: false,
            onChange: (currentPage) => {
              setCurrentPage(currentPage);
            },
          }}
        />
      </PageContentContainer>
    </PageContainer>
  );
};
