export const ROUTES = {
  home: "/",

  // products
  products: "/products",
  productsNew: "/products/new",
  productsBulkEdit: "/products/bulk-edit",

  // drivers
  drivers: "/drivers",

  // customers
  customers: "/customers",
  customersNew: "/customers/new",

  // customer statement
  statements: "/statements",
  statementSend: "/statements/send",

  //franchises
  franchises: "/franchises",
  franchisesNew: "/franchises/new",

  // orders
  orders: "/orders",
  ordersNew: "/orders/new",
  ordersNewV2: "/orders/v2/new",
  ordersSearch: "orders/search",
  ordersEdit: "/orders/:id/edit",

  //lineItems
  lineItems: "/lineItems",

  // allocation
  allocation: "/allocation",
  allocationNew: "/allocation/:id",

  logistic: "/logistic",

  // quotations
  quotations: "/quotations",
  quotationsNew: "/quotations/new",

  // invoices
  invoices: "/invoices",
  invoicesNew: "/invoices/new",

  //expenses
  expenses: "/expenses",
  expensesNew: "/expenses/new",

  // payments
  payments: "/payments",
  paymentsNew: "/payments/new",
  paymentsHistory: "/payments/history",

  // reports and analytics
  analytics: "/analytics",

  // settings
  settings: "/settings",
  settingsPersonal: "/settings/personal",
  settingsOrganisation: "/settings/organisation",

  // dev
  playground: "/playground",

  //buyer
  buyer: "/buyers-portal",
};
