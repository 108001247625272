import { getOrderFulfillmentStatus } from "./getOrderFulfillmentStatus";

// export const getOrderFulfillmentStatus = (lineItems) => {
//   lineItems = lineItems.filter((item) => item.status !== "deleted" );
//   const hasMissing = lineItems.some((item) => {
//     console.log("item: ", item);

//     return item.pickingStatus === "pack" || item.pickingStatus === "missing";
//   });
//   console.log("hasMissing: ", hasMissing);
//   if (hasMissing) return "missing";
//   return "packed";
// };

export const prepareOrderFormValues = (values, customer, operation = "update") => {
  const lineItems = values.lineItems.filter((item) => item.name !== undefined);
  const mappedItems = lineItems.map((item, index) => {
    if (!item.isOpenItem) {
      return {
        id: item.id,
        name: item.name,
        quantity: item.quantity,
        unitPrice: String(item.unitPrice),
        sortingIndex: index,
        pickingStatus: item.pickingStatus,
        productVariant: { connect: { id: item.productVariant.id } },
        product: { connect: { id: item.product.id } },
        isOpenItem: false,
        // historyData: item.historyData,
      };
    } else {
      return {
        id: item.id,
        name: item.name,
        quantity: item.quantity,
        unitPrice: String(item.unitPrice),
        sortingIndex: index,
        pickingStatus: item.pickingStatus,
        isOpenItem: true,
      };
    }
  });

  let data;

  if (values.packer) {
    data = {
      ...values,
      lineItems: operation === "update" ? mappedItems : { create: mappedItems },
      customer: { connect: { id: customer.id } },
      packer: { connect: { id: values.packer } },
      totalWithoutGst: values.totalPrice.totalWithoutGst,
      gstAmount: values.totalPrice.gstAmount,
      total: values.totalPrice.total,
      gstPercentage: values.totalPrice.gstPercentage || "0",
      discountAmount: values.totalPrice.discountAmount || "0",
      totalBasePrice: values.totalPrice.totalBasePrice || "0",
      grossProfit: values.totalPrice.grossProfit || "0",
    };
  } else {
    data = {
      ...values,
      lineItems: operation === "update" ? mappedItems : { create: mappedItems },
      customer: { connect: { id: customer.id } },
      totalWithoutGst: values.totalPrice.totalWithoutGst,
      gstAmount: values.totalPrice.gstAmount,
      total: values.totalPrice.total,
      gstPercentage: values.totalPrice.gstPercentage || "0",
      discountAmount: values.totalPrice.discountAmount || "0",
      totalBasePrice: values.totalPrice.totalBasePrice || "0",
      grossProfit: values.totalPrice.grossProfit || "0",
    };
  }

  delete data.totalPrice;

  data.fulfillmentStatus = getOrderFulfillmentStatus(lineItems);
  return data;
};
