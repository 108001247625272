import moment from "moment";
import { useEffect, useState } from "react";
import { useQuery } from "@apollo/client";
import { Button, PageHeader, Table } from "antd";
import { getProductsBreakdown } from "../utils";
import { OrdersForAllocation } from "../graphql/orders.graphql";
import { Link } from "react-router-dom";
import _ from "lodash";
import { PageContainer } from "../components/atoms/PageContainer";

const columns = [
  { title: "Product", dataIndex: "productName", key: "productName" },
  {
    title: "Required",
    dataIndex: "requiredDisplayQuantity",
    key: "requiredDisplayQuantity",
  },
  {
    title: "On hand",
    dataIndex: ["product", "displayQuantity"],
    key: "onhand",
  },
  { title: "Incoming", dataIndex: "incoming", key: "incoming" },
  {
    title: "Allocate",
    dataIndex: "allocate",
    key: "allocate",
    render: (_, record) => (
      <Button
        disabled={record.requiredQuantity <= record.inventoryQuantity}
        type={record.requiredQuantity > record.inventoryQuantity && "primary"}
      >
        <Link to={`/allocation/${record.product.id}`}>Allocate</Link>
      </Button>
    ),
  },
];

export const AllocationHome = () => {
  const [productsBreakdown, setProductsBreakdown] = useState([]);
  const [ordersCount, setOrdersCount] = useState(0);
  const [loading, setLoading] = useState(true);
  const { data, loading: queryLoading } = useQuery(OrdersForAllocation, {
    variables: {
      where: {
        orderDate: {
          gte: moment().startOf("day"),
          lte: moment().endOf("day"),
        },
        isCancelled: { equals: false },
      },
    },
    fetchPolicy: "network-only",
  });

  useEffect(() => {
    if (data && data.orders) {
      const todayOrders = data.orders;
      setOrdersCount(todayOrders.length);
      const lineItems = todayOrders.map(order => {
        return order.lineItems;
      });
      const flattened = _.reduce(lineItems, (sum, n) => sum.concat(n), []);
      const mapped = flattened.map(item => {
        return { ...item, quantity: +item.quantity };
      });
      console.log("mapped: ", mapped);
      const result = getProductsBreakdown(mapped);
      console.log("result: ", result);
      setProductsBreakdown(result);
      setLoading(false);
    }
  }, [data, queryLoading]);

  return (
    <PageContainer>
      <PageHeader
        title={`Today is ${moment().format(
          "YYYY-MM-DD"
        )}, total orders: ${ordersCount}`}
      />
      <Table
        columns={columns}
        dataSource={productsBreakdown}
        rowKey="productName"
        loading={loading || queryLoading}
      />
    </PageContainer>
  );
};
