import { Form } from "antd";
import { createContext, useState } from "react";

const FormListContext = createContext({
  fields: [],
  add: () => null,
  move: () => null,
  remove: () => null,
  setItemsCount: () => null,
  itemsCount: 3,
});

const FormListProvider = ({ name, children }) => {
  const [itemsCount, setItemsCount] = useState(0);
  return (
    <Form.List name={name}>
      {(fields, { add, move, remove }) => (
        <FormListContext.Provider value={{ fields, add, move, remove, itemsCount, setItemsCount }}>
          {children}
        </FormListContext.Provider>
      )}
    </Form.List>
  );
};

export { FormListContext, FormListProvider };
